import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';
// eslint-disable-next-line import/no-cycle
import { LicenseGroupData, Profile, PublicId } from 'app/store/types';
// eslint-disable-next-line import/no-cycle
import { getFormsUrl, getNodeTestingAPI, getLogApiUrl, getMetadataUrl, getWfxUrl } from 'app/utils/helpers';
import _ from '@lodash';
import qs from 'qs';
import { getPlanDomain, licenseGroupPlanDesignCheck } from 'app/utils/tenant-plan';

// toggle on or off mock data
const urlParams = new URLSearchParams(window.location.search);

if (urlParams.has('noMock')) {
	localStorage.removeItem('mock');
	localStorage.removeItem('mockUser');
	localStorage.removeItem('mockExpired');
	localStorage.removeItem('mockPlan');
	localStorage.removeItem('mockOrderType');
	localStorage.removeItem('mockRegion');
	localStorage.removeItem('mockLogs');
	localStorage.removeItem('mockForms');
	localStorage.removeItem('mockWorkflows');
	localStorage.removeItem('mockApps');
	localStorage.removeItem('mockEntitlements');
	localStorage.removeItem('noComingSoonWrapper');
	localStorage.removeItem('enableCheckout');
	localStorage.removeItem('mockNoRegion');
	localStorage.removeItem('mockT');
	localStorage.removeItem('allRegions');
}

if (urlParams.has('mock')) {
	localStorage.setItem('mock', 'true');
}
if (urlParams.has('mockUser')) {
	localStorage.setItem('mockUser', 'true');
}
if (urlParams.has('mockExpired')) {
	localStorage.setItem('mockExpired', urlParams.get('mockExpired') || `${+new Date('2020-12-12T23:59:59.000Z')}`);
}
if (Object.values(PublicId).includes(urlParams.get('mockPlan') as PublicId)) {
	localStorage.setItem('mockPlan', urlParams.get('mockPlan') as PublicId);
}
if (typeof urlParams.get('mockOrderType') === 'string') {
	localStorage.setItem('mockOrderType', urlParams.get('mockOrderType') as LicenseGroupData['orderType']);
}
if (typeof urlParams.get('mockRegion') === 'string') {
	localStorage.setItem('mockRegion', urlParams.get('mockRegion') as string);
}
if (urlParams.has('mockLogs')) {
	localStorage.setItem('mockLogs', 'true');
}
if (urlParams.has('mockForms')) {
	localStorage.setItem('mockForms', 'true');
}
if (urlParams.has('mockWorkflows')) {
	localStorage.setItem('mockWorkflows', 'true');
}
if (urlParams.has('mockApps')) {
	localStorage.setItem('mockApps', 'true');
}
if (urlParams.has('mockEntitlements')) {
	localStorage.setItem('mockEntitlements', 'true');
}
if (urlParams.has('noComingSoonWrapper')) {
	localStorage.setItem('noComingSoonWrapper', 'true');
}
if (urlParams.has('enableCheckout')) {
	localStorage.setItem('enableCheckout', 'true');
}
if (urlParams.has('mockNoRegion')) {
	localStorage.setItem('mockNoRegion', 'true');
}

if (urlParams.has('mockT')) {
	localStorage.setItem('mockT', 'true');
}

if (urlParams.has('allRegions')) {
	localStorage.setItem('allRegions', 'true');
}

// HACK-ish::add option for Product to edit site content easily
if (urlParams.has('designMode')) {
	document.designMode = 'on';
	document.body.setAttribute('spellcheck', 'false');
}

const mocking = (instance: AxiosInstance) => {
	const mock = new MockAdapter(instance, { delayResponse: 600, onNoMatch: 'passthrough' });
	const mockExpired = localStorage.getItem('mockExpired');
	const selectedLicenseGroup = {
		id: 'changeMeId',
		// id: '11111111-1111-1111-1111-111111111111',
		slug: 'sec',
		name: 'ABC Company',
		timezone: 'America/New_York',
		catalogPublicId: localStorage.getItem('mockPlan') ?? PublicId.TermOneYear,
		orderType: localStorage.getItem('mockOrderType') ?? 'PRODUCTION',
		expirationDate: mockExpired ? +mockExpired : +new Date('2050-12-12T23:59:59.000Z'),
		userExpirationDate: mockExpired ? +mockExpired : +new Date('2025-12-12T23:59:59.000Z'),
		isCoolOffPeriod: false,
		language: 'en',
		orderNumber: '1000596878880001',
		purchaseCode: '267791324',
		// DEV NOTE::magically matches whichever URL you visit (change to explicit value for testing redirect)
		// change to stratus-starter, stratus-business, stratus-enterprise
		plan: (licenseGroupPlanDesignCheck('stratus') ? 'stratus-enterprise' : 'stc') as LicenseGroupData['plan'],
		adminData: [
			{ email: 'sec4@kmbs.konicaminolta.us', status: 'ACCEPTED' },
			{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'PENDING' }
		],
		region: localStorage.getItem('mockNoRegion') ? false : 'us-east-1',
		quantity: 7,
		capacity: 11,
		userQuantity: 8,
		userCapacity: 25,
		managerEmails: [],
		isAdmin: true,
		isManager: false,
		authMethod: 'mp',
		dateAdded: 1614320869622
		// licenseUsage: {
		// 	dateUpdated: 1675058102193,
		// 	fileCount: 51,
		// 	fileSizeTotal: 10000
		// }
	};
	const user = {
		id: 'mock-user-id',
		firstName: 'Russell',
		lastName: 'Fellows',
		email: 'rfellows@kmbs.konicaminolta.us',
		mpId: 'mock-user-mpId-1',
		country: 'US',
		awsRegion: selectedLicenseGroup.region as Profile['awsRegion'],
		tenant: selectedLicenseGroup.id,
		tokens: {
			accessToken:
				'eyJraWQiOiJMc0ZzOG5Xbjd5dlh0RXhnWjVpMkEyS0I4YmtLY0V3b0poQXJmVTVYVWkwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2ZjQzNmU0ZS01NjRhLTQyMjctYTVhMS1mZWRhY2NjOGYwNzUiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfTEE0b1Bkbm1FX0tvbmljYU1pbm9sdGFNYXJrZXRQbGFjZSJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9MQTRvUGRubUUiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiI2Y3Bic3Y3bGs3NGlvbHNkZzRiamU5NG82dSIsIm9yaWdpbl9qdGkiOiI0MzQwMGI3ZS01OGJkLTRhYTItOTNjYi0zNDFiNzIyNDgzM2EiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4OTM5NjA3LCJleHAiOjE2OTkyODY5MDksImlhdCI6MTY5OTI4MzMwOSwianRpIjoiNWMzNDQyYWYtMTg4My00YmFhLTg1MWItMzUwOTg4OWQ5OGU1IiwidXNlcm5hbWUiOiJLb25pY2FNaW5vbHRhTWFya2V0UGxhY2VfZjRjOWIxYzNkYjhmMDkzZmQ3MmY0ZjY3ZmViZjFmOTUxYzI1ZDBjMiJ9.OGDZg9KsWW7plCdgBMA6Kh-ccI5iumI3jpMaqiWy1-doIiOgOp6B2PLogI12bDJ-ohRTn2YmMsXUAbCRNyq5DCoHMSB6OhWO_AJFw_NG-tJNlyf2nuhba6PjViMqRM9RWKbCB0uTAnr21pin1_SNuoF0z5xBLrcsnGXILMDUvSs3I3O42wWFhCfrIvWSIP5aRWggmKWbULyV5r1Q5TTGvhdTYTZtvWfByorI-viwpeJYRdnNXRc7oYv__XaLyTlhqeoSY13lTpcjZjP2BttxG82DjtXK_QrHj0wpbXpXeXPZ7PXPUiBufE3xCwl-C_ssMpXv-hHjbNUvo7WFF2wJMw',
			idToken: 'mock-id-token',
			expireTime: (Date.now() + 1814400000) / 1000 // set far in the future (but do not exceed `2147483647` - see https://nodejs.org/api/timers.html#settimeoutcallback-delay-args)
		},
		licensed: true
	};
	const tenantLicenseGroups: any[] = [
		{
			id: '111',
			name: 'University',
			parentId: 'cccc',
			parentName: 'Next one',
			managerEmails: []
		},
		{
			id: 'aaaa',
			name: 'Government Tenants',
			parentId: 'main',
			parentName: 'Main',
			managerEmails: []
		},
		{
			id: 'avvv',
			name: 'Federal',
			parentId: 'aaaa',
			parentName: 'TEST GROUP',
			managerEmails: []
		},
		{
			id: 'cccc',
			name: 'Education Tenants',
			parentId: 'main',
			parentName: 'Main',
			managerEmails: []
		},
		{
			id: 'main',
			name: 'Main',
			managerEmails: [
				{
					firstName: 'z',
					lastName: 'A',
					email: 'a@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				},
				{
					firstName: 'm',
					lastName: 'G',
					email: 'jalbanese@kmbs.konicaminolta.us',
					status: 'PENDING'
				},
				{
					firstName: 'Russell',
					lastName: 'Fellows',
					email: 'rfellows@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			],
			protected: true
		}
	];
	const licenseGroups = [
		// admin
		{
			id: 'five',
			slug: 'five-slug',
			name: 'Gravitas Group',
			catalogPublicId: PublicId.EnterpriseMonthly,
			orderType: 'PRODUCTION',
			expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
			orderNumber: '1000596878880002',
			purchaseCode: '267791325',
			plan: 'stc',
			adminData: [{ email: 'sec2@kmbs.konicaminolta.us', status: 'PENDING' }],
			region: 'us-east-1',
			quantity: 19,
			capacity: 50,
			userQuantity: 5,
			userCapacity: 20,
			isAdmin: true,
			isManager: false,
			authMethod: 'mp',
			dateAdded: 1614320869623
		},
		// admin
		{
			id: 'six',
			slug: 'six-slug',
			name: 'Wallhaven Associates',
			catalogPublicId: PublicId.EnterpriseMonthly,
			orderType: 'PRODUCTION',
			expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
			orderNumber: '1000596878890002',
			purchaseCode: '267791425',
			plan: 'stc',
			adminData: [],
			region: 'us-east-1',
			quantity: 19,
			capacity: 50,
			userQuantity: 16,
			userCapacity: 120,
			isAdmin: true,
			isManager: false,
			authMethod: 'mp',
			dateAdded: 1614320869633
		},
		{
			id: 'six-two',
			slug: 'six-two-slug',
			name: '',
			catalogPublicId: PublicId.EnterpriseMonthly,
			orderType: 'PRODUCTION',
			expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
			orderNumber: '1000596878890065',
			purchaseCode: '267791425',
			plan: 'stc',
			adminData: [
				{ email: 'sec3@kmbs.konicaminolta.us', status: 'PENDING' },
				{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'PENDING' }
			],
			region: '',
			quantity: 19,
			capacity: 50,
			userQuantity: 16,
			userCapacity: 120,
			isAdmin: true,
			isManager: false,
			authMethod: 'mp',
			dateAdded: 1614320869833
		},
		// manager
		// {
		// 	id: 'seven',
		// 	slug: 'seven-slug',
		// 	name: 'Milstone Financial',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880003',
		// 	purchaseCode: '267791326',
		// 	adminData: [
		// 		{ email: 'sec4@kmbs.konicaminolta.us', status: 'ACCEPTED' },
		// 		{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'ACCEPTED' }
		// 	],
		// 	region: 'us-east-1',
		// 	status: 'CONFIGURED',
		// 	quantity: 14,
		// 	capacity: 25,
		// 	userQuantity: 14,
		// 	userCapacity: 25,
		// 	isAdmin: false,
		// 	isManager: true,
		// 	authMethod: 'local',
		// 	dateAdded: 1614320839624
		// },
		// {
		// 	id: 'eight',
		// 	slug: 'eight-slug',
		// 	name: 'Horizon Digital',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880007',
		// 	purchaseCode: '267791497',
		// 	adminData: [
		// 		{ email: 'sec5@kmbs.konicaminolta.us', status: 'ACCEPTED' },
		// 		{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'ACCEPTED' }
		// 	],
		// 	region: 'us-east-1',
		// 	status: 'UNCONFIGURED',
		// 	quantity: 6,
		// 	capacity: 11,
		// 	userQuantity: 6,
		// 	userCapacity: 11,
		// 	isAdmin: false,
		// 	isManager: true,
		// 	authMethod: 'mp',
		// 	dateAdded: 1475320829626
		// },
		// {
		// 	id: 'twelve',
		// 	slug: 'twelve-slug',
		// 	name: 'Sabre Consulting Group',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880008',
		// 	purchaseCode: '267791497',
		// 	adminData: [
		// 		{ email: 'sec@kmbs.konicaminolta.us', status: 'PENDING' },
		// 		{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'PENDING' },
		// 		{ email: 'msmith@kmbs.konicaminolta.us', status: 'PENDING' }
		// 	],
		// 	region: 'us-east-1',
		// 	status: 'CONFIGURED',
		// 	quantity: 6,
		// 	capacity: 11,
		// 	userQuantity: 6,
		// 	userCapacity: 11,
		// 	isAdmin: false,
		// 	isManager: true,
		// 	authMethod: 'mp',
		// 	dateAdded: 1614320869628
		// },
		{
			id: '11111111-2222-2222-1111-111111111111',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00012904',
			status: 'UNCONFIGURED',
			purchaseCode: '3718-1b76-e138-bf1e',
			plan: 'stc',
			adminData: [
				{
					email: 'aabbott@kmbs.konicaminolta.us',
					status: 'PENDING'
				}
			],
			managerEmails: [],
			isAdmin: false, // at least one mock tenant needs this property, else crash
			dateAdded: 1671139415108,
			isManager: true
		},
		{
			id: '4505281a-2444-547d-a25a-31c618e570fd',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013026',
			status: 'UNCONFIGURED',
			purchaseCode: '34fe-4157-2c6b-be0d',
			plan: 'stratus',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675438138272,
			isManager: true,
			isAdmin: false
		},
		{
			id: '6745859e-8f21-525e-8c00-40b5473edc78',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013061',
			status: 'UNCONFIGURED',
			purchaseCode: '6528-3c49-aba6-bae9',
			plan: 'stc',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675691857902,
			isManager: true,
			isAdmin: false
		},
		{
			id: '7aea885e-5d71-5a55-b2b1-4ea0712640c3',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013062',
			status: 'PENDING',
			purchaseCode: 'e0af-53d6-b194-8099',
			plan: 'stc',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675691848640,
			isManager: true,
			isAdmin: false
		},
		{
			id: '813e7464-6d39-56b9-852e-0d7bc7c70f3c',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013067',
			status: 'UNCONFIGURED',
			purchaseCode: '125d-81bf-ee43-d80b',
			plan: 'stc',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675713684969,
			isManager: true,
			isAdmin: false
		},
		{
			id: '88d695c6-8896-599c-bfbd-f06764c61777',
			slug: 'test',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013065',
			status: 'UNCONFIGURED',
			purchaseCode: 'e9ad-09b9-b471-3f3b',
			plan: 'stratus',
			region: 'us-east-1',
			managerEmails: [],
			adminData: [
				{
					email: 'vdogames@gmail.com',
					status: 'PENDING'
				}
			],
			dateAdded: 1675691821770,
			isManager: true,
			isAdmin: false
		},
		{
			id: '8ddc5e14-644e-5903-bd79-be31ac1b6bc5',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013012',
			status: 'UNCONFIGURED',
			purchaseCode: '287e-1c5b-c9c2-94d7',
			plan: 'stc',
			managerEmails: [],
			adminData: [
				{
					email: 'pat.white@kmbs.konicaminolta.com',
					status: 'PENDING'
				}
			],
			dateAdded: 1675265652769,
			isManager: true,
			isAdmin: false
		},

		{
			id: 'e8680b51-fd2b-5ab0-ba86-f5fcaf8b3ea7',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013066',
			status: 'UNCONFIGURED',
			purchaseCode: '8356-5e16-e9b4-217c',
			plan: 'stc',
			managerEmails: [],
			adminData: [
				{
					email: 'pat.white@kmbs.konicaminolta.us',
					status: 'PENDING'
				}
			],
			dateAdded: 1675713692022,
			isManager: true,
			isAdmin: false
		},
		{
			id: 'f5922df9-cbc1-5383-a86a-90ae4a0fc92f',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013064',
			status: 'UNCONFIGURED',
			purchaseCode: '13a1-efc6-a28e-f90f',
			plan: 'stc',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675691829486,
			isManager: true,
			isAdmin: false
		},
		{
			id: '11111111-1111-1111-1111-111111111111',
			slug: 'jack',
			name: 'Bergen Community College',
			quantity: 5,
			capacity: 6,
			userQuantity: 5,
			userCapacity: 6,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			parent: 'cccc',
			orderType: 'PRODUCTION',
			orderNumber: 'SomeOrder200',
			status: 'CONFIGURED',
			purchaseCode: 'SomePurchase200',
			plan: 'stc',
			expirationDate: 1780876799000,
			region: 'us-east-1',
			managerEmails: [{ email: 'qa@kmbs.konicaminolta.com', status: 'ACCEPTED' }],
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.com',
					status: 'ACCEPTED'
				},
				{
					email: 'frank@ape-escape.net',
					status: 'PENDING'
				}
			],
			dateAdded: 1647107307031,
			isManager: true,
			isAdmin: false
		},
		{
			id: '11111111-1111-1111-1111-1212121212',
			slug: 'jack',
			name: 'Rutgers',
			quantity: 5,
			capacity: 6,
			userQuantity: 5,
			userCapacity: 6,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			parent: '111',
			orderType: 'PRODUCTION',
			orderNumber: 'SomeOrder200',
			status: 'CONFIGURED',
			purchaseCode: 'SomePurchase200',
			plan: 'stc',
			expirationDate: 1780876799000,
			region: 'us-east-1',
			managerEmails: [{ email: 'qa@kmbs.konicaminolta.com', status: 'ACCEPTED' }],
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.com',
					status: 'ACCEPTED'
				},
				{
					email: 'frank@ape-escape.net',
					status: 'PENDING'
				}
			],
			dateAdded: 1647107307031,
			isManager: true,
			isAdmin: false
		},
		{
			id: 'd254bae9-d5eb-5719-b86a-c8e72a807402',
			slug: 'vivivivi',
			name: 'Ridgewood Public Schools',
			parent: 'cccc',
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013063',
			status: 'CONFIGURED',
			expirationDate: 1780876799000,
			purchaseCode: 'f22e-b506-0f0f-db72',
			plan: 'stc',
			region: 'us-east-1',
			managerEmails: [{ email: 'qa@kmbs.konicaminolta.com', status: 'ACCEPTED' }],
			managerEvents: {
				'30days': true
			},
			adminData: [
				{
					email: 'vdogames@gmail.com',
					status: 'ACCEPTED'
				}
			],
			dateAdded: 1686172692397,
			isManager: true,
			isAdmin: false,
			suspensions: { viewOnly: true }
		},
		{
			id: '1ea16018-ee08-5eb3-ba96-a2baa04eeb8d',
			owner: 'f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
			name: 'William Paterson',
			region: 'us-east-1',
			status: 'CONFIGURED',
			dateAdded: 1680285802159,
			slug: 'sktenant11',
			parent: '111',
			purchaseCode: 'f5e5-c055-0878-f86f',
			plan: 'stc',
			orderNumber: 'MP00013303',
			materialNumber: 'DPSAAA01',
			catalogUid: 'dps-stc-subm',
			catalogPublicId: '3348c5fd-7123-4332-abd0-4d5c644ce3a7',
			licenseName: 'Dispatcher ScanTrip Cloud',
			licenseUsage: {},
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			orderType: 'PRODUCTION',
			isExpired: false,
			managerEmails: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			],
			isManager: true,
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			]
		},
		{
			id: '7def2712-dcc1-5ad8-be99-28e56af8eae3',
			owner: 'f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
			name: 'Montclair State',
			parent: '111',
			region: 'us-east-1',
			status: 'CONFIGURED',
			dateAdded: 1680790426016,
			slug: 'domainalreadytakenlg',
			purchaseCode: '7bba-96f5-7085-5880',
			plan: 'stc',
			orderNumber: 'MP00013320',
			materialNumber: 'DPSAAA01',
			catalogUid: 'dps-stc-subm',
			catalogPublicId: '3348c5fd-7123-4332-abd0-4d5c644ce3a7',
			licenseName: 'Dispatcher ScanTrip Cloud',
			licenseUsage: {},
			quantity: 0,
			capacity: 10,
			userQuantity: 0,
			userCapacity: 10,
			orderType: 'PRODUCTION',
			isExpired: false,
			isManager: true,
			managerEmails: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			],
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			]
		},
		{
			id: '9f0303a4-ede5-5060-914e-6f5523da60cd',
			owner: 'f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
			name: 'Princeton',
			region: 'us-east-1',
			parent: '111',
			status: 'CONFIGURED',
			dateAdded: 1681849276370,
			slug: 'sgalvisadmin',
			purchaseCode: 'e8bb-72c0-97e8-0174',
			plan: 'stc',
			orderNumber: 'MP00013372',
			materialNumber: 'DPSAAA01',
			catalogUid: 'dps-stc-subm',
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			licenseName: 'Enterprise',
			licenseUsage: {},
			quantity: 0,
			capacity: 11,
			userQuantity: 0,
			userCapacity: 11,
			orderType: 'PRODUCTION',
			isExpired: false,
			isManager: true,
			managerEmails: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			],
			adminData: [
				{
					email: 'sgalvis@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			]
		},
		// ===================================================================

		// {
		// 	id: 'forty-one',
		// 	slug: 'forty-one-slug',
		// 	name: 'Cosgrove Medical',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880008',
		// 	purchaseCode: '267791497',
		// 	adminData: [],
		// 	region: 'eu-central-1',
		// 	quantity: 6,
		// 	capacity: 11,
		// 	userQuantity: 6,
		// 	userCapacity: 11,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614110769628
		// },

		// unconfigured tenant (i.e. no `region` [also no `name` - though that's not a rule in the logic])
		// {
		// 	id: 'nine',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880004',
		// 	purchaseCode: '267791327',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 119,
		// 	capacity: 25,
		// 	userQuantity: 119,
		// 	userCapacity: 25,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614020869623
		// },
		// {
		// 	id: 'ten',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880005',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320859627
		// },
		// {
		// 	id: 'thirteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880013',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1398320849627
		// },
		// {
		// 	id: 'fourteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880014',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1586320869627
		// },

		// {
		// 	id: 'fifteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880015',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614020839627
		// },
		// {
		// 	id: 'sixteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880016',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1514200869627
		// },
		// {
		// 	id: 'seventeen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880017',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1601320869627
		// },
		// {
		// 	id: 'eightteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880018',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'nineteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880019',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'twenty',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880020',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'twenty-one',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880021',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'twenty-two',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880022',
		// 	purchaseCode: '267791329',
		//  plan: 'stc',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	userQuantity: 11,
		// 	userCapacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// ========================================================
		// ========================================================
		selectedLicenseGroup
	];
	// DEV NOTE::admined and managed licenses are returned from two different lists - and the `isAdmin` and `isManager` property don't actually exist
	const managedLicenseGroups = licenseGroups
		.filter(({ isManager }) => isManager)
		.map(licenseGroup => _.omit(licenseGroup, ['isAdmin', 'isManager']));
	// const adminedLicenseGroups = licenseGroups
	// 	.filter(({ isAdmin }) => isAdmin)
	// 	.map(licenseGroup => _.omit(licenseGroup, ['isAdmin', 'isManager']));

	const adminedLicenseGroups = [
		{
			id: selectedLicenseGroup.id,
			name: selectedLicenseGroup.name,
			domain: `https://${selectedLicenseGroup.slug}.tenant.${getPlanDomain(selectedLicenseGroup.plan as any)}`
		},
		{
			id: licenseGroups[1].id,
			name: licenseGroups[1].name,
			domain: `https://${licenseGroups[1].slug}.tenant.${getPlanDomain(licenseGroups[1].plan as any)}`
		}
	];

	const users = [
		{
			id: user.id,
			userName: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			mpId: user.mpId,
			dateAdded: 1614202447928,
			// permissions: {
			// 	device_management: true,
			// 	user_management: true,
			// 	license_management: true
			// }
			roles: ['role-2'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1', '00000000-2222-2222-2222-000000000000'],
			licensed: user.licensed
		},
		{
			id: 'mock-user-id-3',
			userName: 'mock-user-id-3',
			firstName: 'John',
			lastName: 'Smith',
			email: 'jsmith@example.com',
			mpId: 'mock-user-mpId-2',
			dateAdded: 1616202447928,
			// permissions: {
			// 	device_management: true,
			// 	user_management: true,
			// 	license_management: true
			// }
			roles: ['role-1'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1'],
			licensed: true
		},
		{
			id: 'mock-user-id-5',
			userName: 'mock-user-id-5',
			firstName: 'Katherine',
			lastName: 'Pryde',
			email: 'kpryde@example.com',
			mpId: 'mock-user-mpId-3',
			dateAdded: 1616202447928,
			// permissions: {
			// 	device_management: true
			// }
			roles: ['role-2'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1', '00000000-1111-1111-1111-000000000000'],
			licensed: true
		},
		{
			id: 'mock-user-id-11',
			userName: 'mock-user-id-11',
			firstName: 'Hank',
			lastName: 'McCoy',
			email: 'hmccoy@example.com',
			mpId: 'mock-user-mpId-4',
			dateAdded: 1616202447928,
			// permissions: {
			// 	user_management: true
			// }
			roles: ['role-3', 'role-2'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1', '00000000-2222-2222-2222-000000000000'],
			licensed: false
		},
		{
			id: 'mock-user-id-13',
			userName: 'mock-user-id-13',
			firstName: undefined,
			lastName: undefined,
			username: 'ebrooks',
			email: 'ebrooks@example.com',
			mpId: 'mock-user-mpId-5',
			dateAdded: 1616202447928,
			// permissions: {
			// 	license_management: true
			// }
			roles: ['role-2'],
			status: 'ACCEPTED',
			groupList: ['userGroup-2', '00000000-2222-2222-2222-000000000000'],
			licensed: true
		},
		{
			id: 'mock-user-id-19',
			userName: 'mock-user-id-19',
			firstName: 'Reed',
			lastName: 'Richards',
			email: 'rrichards@example.com',
			mpId: 'mock-user-mpId-6',
			dateAdded: 1616202447928,
			roles: ['role-2'],
			blocked: true,
			status: 'ACCEPTED',
			groupList: ['userGroup-2', '00000000-1111-1111-1111-000000000000'],
			licensed: false
		},
		{
			id: 'mock-user-id-21',
			userName: 'mock-user-id-21',
			firstName: 'Sean',
			lastName: 'Sullivan',
			email: 'ssullivan@example.com',
			mpId: 'mock-user-mpId-7',
			dateAdded: 1616202447928,
			roles: ['role-2'],
			status: 'ACCEPTED',
			groupList: ['userGroup-2', '00000000-1111-1111-1111-000000000000'],
			licensed: false
		},
		{
			id: 'mock-user-id-23',
			userName: 'mock-user-id-23',
			firstName: 'Tabrej',
			lastName: 'Chowdhury',
			email: 'tchowdhury@example.com',
			mpId: 'mock-user-mpId-8',
			dateAdded: 1616202447928,
			roles: ['role-2'],
			status: 'ACCEPTED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000'],
			licensed: false
		},
		{
			id: 'mock-user-id-25',
			userName: 'mock-user-id-25',
			firstName: 'Pavani',
			lastName: 'Ennamaneni',
			email: 'pennamaneni@example.com',
			mpId: 'mock-user-mpId-9',
			dateAdded: 1615202447928,
			roles: ['role-2'],
			status: 'BLOCKED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000'],
			licensed: false
		},
		{
			id: 'mock-user-id-27',
			userName: 'mock-user-id-27',
			firstName: 'William',
			lastName: 'Smith',
			email: 'wsmith@example.com',
			mpId: 'mock-user-mpId-10',
			dateAdded: 1615202447928,
			roles: ['role-2'],
			status: 'ACCEPTED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000'],
			licensed: false
		},
		{
			id: 'mock-user-id-29',
			userName: 'mock-user-id-29',
			firstName: 'Sue',
			lastName: 'Johnson',
			email: 'sjohnson@example.com',
			mpId: 'mock-user-mpId-11',
			dateAdded: 1615002447928,
			roles: ['role-2'],
			status: 'ACCEPTED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000'],
			licensed: false
		}
	];
	const pendingUsers = [
		{
			id: 'mock-pending-user-1',
			email: 'jfrost@example.com',
			dateAdded: 1611202447928,
			token: 'f024244021',
			roles: ['role-1'],
			status: 'PENDING',
			userGroupId: ['userGroup-1', '00000000-1111-1111-1111-000000000000'],
			licensed: false
		},
		{
			id: 'mock-pending-user-3',
			email: 'zjohnson@example.com',
			dateAdded: 1616202447528,
			token: 'f024244027',
			roles: ['role-2'],
			status: 'PENDING',
			userGroupId: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000'],
			licensed: false
		}
	];
	const userGroups = [
		// uncomment user group 0 for a lengthy group title that falls to the next line
		// {
		// 	id: 'userGroup-0',
		// 	name: 'My name is Ozymandias, king of kings!  Look upon my works, ye mighty, and despair!',
		// 	updatedAt: 1619483850584
		// },
		// default ungrouped, user, and admin groups
		{
			id: '00000000-3333-3333-3333-000000000000',
			name: 'Default Group',
			updatedAt: 1619483850584
		},
		{
			id: '00000000-1111-1111-1111-000000000000',
			name: 'Users Group',
			updatedAt: 1619483850584
		},
		{
			id: '00000000-2222-2222-2222-000000000000',
			name: 'Administrators Group',
			updatedAt: 1619483850584
		},
		{
			id: 'userGroup-1',
			name: 'Exec Group',
			updatedAt: 1619483870584,
			roles: ['role-1'],
			defaultUser: 'mock-user-id'
		},
		{
			id: 'userGroup-2',
			name: 'Marketing Group',
			updatedAt: 1619483850584,
			roles: []
		},
		{
			id: 'userGroup-3',
			name: 'Admin Group',
			updatedAt: 1619483850584,
			roles: []
		}
	];
	// uncomment the call below to mimic hundreds of users
	// addHundredsOfUsers();
	const devices = [
		{
			id: 'DA61F011000003',
			name: 'First Floor - Lobby',
			model: '287',
			location: 'First Floor - Lobby',
			stcApp: true,
			ip: '888.888.888.888',
			serial: 'A61F011000003',
			description: 'First Floor - Lobby: Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000005',
			name: 'Second Floor - Lobby',
			model: '364e',
			location: 'Second Floor - Lobby',
			stcApp: true,
			ip: '12.34.56.80',
			serial: 'A61F011000005',
			description: 'Second Floor - Lobby Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000007',
			name: 'First Floor - Lab',
			model: '364e',
			location: 'First Floor - Lab',
			stcApp: true,
			ip: '12.34.56.81',
			serial: 'A61F011000007',
			description: 'First Floor - Lab Description',
			licensed: true,
			groupList: ['deviceGroup-1']
		},
		{
			id: 'A61F011000009',
			name: 'Second Floor - Lab',
			model: '4052',
			location: 'Second Floor - Lab',
			stcApp: true,
			ip: '12.34.56.82',
			serialNumber: 'A61F011000009',
			description: 'Second Floor - Lab Description Test Extra Long Description',
			licensed: false,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000011',
			name: 'Third Floor - Lab',
			model: '4750',
			location: 'Third Floor - Lab',
			stcApp: true,
			ip: '12.34.56.83',
			serial: 'A61F011000011',
			description: 'Third Floor - Lab Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000013',
			name: 'Warehouse',
			model: 'C287',
			location: 'Warehouse',
			stcApp: true,
			ip: '12.34.56.84',
			serial: 'A61F011000013',
			description: 'Warehouse Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000015',
			name: 'Third Floor - Lobby',
			model: 'C287',
			location: 'Third Floor - Lobby',
			stcApp: true,
			ip: '12.34.56.85',
			serial: 'A61F011000015',
			description: 'Third Floor - Lobby Description',
			licensed: false,
			groupList: ['deviceGroup-1']
		},
		{
			id: 'A61F011000017',
			name: 'Fourth Floor - Lobby',
			model: 'C300i',
			location: 'Fourth Floor - Lobby',
			stcApp: true,
			ip: '12.34.56.86',
			serial: 'A61F011000017',
			description: 'Fourth Floor - Lobby Description',
			licensed: true,
			groupList: ['deviceGroup-1', 'deviceGroup-2']
		},
		{
			id: 'A61F011000619',
			name: 'Fourth Floor - Lab',
			model: 'C666',
			location: 'Fourth Floor - Lab',
			stcApp: true,
			ip: '12.34.56.87',
			serial: 'A61F011000619',
			description:
				'0987h1234f0987h1234f0987h1234f987h1234f09871h234f0987h1234f0987h1234f0987h1234f0987h1234f-098j1234fj',
			licensed: true,
			groupList: ['deviceGroup-2', 'deviceGroup-3']
		},
		{
			id: 'A61F011000621',
			name: 'Fifth Floor - Lab',
			model: 'C666',
			location: 'Fifth Floor - Lab',
			stcApp: false,
			ip: '12.34.56.88',
			serial: 'A61F011000621',
			description: 'Fifth Floor - Lab Description',
			licensed: true,
			groupList: [
				'deviceGroup-1',
				'deviceGroup-2',
				'deviceGroup-3',
				'deviceGroup-4',
				'deviceGroup-5',
				'deviceGroup-6',
				'deviceGroup-7',
				'deviceGroup-8',
				'deviceGroup-9'
			]
		}
	];
	const deviceGroups = [
		{
			id: '00000000-4444-4444-4444-000000000000',
			name: '00000000-4444-4444-4444-000000000000',
			dateUpdated: 1619483870584
		},
		{
			id: 'deviceGroup-1',
			name: 'HQ Building 101'
		},
		{
			id: 'deviceGroup-2',
			name: 'HQ Building     201',
			dateUpdated: 1619483850584
		},
		{
			id: 'deviceGroup-3',
			name: 'HQ Building 301',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-4',
			name: 'HQ Building 401',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-5',
			name: 'HQ Building 501',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-6',
			name: 'HQ Building 601',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-7',
			name: 'HQ Building 701',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-8',
			name: 'HQ Building 801',
			dateUpdated: 1619483860587
		},
		{
			id: 'deviceGroup-9',
			name: 'HQ Building 901',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-10',
			name: 'HQ Building 1001',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-11',
			name:
				'Super Long Name that should wrap. aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
			dateUpdated: 1619483860584
		}
	];
	const appGroups = [
		{
			id: '00000000-6666-6666-6666-000000000000',
			name: ''
		},
		{
			id: 'appGroup-1',
			name: 'App HQ Building 101'
		},
		{
			id: 'appGroup-2',
			name: 'App HQ Building     201'
		},
		{
			id: 'appGroup-3',
			name: 'App HQ Building 301'
		},
		{
			id: 'appGroup-4',
			name: 'App HQ Building 401'
		},
		{
			id: 'appGroup-5',
			name:
				'App Super Long Name that should wrap. aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
		}
	];
	const apps = [
		{
			id: 'mock-app-id-1',
			name: 'Tickets (Internal) Form',
			description:
				'Create and manage tickets. Create and manage tickets. Create and manage tickets. Create and manage tickets. Lorem Ipsum is simply dummy text of the printing and typesetting industry. This app description is way too long.',
			icon: 'https://files.bizhub.sh/9d5ee41f343767d9211ec18c2c1a9a80961d1bb4',
			type: 'internal',
			workflowId: 'mock-workflow-id-1',
			nodeId: 'mock-node-id-1',
			licensed: true,
			groupList: ['appGroup-1'],
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: [
					{ name: 'userGroup-1', role: 'edit' },
					{ name: 'userGroup-2', role: 'view' }
				]
			},
			aclRole: 'owner'
		},
		{
			id: 'mock-app-id-2',
			name: 'Service Form For Calculating Total Time Spent to Launch',
			description: 'Create and manage services.',
			url: 'https://example.com/temp',
			icon: 'https://files.bizhub.sh/f15037a4b7f1522071cda7f0ec10fecc9a847c6c',
			type: 'service',
			licensed: true,
			groupList: ['appGroup-1'],
			acl: {
				users: [
					{ name: 'mock-user-id-3', role: 'owner' },
					{ name: user.id, role: 'view' },
					{ name: 'mock-user-id-5', role: 'edit' },
					{ name: 'mock-user-id-11', role: 'view' }
				],
				groups: []
			},
			aclRole: 'view'
		},
		{
			id: 'mock-app-id-3',
			name: 'Calendar (External) Form',
			description:
				'Create and manage calendars. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			icon: 'https://files.bizhub.sh/6d47ba9d245cce0d82f428e75f5c05e9ab9d8805',
			type: 'external',
			workflowId: 'mock-workflow-id-1',
			nodeId: 'mock-node-id-4',
			licensed: true,
			groupList: ['appGroup-1'],
			acl: {
				users: [
					{ name: 'mock-user-id-11', role: 'owner' },
					{ name: user.id, role: 'edit' },
					{ name: 'mock-user-id-5', role: 'edit' },
					{ name: 'mock-user-id-3', role: 'view' }
				],
				groups: []
			},
			aclRole: 'edit'
		},
		{
			id: 'mock-app-id-4',
			name: 'Contacts (External [URL]) Form',
			description:
				'Create and manage contacts. Create and manage contacts. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			url: 'https://example.com/mockexternalformurl',
			icon: 'https://files.bizhub.sh/c121946853077d1faaebe90b08161f1c56a658e0',
			type: 'external',
			workflowId: 'mock-workflow-id-1',
			nodeId: 'mock-node-id-4',
			licensed: true,
			groupList: ['appGroup-1'],
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: []
			},
			aclRole: 'owner'
		},
		{
			id: 'mock-app-id-5',
			name: 'Notes Form',
			description:
				'Create and manage notes. Create and manage notes. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			url: 'https://example.com/temp',
			icon: 'https://files.bizhub.sh/bc67f77fe921cee17c5e2907f15aa1a07ff60ea3',
			type: 'url',
			licensed: true,
			groupList: ['appGroup-2'],
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: []
			},
			aclRole: 'owner'
		},
		{
			id: 'mock-app-id-6',
			name: 'Tasks Form',
			description:
				'Create and manage tasks. Create and manage tasks. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			url: 'https://example.com/temp',
			icon: 'https://files.bizhub.sh/e62bae1d966671d55d83fb8b0b20bbb0004588bb',
			type: 'url',
			licensed: true,
			groupList: ['appGroup-2'],
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: []
			},
			aclRole: 'owner'
		},
		{
			id: 'mock-app-id-7',
			name: 'Email Form',
			description:
				'Create and manage emails. Create and manage emails. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			url: 'https://example.com/temp',
			icon: 'https://files.bizhub.sh/01fa6c06480fc0ed6ab7627711c18a25e5bd5fac',
			type: 'url',
			licensed: false,
			groupList: ['appGroup-2'],
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: []
			},
			aclRole: 'owner'
		},
		{
			id: 'mock-app-id-8',
			name: 'Documents Form',
			description:
				'Create and manage documents. Create and manage documents. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			url: 'https://example.com/temp',
			icon: 'https://files.bizhub.sh/86a6c1724111d6b6230f485adc49f422f2d38ee6',
			type: 'url',
			licensed: true,
			groupList: undefined,
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: []
			},
			aclRole: 'owner'
		},
		{
			id: 'mock-app-id-9',
			name: 'Photos Form',
			description:
				'Create and manage photos. Create and manage photos. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			url: 'https://example.com/temp',
			icon: 'https://files.bizhub.sh/9e7833cbc648be4fb14a6facfcf3c8fe845e7c11',
			type: 'url',
			licensed: false,
			groupList: undefined,
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: []
			},
			aclRole: 'owner'
		},
		{
			id: 'mock-app-id-10',
			name: 'Paperclip (Internal) Form',
			description:
				'Create and manage paperclipss. This workflow may not be found. Create and manage paperclips. Create and manage paperclips. Create and manage paperclips. Lorem Ipsum is simply dummy text of the printing and typesetting industry. This app description is way too long.',
			icon: 'https://files.bizhub.sh/9d5ee41f343767d9211ec18c2c1a9a80961d1bb4',
			type: 'internal',
			workflowId: 'mock-workflow-id-unknown',
			nodeId: 'mock-node-id-1',
			licensed: true,
			groupList: undefined,
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: [
					{ name: 'userGroup-1', role: 'edit' },
					{ name: 'userGroup-2', role: 'view' }
				]
			},
			aclRole: 'owner'
		}
	];
	const jobNames = [
		{
			id: 'A1',
			components: [],
			modified: 0,
			created: 0,
			editor: 'John Doe',
			creator: 'Jane Doe',
			groupList: ['01'],
			nodes: [
				{
					workflowId: 'apples',
					nodeId: 'oranges'
				}
			]
		},
		{
			id: 'C3',
			components: [],
			modified: 999991000000,
			created: 99991000000,
			editor: 'Jane Doe',
			creator: 'John Doe',
			groupList: ['01'],
			nodes: []
		},
		{
			id: 'B2',
			components: [],
			modified: 0,
			created: 0,
			editor: 'John Doe',
			creator: 'Jane Doe',
			groupList: ['02'],
			nodes: []
		},
		{
			id: 'D4',
			components: [],
			modified: 0,
			created: 0,
			editor: 'John Doe',
			creator: 'Jane Doe',
			groupList: ['01', '02'],
			nodes: []
		}
	];
	const jobNameGroups = [
		{
			id: '01',
			title: 'Dev'
		},
		{
			id: '02',
			title: 'QA'
		}
	];
	const inputNodes = [
		{
			id: 'inputNode-1',
			name: 'Dropbox In Node',
			licensed: true
		},
		{
			id: 'inputNode-2',
			name: 'Dropbox In Node',
			licensed: false
		}
	];
	const forms = [
		{
			id: 'form-1',
			title: 'Conversions and File Upload',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: undefined,
			created: +new Date('2022-04-18 15:55'),
			updated: +new Date('2022-04-18 15:55')
		},
		{
			id: 'form-2',
			title: 'Email Sorting Automation',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: undefined,
			created: +new Date('2022-04-18 15:55'),
			updated: +new Date('2022-04-18 15:55')
		},
		{
			id: 'form-3',
			title: 'Documentation Forms',
			creator: 'mock-user-id-27',
			formStatus: 'published',
			active: true,
			formGroupId: undefined,
			created: +new Date('2022-04-16 15:55'),
			updated: +new Date('2022-04-17 15:55')
		},
		{
			id: 'form-5',
			title: 'File Storage',
			creator: 'mock-user-id-29',
			formStatus: 'published',
			active: true,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-14 15:55'),
			updated: +new Date('2022-04-15 15:55')
		},
		{
			id: 'form-7',
			title: 'Public Conversions with Upload',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-12 15:55'),
			updated: +new Date('2022-04-13 15:55')
		},
		{
			id: 'form-9',
			title: 'Upload Documents',
			creator: 'mock-user-id-27',
			formStatus: 'published',
			active: true,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-12 15:55'),
			updated: +new Date('2022-04-13 15:55')
		},
		{
			id: 'form-11',
			title: 'File Conversion',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-10 15:55'),
			updated: +new Date('2022-04-11 15:55')
		},
		{
			id: 'form-13',
			title: 'Cloud Storage',
			creator: 'mock-user-id-27',
			formStatus: 'published',
			active: true,
			formGroupId: 'formGroup-2',
			created: +new Date('2022-04-10 15:55'),
			updated: +new Date('2022-04-11 15:55')
		}
	];
	const formGroups = [
		{
			id: 'formGroup-1',
			title: 'Dev Forms',
			updatedAt: 1619483870584
		},
		{
			id: 'formGroup-2',
			title: 'Team Forms',
			updatedAt: 1619483850584
		},
		{
			id: 'formGroup-3',
			title: 'Test Forms',
			updatedAt: 1619483850585
		}
	];

	const roles = [
		{
			id: 'role-1',
			name: 'Tenant Super Admin',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: true,
						add: true,
						edit: true,
						remove: true,
						activate: true,
						createGroup: true,
						editGroup: true,
						deleteGroup: true
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: true,
						edit: true,
						remove: true,
						import: true,
						activate: true,
						deactivate: true,
						createGroup: true,
						editGroup: true,
						deleteGroup: true,
						clone: true
					}
				},
				usersTab: {
					usersSection: {
						view: true,
						invite: true,
						edit: true,
						remove: true,
						block: true,
						createGroup: true,
						editGroup: true,
						deleteGroup: true,
						transferOwnership: true,
						assignUser: true,
						assignGroup: true
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						share: true,
						import: true,
						run: true,
						// schedule: true,
						changeOwner: true,
						// simulation: true,
						createGroup: true,
						shareGroup: true,
						editGroup: true,
						deleteGroup: true,
						clone: true,
						samples: true
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						activate: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					settingsSection: {
						view: true,
						edit: true
					},
					profileSection: {
						outOfOffice: true
					}
				},
				rolesTab: {
					rolesSection: {
						view: true,
						create: true,
						edit: true,
						remove: true
						// clone: true
					}
				}
			},
			systemRole: 'tenant-super-admin'
		},
		{
			id: 'role-2',
			name: 'Tenant Admin',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: true,
						add: true,
						edit: true,
						remove: true,
						activate: true,
						createGroup: true,
						editGroup: true,
						deleteGroup: true
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: true,
						edit: true,
						remove: true,
						import: true,
						activate: true,
						deactivate: true,
						createGroup: true,
						editGroup: true,
						deleteGroup: true,
						clone: true
					}
				},
				usersTab: {
					usersSection: {
						view: true,
						invite: true,
						edit: true,
						remove: true,
						block: true,
						createGroup: true,
						editGroup: true,
						deleteGroup: true,
						transferOwnership: true,
						assignUser: true,
						assignGroup: true
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						share: true,
						import: true,
						run: true,
						// schedule: true,
						changeOwner: true,
						// simulation: true,
						createGroup: true,
						shareGroup: true,
						editGroup: true,
						deleteGroup: true,
						clone: true,
						samples: true
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						activate: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					settingsSection: {
						view: false,
						edit: false
					},
					profileSection: {
						outOfOffice: true
					}
				},
				rolesTab: {
					rolesSection: {
						view: true,
						create: true,
						edit: true,
						remove: true
						// clone: true
					}
				}
			}
		},
		{
			id: 'role-3',
			name: 'Tenant User',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: false,
						add: false,
						edit: false,
						remove: false,
						activate: false,
						createGroup: false,
						editGroup: false,
						deleteGroup: false
					}
				},
				formsTab: {
					formsSection: {
						view: false,
						add: false,
						edit: false,
						remove: false,
						import: false,
						activate: false,
						deactivate: false,
						createGroup: false,
						editGroup: false,
						deleteGroup: false,
						clone: false
					}
				},
				usersTab: {
					usersSection: {
						view: false,
						invite: false,
						edit: false,
						remove: false,
						block: false,
						createGroup: false,
						editGroup: false,
						deleteGroup: false,
						transferOwnership: false,
						assignUser: false,
						assignGroup: false
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: true,
						create: false,
						edit: false,
						remove: false,
						share: false,
						import: false,
						run: false,
						schedule: false,
						changeOwner: false,
						simulation: false,
						createGroup: false,
						shareGroup: false,
						editGroup: false,
						deleteGroup: false,
						clone: false,
						samples: false
					}
				},
				dataTab: {
					reportsSection: {
						view: false
					},
					logsSection: {
						view: false
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						activate: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					settingsSection: {
						view: false,
						edit: false
					},
					profileSection: {
						outOfOffice: true
					}
				},
				rolesTab: {
					rolesSection: {
						view: false,
						create: false,
						edit: false,
						remove: false
					}
				}
			}
		},
		{
			id: 'role-4',
			name: 'Executive',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: true,
						add: false,
						edit: true,
						remove: false,
						activate: true,
						createGroup: true,
						editGroup: false,
						deleteGroup: true
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: false,
						edit: false,
						remove: true,
						import: false,
						activate: true,
						deactivate: false,
						createGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				usersTab: {
					usersSection: {
						view: false,
						invite: true,
						edit: false,
						remove: true,
						block: false,
						createGroup: true,
						editGroup: true,
						deleteGroup: false,
						transferOwnership: true,
						assignUser: true,
						assignGroup: true
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						share: false,
						import: true,
						run: false,
						// schedule: true,
						changeOwner: false,
						// simulation: true,
						createGroup: false,
						shareGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false,
						samples: true
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						activate: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					settingsSection: {
						view: true,
						edit: true
					},
					profileSection: {
						outOfOffice: true
					}
				},
				rolesTab: {
					rolesSection: {
						view: false,
						create: true,
						edit: false,
						remove: true
						// clone: true
					}
				}
			}
		},
		{
			id: 'role-5',
			name: 'Storeroom',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: false,
						add: false,
						edit: false,
						remove: false,
						activate: false,
						createGroup: false,
						editGroup: false,
						deleteGroup: false
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: false,
						edit: false,
						remove: true,
						import: false,
						activate: true,
						deactivate: false,
						createGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				usersTab: {
					usersSection: {
						view: false,
						invite: true,
						edit: false,
						remove: true,
						block: false,
						createGroup: true,
						editGroup: true,
						deleteGroup: false,
						transferOwnership: true,
						assignUser: true,
						assignGroup: true
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						share: false,
						import: true,
						run: false,
						// schedule: true,
						changeOwner: false,
						// simulation: true,
						createGroup: false,
						shareGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false,
						samples: false
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						activate: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					settingsSection: {
						view: true,
						edit: true
					},
					profileSection: {
						outOfOffice: true
					}
				},
				rolesTab: {
					rolesSection: {
						view: true,
						create: true,
						edit: true,
						remove: true
						// clone: true
					}
				}
			}
		}
	];

	const permissions = {
		devicesTab: {
			devicesSection: {
				view: true,
				add: true,
				edit: true,
				remove: true,
				activate: true,
				createGroup: true,
				editGroup: true,
				deleteGroup: true
			},
			inputNodesSection: {
				view: true,
				viewAll: true,
				assign: true,
				assignAll: true
			}
		},
		formsTab: {
			formsSection: {
				view: true,
				add: true,
				edit: true,
				remove: true,
				import: true,
				activate: true,
				deactivate: true,
				createGroup: true,
				editGroup: true,
				deleteGroup: true,
				clone: true
			}
		},
		usersTab: {
			usersSection: {
				view: true,
				invite: true,
				edit: true,
				remove: true,
				block: true,
				createGroup: true,
				editGroup: true,
				deleteGroup: true,
				transferOwnership: true,
				assignUser: true,
				assignGroup: true
			}
		},
		appsTab: {
			internalSection: {
				create: true,
				view: true,
				delete: true,
				share: true
			},
			externalSection: {
				create: true,
				view: true,
				delete: true,
				share: true
			},
			urlSection: {
				create: true,
				view: true,
				delete: true,
				share: true
			}
		},
		workflowsTab: {
			workflowsSection: {
				view: true,
				create: true,
				edit: true,
				remove: true,
				share: true,
				import: true,
				run: true,
				// schedule: true,
				changeOwner: true,
				// simulation: true,
				createGroup: true,
				shareGroup: true,
				editGroup: true,
				deleteGroup: true,
				clone: true,
				samples: true
			}
		},
		dataTab: {
			reportsSection: {
				view: true
			},
			logsSection: {
				view: true
			}
		},
		jobsTab: {
			jobQueueSection: {
				view: true,
				create: true,
				edit: true,
				remove: true,
				activate: true
			},
			trackerSection: {
				view: true,
				track: true
			},
			tasksSection: {
				view: true
			}
		},
		settingsTab: {
			settingsSection: {
				view: true,
				edit: true
			},
			profileSection: {
				outOfOffice: true
			}
		},
		rolesTab: {
			rolesSection: {
				view: true,
				create: true,
				edit: true,
				remove: true
				// clone: true
			}
		}
	};

	const features = {
		advanced_forms: true,
		advanced_roles_and_permissions: true,
		annotate: true,
		assign_tenant_manager: true,
		azure_ad_integration: true,
		convert_to_pdf_w_tesseract: true,
		device_sharing: true,
		dropbox_in: true,
		email_in: true,
		forms_management: true,
		forms_selector: true,
		home_page: true,
		import_device: true,
		license_calculator: true,
		licensing_platform: true,
		linear_forms: true,
		metadata_scripting: true,
		'multi-tiered_tenant_management': true,
		nfr_portal: true,
		odbc: true,
		people_node: true,
		processing_node_logging: true,
		public_user_access: true,
		rendering_custom_forms_on_mfps: true,
		'reskin_portal&customization_option': true,
		samples_shared_with_all_admins: true,
		support_for_non_mfps: true,
		support_for_paragon_integration: true,
		tenant_settings_for_timezone: true,
		upland_fax_connector: true
	};

	const sampleWorkflows = [
		{
			id: 'sample-workflow-1',
			name: 'Sample Scan to Google Drive',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:30:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-2',
			name: 'Sample Scan to Box',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:31:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-3',
			name: 'Sample Scan to OneDrive for Business',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:33:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-9',
			name: 'Sample Route to SharePoint Online',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:34:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-11',
			name: 'Sample Route to Marketing',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:35:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-13',
			name: 'Sample Route to R&D',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:36:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-15',
			name: 'Sample Convert and Route to Box',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:37:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-17',
			name: 'Sample Convert and Route to SharePoint',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:38:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-19',
			name: 'Sample Convert and Route to OneDrive',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:39:58.4033151+00:00'
		},
		{
			id: 'sample-workflow-21',
			name: 'Sample Route to R&D',
			status: 'draft',
			description: '',
			modified: '2022-08-15T14:40:58.4033151+00:00'
		}
	];

	const workflows = [
		{
			id: 'mock-workflow-id-1',
			name: 'Scan to Google Drive',
			status: 'running',
			modified: '2022-08-15T14:30:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 3,
				group: 'workflowGroup-1'
			},
			workflowGroupId: 'workflowGroup-1'
		},
		{
			id: 'mock-workflow-id-2',
			name: 'Scan to Box',
			status: 'running',
			modified: '2022-08-15T14:31:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-1'
			},
			workflowGroupId: 'workflowGroup-1'
		},
		{
			id: '4b501a9b9d2e493baaeee3c440c355ad',
			name: 'Scan to OneDrive for Business',
			status: 'stopped',
			modified: '2022-08-15T14:33:58.4033151+00:00',
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: [
					{ name: 'userGroup-1', role: 'edit' },
					{ name: 'userGroup-2', role: 'view' }
				]
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-1'
			},
			workflowGroupId: 'workflowGroup-1'
		},
		{
			id: 'workflow-9',
			name: 'Route to SharePoint Online',
			status: 'running',
			modified: '2022-08-15T14:34:58.4033151+00:00',
			acl: {
				users: [
					{ name: user.id, role: 'owner' },
					{ name: 'mock-user-id-3', role: 'edit' },
					{ name: 'mock-user-id-5', role: 'edit' },
					{ name: 'mock-user-id-11', role: 'view' }
				],
				groups: []
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-11',
			name: 'Route to Marketing',
			status: 'stopped',
			modified: '2022-08-15T14:35:58.4033151+00:00',
			acl: {
				users: [
					{ name: user.id, role: 'owner' },
					{ name: 'mock-user-id-3', role: 'edit' },
					{ name: 'mock-user-id-5', role: 'edit' },
					{ name: 'mock-user-id-11', role: 'view' }
				],
				groups: []
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-13',
			name: 'Route to R&D',
			status: 'running',
			modified: '2022-08-15T14:36:58.4033151+00:00',
			acl: {
				users: [{ name: 'mock-user-id-13', role: 'owner' }],
				groups: []
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-15',
			name: 'Convert and Route to Box',
			status: 'paused',
			modified: '2022-08-15T14:37:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-17',
			name: 'Convert and Route to SharePoint',
			status: 'draft',
			modified: '2022-08-15T14:38:58.4033151+00:00',
			acl: {
				users: [
					{ name: 'mock-user-id-19', role: 'owner' },
					{ name: 'mock-user-id-21', role: 'edit' },
					{ name: 'mock-user-id-23', role: 'view' }
				],
				groups: [{ name: 'userGroup-2', role: 'view' }]
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-19',
			name: 'Convert and Route to OneDrive',
			status: 'draft',
			modified: '2022-08-15T14:39:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 1,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-21',
			name: 'Route to R&D',
			status: 'stopped',
			modified: '2022-08-15T14:40:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 6,
				Group: 'workflowGroup-3'
			},
			workflowGroupId: 'workflowGroup-3'
		}
	];
	// uncomment below to mimic a workflow table that needs a scrollbar
	const tasks = [
		'Go to warp',
		'Open pod bay doors',
		'Use the Force',
		'Release the Kraken',
		'Test your might',
		'Do the time warp again',
		'Fastball Special',
		'Let the spice flow',
		'Kneel before Zod',
		'Save the cheerleader, save the world',
		'Hawkmen, dive',
		'Build it, they will come',
		'Make it so',
		'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
	];
	for (let i = 0; i < tasks.length; i += 1) {
		workflows.push({
			id: `workflow-${i + 22}`,
			name: tasks[i],
			status: 'running',
			modified: '2022-08-15T14:30:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 0,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		});
	}
	const workflowGroups = [
		{
			id: 'workflowGroup-1',
			name: 'My Workflows',
			modified: 1619483870584
		},
		{
			id: 'workflowGroup-2',
			name: 'Other Workflows',
			modified: 1617483870584
		},
		{
			id: 'workflowGroup-3',
			name: 'Some Workflows',
			modified: 1615483870584
		}
		// {
		// 	id: 'workflowGroup-4',
		// 	name: 'More Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-5',
		// 	name: 'Further Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-6',
		// 	name: 'Extra Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-7',
		// 	name: 'Bonus Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-8',
		// 	name: 'Added Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-9',
		// 	name: 'New Workflows',
		// 	updatedAt: 1615483870584
		// }
	];
	const logs = [
		{
			SK: 'log-1',
			Type: 'workflow',
			severity: 'low',
			DateAdded: 1646110800000,
			MsgKey: 'Workflow Secure Files was checked in.',
			MessageTranslated: 'Workflow Secure Files was checked in.',
			Action: 'Check in',
			Info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			SK: 'log-2',
			Type: 'workflow',
			severity: 'low',
			DateAdded: 1646197200000,
			MsgKey: 'Workflow Scan Financial Records was checked out.',
			MessageTranslated: 'Workflow Scan Financial Records was checked out.',
			Action: 'Check out',
			Info: {
				deviceId: 'A112233440002',
				deviceName: '364e'
			}
		},
		{
			SK: 'log-3',
			Type: 'workflow',
			severity: 'low',
			DateAdded: 1646283600000,
			MsgKey: 'Workflow Verification was started and failed.',
			MessageTranslated: 'Workflow Verification was started and failed.',
			Action: 'Error',
			Info: {
				deviceId: 'A112233440003',
				deviceName: '364e',
				policyFails: {
					msg: "I can't do that, Dave",
					deviceSetting: 120,
					msgKey: 'encryptpdfFail',
					requiredSetting: '15',
					policyItemKey: 'encryptpdf'
				}
			}
		},
		{
			SK: 'log-4',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646370000000,
			MsgKey: 'Workflow Secure Files was paused.',
			MessageTranslated: 'Workflow Secure Files was paused.',
			Action: 'Pause',
			Info: {
				deviceId: 'A112233440004',
				deviceName: '364e',
				policyFails: [
					{
						msg: "hhdencrypt is 'false' but should be 'true'",
						deviceSetting: false,
						msgKey: 'hhdencryptFail',
						requiredSetting: 'true',
						policyItemKey: 'hhdencrypt'
					},
					{
						msg: "encryptpdf is '120' but should be '5'",
						deviceSetting: 120,
						msgKey: 'encryptpdfFail',
						requiredSetting: '5',
						policyItemKey: 'encryptpdf'
					}
				]
			}
		},
		{
			SK: 'log-5',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646456400000,
			MsgKey: 'Workflow Secure Files was scheduled.',
			MessageTranslated: 'Workflow Secure Files was scheduled.',
			Action: 'Scheduled',
			Info: {
				deviceId: 'A112233440005',
				deviceName: 'C300',
				policyFails: [
					{
						msg: "encryptpdf is '120' but should be '15'",
						deviceSetting: 120,
						msgKey: 'encryptpdfFail',
						requiredSetting: '15',
						policyItemKey: 'encryptpdf'
					}
				]
			}
		},
		{
			SK: 'log-6',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646542800000,
			MsgKey: 'Workflow Scan Financial Records was started.',
			MessageTranslated: 'Workflow Scan Financial Records was started.',
			Action: 'Start',
			Info: {
				deviceId: 'A112233440006',
				deviceName: 'c558',
				policyFails: [
					{
						msg: "idandprint is '60`' but should be '30'",
						deviceSetting: 60,
						msgKey: 'idandprintFail',
						requiredSetting: '30',
						policyItemKey: 'idandprint'
					}
				]
			}
		},
		{
			SK: 'log-7',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646629200000,
			MsgKey: 'Workflow Scan Financial Records was stopped.',
			MessageTranslated: 'Workflow Scan Financial Records was stopped.',
			Action: 'Stop',
			Info: {
				deviceId: 'A112233440007',
				deviceName: 'C300i',
				policyFails: [
					{
						msg: "publicauth is '2' but should be '1'",
						deviceSetting: 2,
						msgKey: 'publicauthFail',
						requiredSetting: '1',
						policyItemKey: 'publicauth'
					}
				]
			}
		},
		{
			SK: 'log-8',
			Type: 'device',
			severity: 'low',
			DateAdded: 1646715600000,
			MsgKey: 'Device bizhub 364e was added.',
			MessageTranslated: 'Device bizhub 364e was added.',
			Action: 'Added',
			Info: {
				deviceId: 'A112233440008',
				deviceName: '364e'
			}
		},
		{
			SK: 'log-10',
			Type: 'device',
			severity: 'low',
			DateAdded: 1646888400000,
			MsgKey: 'Device bizhub C300i was removed.',
			MessageTranslated: 'Device bizhub C300i was removed.',
			Action: 'Removed',
			Info: {
				deviceId: 'A1122334400010',
				deviceName: 'C300i'
			}
		},
		{
			SK: 'log-11',
			Type: 'device',
			severity: 'low',
			DateAdded: 1646974800000,
			MsgKey: 'Device bizhub 558 was registered.',
			MessageTranslated: 'Device bizhub 558 was registered.',
			Action: 'Registered',
			Info: {
				deviceId: 'A1122334400011',
				deviceName: '558'
			}
		},
		{
			SK: 'log-12',
			Type: 'device',
			severity: 'low',
			DateAdded: 1647061200000,
			MsgKey: 'Device bizhub C300i was unregistered.',
			MessageTranslated: 'Device bizhub C300i was unregistered.',
			Action: 'Unregistered',
			Info: {
				deviceId: 'A1122334400012',
				deviceName: 'C300i'
			}
		},
		{
			SK: 'log-13',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647147600000,
			MsgKey: 'User rrichards@example.com was activated.',
			MessageTranslated: 'User rrichards@example.com was activated.',
			Action: 'Activated',
			Info: {
				deviceId: 'A1122334400013',
				deviceName: '558'
			}
		},
		{
			SK: 'log-14',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647230400000,
			MsgKey: 'User kpryde@example.com was blocked.',
			MessageTranslated: 'User kpryde@example.com was blocked.',
			Action: 'Blocked',
			Info: {
				type: 'change-admin-password',
				id: '243000008-7g3uemBjwKNCVuzjes',
				status: 'completed',
				deviceId: 'A1122334400014',
				deviceName: 'C300'
			}
		},
		{
			SK: 'log-17',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647403200000,
			MsgKey: 'User ebrooks@example.com was blocked and failed.',
			MessageTranslated: 'User ebrooks@example.com was blocked and failed.',
			Action: 'Error',
			Info: {
				msgParm1: 'autodoc',
				msgParm2: 'Day3',
				msgParm3: 'Day7',
				deviceId: 'A1122334400017',
				deviceName: '558',
				policyFails: {
					msg: "I can't do that, Dave",
					deviceSetting: 120,
					msgKey: 'encryptpdfFail',
					requiredSetting: '15',
					policyItemKey: 'encryptpdf'
				}
			}
		},
		{
			SK: 'log-19',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647489600000,
			MsgKey: 'User hmccoy@example.com accepted user invite.',
			MessageTranslated: 'User hmccoy@example.com accepted user invite.',
			Action: 'Invited',
			Info: {
				msgParm1: 'ssltls',
				msgParm2: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'true', 'tls10': 'false'}",
				msgParm3: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'false', 'tls10': 'false'}",
				deviceId: 'A1122334400019',
				deviceName: 'C300'
			}
		},
		{
			SK: 'log-20',
			Type: 'device',
			severity: 'low',
			DateAdded: 1000,
			MsgKey: 'I cannot do that, Dave.',
			MessageTranslated: 'I cannot do that, Dave.',
			Action: 'Error',
			Info: {
				msgParm1: 'ssltls',
				msgParm2: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'true', 'tls10': 'false'}",
				msgParm3: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'false', 'tls10': 'false'}",
				deviceId: 'A1122334400019',
				deviceName: 'C300'
			}
		}
	];
	const mpDevices = [
		{
			id: 'mock-mp-device-guid-1',
			name: 'C558',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-1',
			friendlyName: 'Second Floor - Dev'
		},
		{
			id: 'mock-mp-device-guid-2',
			name: 'C556',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-2',
			friendlyName: 'Second Floor - QA'
		},
		{
			id: 'mock-mp-device-guid-3',
			name: 'C554',
			family: 'VenusMLK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-3',
			friendlyName: 'Second Floor - Lab'
		},
		{
			id: 'mock-mp-device-guid-4',
			name: 'C358',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-4'
		},
		{
			id: 'mock-mp-device-guid-5',
			name: 'C356',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-5'
		},
		{
			id: 'mock-mp-device-guid-6',
			name: 'C354',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-6'
		},
		{
			id: 'mock-mp-device-guid-7',
			name: 'C666',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-7'
		},
		{
			id: 'mock-mp-device-guid-8',
			name: 'C667',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-8'
		},
		{
			id: 'mock-mp-device-guid-9',
			name: 'C668',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-9'
		}
	];

	// COMMENT ME TO A BE A USER
	// mock.onGet('/api/v1/tenant').reply(401, {
	if (localStorage.getItem('mockWorkflows')) {
		const wfxApiUrl = getWfxUrl('us-east-1');

		mock.onGet(`${wfxApiUrl}/api/samples`).reply(200, sampleWorkflows);

		mock.onGet(`${wfxApiUrl}/api/wfx`).reply(200, workflows);

		mock.onGet(`${wfxApiUrl}/api/zip?password=false&id=workflow-21`).reply(
			200,
			Buffer.from('arrayBufferOfWorkflowDataFake')
		);
		mock.onGet(`${wfxApiUrl}/api/groups`).reply(200, workflowGroups);
	}

	if (localStorage.getItem('mockForms')) {
		const formsApiUrl = getFormsUrl(user.awsRegion);
		mock.onGet(`${formsApiUrl}/api/groups`).reply(200, [
			{
				id: '4976d6b2fccd40ce9b825c1eb12a5434',
				title: 'form-group-2',
				sortOrder: 2
			},
			{
				id: 'eabdc55208d842619bccb6397680930a',
				title: 'group-2',
				sortOrder: 3
			}
		]);

		mock.onGet(`${formsApiUrl}/api/forms`).reply(200, [
			{
				id: '1a2b3c4d5e6f7g8h',
				title: 'Project X',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696000000000,
				updated: 1696100000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '2b3c4d5e6f7g8h9i',
				title: 'Task Management 2',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696100000000,
				updated: 1696200000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c4d5e6f7g8h9i0j',
				title: 'Product Launch 3',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696200000000,
				updated: 1696300000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '4d5e6f7g8h9i0j1k',
				title: 'Meeting Notes 4',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696300000000,
				updated: 1696400000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '5e6f7g8h9i0j1k2l',
				title: 'Expense Report 5',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696400000000,
				updated: 1696500000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '6f7g8h9i0j1k2l3m',
				title: 'Project Y',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696500000000,
				updated: 1696600000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '7g8h9i0j1k2l3m4n',
				title: 'Survey Results 7',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696600000000,
				updated: 1696700000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '8h9i0j1k2l3m4n5o',
				title: 'Marketing Campaign 8',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696700000000,
				updated: 1696800000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c58f69b470f42178b77d9249e2f3989',
				title: 'nmassey',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1695312048179,
				updated: 1695312065423,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: 'b555c9e7f8fa41dfbc5a79683e22dd43',
				title: 'Test 1',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1694832174280,
				updated: 1694832778817,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			}
		]);
	}

	if (localStorage.getItem('mock')) {
		mock.onGet('/api/tenants').reply(200, _.keyBy(managedLicenseGroups, 'id'));
		mock.onGet('/api/tenants/tenant-manager-groups').reply(200, _.keyBy(tenantLicenseGroups, 'id'));
		mock.onPost('/api/tenants/tenant-manager-groups').reply(async config => {
			const { name, parentId } = JSON.parse(config.data);
			const parentGroup = tenantLicenseGroups.filter(item => item.id === parentId)[0];

			const rsp: any = tenantLicenseGroups;
			rsp.push({
				name,
				// eslint-disable-next-line prefer-template
				id: '' + Math.random() * 100,
				parentId,
				parentName: parentGroup ? parentGroup.name : undefined
			});
			return [200, rsp];
		});
		mock.onGet(/\/api\/mp\/license\/order\/[^/]+$/).reply(async config => {
			const order = {
				catalogItems: [
					{
						expirationDate: 2554482820000,
						materialNumber: 'AEMPA04',
						name: 'Dispatcher Stratus 1 device license, 1 Year',
						publicId: PublicId.OneDeviceLicenseFiveYear,
						quantity: 10,
						uid: 'dps-stratus-device-term1'
					},
					{
						expirationDate: null,
						materialNumber: 'STRATUSUP4YR',
						name: 'Dispatcher Stratus 1 device upgrade license, 4 Year',
						publicId: '623001AF-C247-4882-AC71-F35728A1BE07',
						quantity: 11,
						uid: 'dps-stratus-device-upgrade-term4'
					}
				],
				// catalogPublicId: PublicId.OneDeviceLicenseFiveYear,
				// materialNumber: 'AEMPA00',
				country: 'US',
				creationDate: 1681912598915,
				// expirationDate: 2554482820000,
				// userExpirationDate: 2554482820000,
				number: 'STC0000001',
				// quantity: 1,
				rocs: ['BUS'],
				type: 'PRODUCTION'
			};
			return [200, { order }];
		});
		mock.onPut('/api/tenants/tenant-manager-groups').reply(async config => {
			const { name, parentId, id } = JSON.parse(config.data);
			const currentIndex = tenantLicenseGroups.findIndex(item => item.id === id);

			const [parentName] = tenantLicenseGroups.filter(item => item.id === parentId);
			const rsp = tenantLicenseGroups;
			tenantLicenseGroups.splice(currentIndex, 1);
			rsp.push({
				name,
				parentId: parentId ?? undefined,
				id,
				parentName: parentName?.name,
				managerEmails: []
			});
			return [200, _.keyBy(rsp, 'id')];
		});
		mock.onPut(/\/api\/tenants\/edit-tenant-details\/[^/]+$/).reply(async config => {
			const id = config.url!.split('/').pop()!;
			const { name, parentId } = JSON.parse(config.data);
			const currentIndex = licenseGroups.findIndex(item => item.id === id);

			const rsp: any = licenseGroups
				.filter(({ isManager }) => isManager)
				.map(licenseGroup => _.omit(licenseGroup, ['isAdmin', 'isManager']));
			const currentTenant = licenseGroups.splice(currentIndex, 1);
			// @ts-ignore
			rsp.push({
				...currentTenant[0],
				name,
				parent: parentId,
				id
			});
			return [200, _.keyBy(rsp, 'id')];
		});

		mock.onGet(/\/api\/tenants\/tenant-manager-group\/[^/]+$/).reply(async config => {
			return [200, users];
		});

		mock.onGet(/\/api\/inputNode\/licenses\/[^/]+$/).reply(async config => {
			const dat = {
				userId: 'mock-user-id',
				capacity: 10,
				quantity: 3,
				licenses: {
					licenses: {
						'Dropbox In': [
							{
								accountId: '',
								path: '',
								workflows: [
									{
										workflowId: 'workflow-22',
										refCount: 2
									}
								]
							}
						],
						'GMail In': [
							{
								accountId: '',
								path: '',
								workflows: [
									{
										workflowId: 'workflow-23',
										refCount: 1
									}
								]
							}
						]
					}
				}
			};
			return [200, dat];
		});

		mock.onGet(/\/api\/inputNode\/licenses/).reply(async config => {
			const dat = {
				'mock-user-id': {
					userId: 'mock-user-id',
					capacity: 10,
					quantity: 3,
					licenses: {
						licenses: {
							'Dropbox In': [
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-22',
											refCount: 2
										}
									]
								},
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-22',
											refCount: 2
										}
									]
								}
							],
							'GMail In': [
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-23',
											refCount: 1
										},
										{
											workflowId: 'workflow-22',
											refCount: 1
										}
									]
								}
							]
						}
					}
				},
				'mock-user-id-5': {
					userId: 'mock-user-id-5',
					capacity: 5,
					quantity: 2,
					licenses: {
						licenses: {
							'Dropbox In': [
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-24',
											refCount: 1
										}
									]
								}
							],
							'EMail In': [
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-25',
											refCount: 1
										}
									]
								}
							]
						}
					}
				},
				'mock-user-id-11': {
					userId: 'mock-user-id-11',
					capacity: 5,
					quantity: 3,
					licenses: {
						licenses: {
							'GMail In': [
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-26',
											refCount: 1
										}
									]
								}
							],
							'Dropbox In': [
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-00',
											refCount: 2
										}
									]
								}
							]
						}
					}
				},
				'mock-user-id-19': {
					userId: 'mock-user-id-19',
					capacity: 5,
					quantity: 1,
					licenses: {
						licenses: {
							'GMail In': [
								{
									accountId: '',
									path: '',
									workflows: [
										{
											workflowId: 'workflow-28',
											refCount: 1
										}
									]
								}
							]
						}
					}
				},
				'mock-user-id-21': {
					userId: 'mock-user-id-21',
					capacity: 0,
					quantity: 0,
					licenses: {}
				}
			};
			return [200, dat];
		});

		mock.onPost(/\/api\/tenants\/[^/]+\/notifications/).reply(async config => {
			const { obj } = JSON.parse(config.data);
			const id = config.url?.split('/')[3];
			obj.groupId = id;
			return [200, obj];
		});

		mock.onPost(/\/api\/tenants\/[^/]+\/suspension/).reply(async config => {
			const { obj } = JSON.parse(config.data);
			const id = config.url?.split('/')[3];
			obj.tenantId = id;
			return [200, obj];
		});
		mock.onPatch(/\/api\/tenants\/tenant-group\/[^/]+\/invite-manager/).reply(async config => {
			const id = config.url?.split('/')[4];
			const { managerEmail } = JSON.parse(config.data);
			const update = tenantLicenseGroups.reduce((prev: any, item: any) => {
				if (item.id === id) {
					const copy = item;
					// eslint-disable-next-line no-unused-expressions
					copy.managerEmails?.push({ email: managerEmail, status: 'PENDING' });
					return [...prev, copy];
				}
				return [...prev, item];
			}, []);

			return [200, update];
		});
		mock.onGet(/\/api\/tenants\/slug\/[^/]+$/).reply(config => {
			const slug = config.url!.split('/').pop()!;
			const requestedLicenseGroup = licenseGroups.find(licenseGroup => licenseGroup.slug === slug);
			return requestedLicenseGroup ? [200, { id: requestedLicenseGroup.id }] : [404];
		});

		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/details`).reply(200, selectedLicenseGroup);
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/users`).reply(200, _.keyBy(users, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/pendingUsers`).reply(200, _.keyBy(pendingUsers, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/userGroups`).reply(200, _.keyBy(userGroups, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/devices`).reply(200, _.keyBy(devices, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/deviceGroups`).reply(200, _.keyBy(deviceGroups, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/inputNodes`).reply(200, _.keyBy(inputNodes, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/forms`).reply(200, _.keyBy(forms, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/formGroups`).reply(200, _.keyBy(formGroups, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/roles`).reply(200, _.keyBy(roles, 'id'));
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}/adminInfo`).reply(200, selectedLicenseGroup.adminData);
		mock.onGet(`/api/app/app-group`).reply(200, appGroups);
		mock.onGet(`/api/naming/jnt/${selectedLicenseGroup.id}`).reply(200, _.keyBy(jobNames, 'id'));
		mock.onGet(`/api/naming/jntg/${selectedLicenseGroup.id}/job-name-template-group`).reply(
			200,
			_.keyBy(jobNameGroups, 'id')
		);

		// mock.onGet(`/api/v1/tenant/${selectedLicenseGroup.id}`).reply(200, selectedLicenseGroup);
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}`).reply(200, {
			details: selectedLicenseGroup,
			users: _.keyBy(users, 'id'),
			pendingUsers: _.keyBy(pendingUsers, 'id'),
			userGroups: _.keyBy(userGroups, 'id'),
			devices: _.keyBy(devices, 'id'),
			deviceGroups: _.keyBy(deviceGroups, 'id'),
			appGroups: _.keyBy(appGroups, 'id'),
			inputNodes: _.keyBy(inputNodes, 'id'),
			forms: _.keyBy(forms, 'id'),
			formGroups: _.keyBy(formGroups, 'id'),
			roles: _.keyBy(roles, 'id')
			// workflows: _.keyBy(workflows, 'id'),
			// workflowGroups: _.keyBy(workflowGroups, 'id')
			// logs: _.keyBy(logs, 'id')
		});

		const wfxApiUrl = getWfxUrl('us-east-1');
		mock.onGet(`${wfxApiUrl}/api/groups`).reply(200, workflowGroups);
		mock.onGet(`/api/tenants/logs/${selectedLicenseGroup.id}`).reply(200, _.keyBy(logs, 'SK'));
		mock.onGet(`/api/device/user-devices`).reply(200, _.keyBy(mpDevices, 'id'));

		mock.onGet(`/api/user/user-tenants`).reply(200, adminedLicenseGroups);

		mock.onPost('/api/v1/nine/settings').reply(config => {
			// TODO: fixme or remove me!
			// HACK::use callback to trigger side effects of modifying mock tenant data
			// const selectedLicenseGroupId = config.url && config.url.split('/').filter(Boolean).at(-1); // EDIT::or maybe `config.url.split('/').filter(Boolean).at(-1)` ... they both kinda suck
			// let newRegion = config.data.region;
			console.log('config = ', config);
			// newRegion = licenseGroups;

			return [200];
		});

		mock.onGet(`/api/user/${selectedLicenseGroup.id}/preferences`).reply(200, {
			quickLinks: ['invite-users', 'create-workflow'],
			startPage: 'home',
			navbarBackgroundOpacity: 50,
			appearance: 'light',
			siteBackground: 'tropical-image.jpg',
			pageHeaderBackgroundOpacity: 50,
			tableHeaderColorBackgroundOpacity: 80,
			language: 'en',
			tableHeaderBackground: 'color',
			tableHeaderStandardBackgroundOpacity: 80,
			homePageLayout: 'rows',
			messageRetentionDaysInfo: 7,
			messageRetentionDaysError: 7,
			messageRetentionDaysJob: 7,
			messageRetentionDaysOther: 7
		});

		mock.onGet(`/api/user/${selectedLicenseGroup.id}/app-data`).reply(200, {
			lastAcknowledgedNotificationDate: new Date('10/13/2023 10:00:30 am').getTime()
		});

		// WORKFLOW APIS
		mock.onGet(`${wfxApiUrl}/api/samples`).reply(200, sampleWorkflows);
		mock.onGet(`${wfxApiUrl}/api/wfx`).reply(200, workflows);

		mock.onGet(`${wfxApiUrl}/api/zip?password=false&id=workflow-21`).reply(
			200,
			Buffer.from('arrayBufferOfWorkflowDataFake')
		);

		const logApiUrl = getLogApiUrl('us-east-1');

		mock.onGet(`${logApiUrl}/log/${selectedLicenseGroup.id}/message`).reply(200, logs);

		const workflowLogsUrl = new RegExp(
			`${logApiUrl.replace(
				/[.*+?^${}()|[\]\\]/g,
				'\\$&'
			)}/log/.*/message\\?limit=.*&from=.*&to=.*&type=workflow&id=.*`
		);
		mock.onGet(workflowLogsUrl).reply(config => {
			const { id: workflowId } = qs.parse(new URL(config.url!).search, { ignoreQueryPrefix: true });
			return [
				200,
				{
					Items: [
						{
							DateAdded: 1683578664753,
							SK: '2387330135247-2jcd5RiuzQ8jTKoLEBxgJL',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							MessageTranslated: 'wfxCheckIn',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578640081,
							SK: '2387330159919-4LUvtVdQRXPitBFgcGH3ki',
							Action: 'checkout',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckOut',
							MessageTranslated: 'wfxCheckOut',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578636217,
							SK: '2387330163784-ML9d9Bx3wyaDhSoBLfzoXV',
							Action: 'stop',
							Type: 'workflow',
							Info: {
								status: 'stopped',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							MessageTranslated: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578557703,
							SK: '2387330242297-3zE2sE6yBCYRhFJuUhJgm1',
							Action: 'stop',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'root/STS_April23/May 23/05-08',
								nodeName: 'Google Drive Connector'
							},
							MsgKey: 'fileUploadSuccess',
							MessageTranslated: 'fileUploadSuccess',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578553448,
							SK: '2387330246552-3vp8YJnJb13gUsHAEPhQnh',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'root/STS_April23/May 23/05-08',
								nodeName: 'Google Drive Connector'
							},
							MsgKey: 'fileUploadStart',
							MessageTranslated: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578551601,
							SK: '2387330248400-XaYni11s6PFDVys3PWXm1h',
							Action: 'error',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadFailOnInsufficientStorage',
							MessageTranslated: 'fileUploadFailOnInsufficientStorage',
							LogLevel: 'error'
						},
						{
							DateAdded: 1683578551448,
							SK: '2387330248553-MwsXgc2Q5P42C4ozsbqsH2',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'root/TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadStart',
							MessageTranslated: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578520743,
							SK: '2387330279257-EdGogaB5grLwcn6GbSRFWW',
							Action: 'added',
							Type: 'instance',
							Info: {
								arn: 'c65d03e6-4546-4f33-9a2d-fe09e820c0b5',
								filename: '20230508T164059813690.pdf',
								size: 2725,
								workflowName: 'Issue_78'
							},
							MsgKey: 'fileAdded',
							MessageTranslated: 'fileAdded',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577752000,
							SK: '2387331048000-1ipseq7EpZdjPSbyiAnM7b',
							Action: 'start',
							Type: 'workflow',
							Info: {
								status: 'running',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'running'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							MessageTranslated: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577746991,
							SK: '2387331053009-W3ph2WNWnFYR9K1uUBsK18',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							MessageTranslated: 'wfxCheckIn',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577628303,
							SK: '2387331171697-KzuCwVcrXCuWNJnZSHsk84',
							Action: 'checkout',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckOut',
							MessageTranslated: 'wfxCheckOut',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577618111,
							SK: '2387331181889-1WbUraJiW8WMjTMdnusU3a',
							Action: 'stop',
							Type: 'workflow',
							Info: {
								status: 'stopped',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							MessageTranslated: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576985830,
							SK: '2387331814170-4qoxg2aWxKDtHydcodCBCx',
							Action: 'error',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'Skaur',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadFailOnInsufficientStorage',
							MessageTranslated: 'fileUploadFailOnInsufficientStorage',
							LogLevel: 'error'
						},
						{
							DateAdded: 1683576985662,
							SK: '2387331814338-2djLmAe7Hhd773JA3CKGwJ',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'root/Skaur',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadStart',
							MessageTranslated: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576983794,
							SK: '2387331816206-YCfS6XqKeMzyn9a9Qwu9Hi',
							Action: 'error',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadFailOnInsufficientStorage',
							MessageTranslated: 'fileUploadFailOnInsufficientStorage',
							LogLevel: 'error'
						},
						{
							DateAdded: 1683576983623,
							SK: '2387331816378-84E19kP9FTknZ6x7qWVc4k',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'root/TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadStart',
							MessageTranslated: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576963629,
							SK: '2387331836371-SD1CGNQRDcUVhNsMgCMqes',
							Action: 'added',
							Type: 'instance',
							Info: {
								arn: '1b0e0290-0084-45a8-b210-929a8303210c',
								filename: '20230508T161502928775.pdf',
								size: 2613,
								workflowName: 'Issue_78'
							},
							MsgKey: 'fileAdded',
							MessageTranslated: 'fileAdded',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576915637,
							SK: '2387331884363-8zFerDFpyfUn5a4EdmVvc7',
							Action: 'start',
							Type: 'workflow',
							Info: {
								status: 'running',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'running'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							MessageTranslated: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576911617,
							SK: '2387331888383-Er5iAzCz2zkbPZzTtxnF8Q',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							MessageTranslated: 'wfxCheckIn',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576885401,
							SK: '2387331914599-TpgXowSXt8JioL8CcKocv6',
							Action: 'checkout',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckOut',
							MessageTranslated: 'wfxCheckOut',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576882887,
							SK: '2387331917113-LQst81pesyswvzSiWpwTnD',
							Action: 'stop',
							Type: 'workflow',
							Info: {
								status: 'stopped',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							MessageTranslated: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576870562,
							SK: '2387331929438-SRuusgvFsEcbc7LffXxWrm',
							Action: 'start',
							Type: 'workflow',
							Info: {
								status: 'running',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'running'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							MessageTranslated: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576857909,
							SK: '2387331942092-31o6qMvUs7ZYzWQwje3Uua',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							MessageTranslated: 'wfxCheckIn',
							LogLevel: 'info'
						}
					],
					Count: 23,
					qs: [null]
				}
			];
		});

		mock.onGet('/api/mp/catalog/pricing?region=US').reply(200, {
			region: 'US',
			currencyCode: 'USD',
			pricing: {
				'F34EB719-1E3E-4AA9-B31B-AE691B0AB090': {
					publicId: 'F34EB719-1E3E-4AA9-B31B-AE691B0AB090',
					name: 'Dispatcher ScanTrip Cloud 1 device license, 1 Year',
					showPrice: true,
					productionDuration: 1,
					priceRanges: [
						{
							sapId: 'AEMPA00',
							amount: 241.14,
							min: 1
						},
						{
							sapId: 'AEMPA05',
							amount: 236.32,
							min: 10
						},
						{
							sapId: 'AEMPA0A',
							amount: 231.5,
							min: 25
						},
						{
							sapId: 'AEMPA0G',
							amount: 226.67,
							min: 50
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'04847520-17F3-4582-A1DA-827D7D4D2B52': {
					publicId: '04847520-17F3-4582-A1DA-827D7D4D2B52',
					name: 'Dispatcher ScanTrip Cloud 1 device license, 2 Year',
					showPrice: true,
					productionDuration: 2,
					priceRanges: [
						{
							sapId: 'AEMPA01',
							amount: 458.17,
							min: 1
						},
						{
							sapId: 'AEMPA06',
							amount: 449.01,
							min: 10
						},
						{
							sapId: 'AEMPA0C',
							amount: 439.85,
							min: 25
						},
						{
							sapId: 'AEMPA0H',
							amount: 430.67,
							min: 50
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'2B4EEAA7-9576-43C8-820D-25C09DAC99B3': {
					publicId: '2B4EEAA7-9576-43C8-820D-25C09DAC99B3',
					name: 'Dispatcher ScanTrip Cloud 1 device license, 3 Year',
					showPrice: true,
					productionDuration: 3,
					priceRanges: [
						{
							sapId: 'AEMPA02',
							amount: 651.08,
							min: 1
						},
						{
							sapId: 'AEMPA07',
							amount: 638.06,
							min: 10
						},
						{
							sapId: 'AEMPA0D',
							amount: 625.05,
							min: 25
						},
						{
							sapId: 'AEMPA0J',
							amount: 612.01,
							min: 50
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'3A14C409-D4CB-4C16-A346-B5D732570A17': {
					publicId: '3A14C409-D4CB-4C16-A346-B5D732570A17',
					name: 'Dispatcher ScanTrip Cloud 1 device license, 4 Year',
					showPrice: true,
					productionDuration: 4,
					priceRanges: [
						{
							sapId: 'AEMPA03',
							amount: 819.88,
							min: 1
						},
						{
							sapId: 'AEMPA08',
							amount: 803.49,
							min: 10
						},
						{
							sapId: 'AEMPA0E',
							amount: 787.1,
							min: 25
						},
						{
							sapId: 'AEMPA0K',
							amount: 770.68,
							min: 50
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'A0C7C4C6-FF94-44D1-AD3F-16E5274385F5': {
					publicId: 'A0C7C4C6-FF94-44D1-AD3F-16E5274385F5',
					name: 'Dispatcher ScanTrip Cloud 1 device license, 5 Year',
					showPrice: true,
					productionDuration: 5,
					priceRanges: [
						{
							sapId: 'AEMPA04',
							amount: 964.56,
							min: 1
						},
						{
							sapId: 'AEMPA09',
							amount: 945.28,
							min: 10
						},
						{
							sapId: 'AEMPA0F',
							amount: 926,
							min: 25
						},
						{
							sapId: 'AEMPA0M',
							amount: 906.68,
							min: 50
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'C3F39D09-6091-4302-8C31-C78A81F1ADAE': {
					publicId: 'C3F39D09-6091-4302-8C31-C78A81F1ADAE',
					name: 'Dispatcher ScanTrip Cloud BUS Bundle 1 device license, 1 Year',
					showPrice: true,
					productionDuration: 1,
					priceRanges: [
						{
							sapId: 'AEMPA0N',
							amount: 0.2,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'3348C5FD-7123-4332-ABD0-4D5C644CE3A7': {
					publicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
					name: 'Dispatcher ScanTrip Cloud 1 device license, Monthy Subscription',
					showPrice: true,
					productionDuration: null,
					priceRanges: [
						{
							sapId: 'DPSAAA01',
							amount: 20.1,
							min: 1
						},
						{
							sapId: 'DPSAAA10',
							amount: 19.69,
							min: 10
						},
						{
							sapId: 'DPSAAA25',
							amount: 19.29,
							min: 25
						},
						{
							sapId: 'DPSAAA50',
							amount: 18.89,
							min: 50
						}
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 100000,
						step: 1,
						lock: false
					}
				},
				'1293F292-D870-4578-9ED0-50D151A46820': {
					publicId: '1293F292-D870-4578-9ED0-50D151A46820',
					name: 'Dispatcher ScanTrip Cloud BUS Bundle 1 device license, 2 Year',
					showPrice: true,
					productionDuration: 2,
					priceRanges: [
						{
							sapId: 'AEMPA0P',
							amount: 229.28,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'6852DB6D-DD15-4910-A68F-63CAEE12EC17': {
					publicId: '6852DB6D-DD15-4910-A68F-63CAEE12EC17',
					name: 'Dispatcher ScanTrip Cloud BUS Bundle 1 device license, 3 Year',
					showPrice: true,
					productionDuration: 3,
					priceRanges: [
						{
							sapId: 'AEMPA0R',
							amount: 434.26,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'ED4FA711-9159-4437-9398-1AEC3CA4B196': {
					publicId: 'ED4FA711-9159-4437-9398-1AEC3CA4B196',
					name: 'Dispatcher ScanTrip Cloud BUS Bundle 1 device license, 4 Year',
					showPrice: true,
					productionDuration: 4,
					priceRanges: [
						{
							sapId: 'AEMPA0T',
							amount: 615.11,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'0FA99FF0-16D2-4971-9CD5-157F99F2ED29': {
					publicId: '0FA99FF0-16D2-4971-9CD5-157F99F2ED29',
					name: 'Dispatcher ScanTrip Cloud BUS Bundle 1 device license, 5 Year',
					showPrice: true,
					productionDuration: 5,
					priceRanges: [
						{
							sapId: 'AEMPA0U',
							amount: 771.85,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'CB73A7BE-5C65-41D0-9312-D95DA2301E3B': {
					publicId: 'CB73A7BE-5C65-41D0-9312-D95DA2301E3B',
					name: 'Dispatcher ScanTrip Cloud BUS Bundle Dealer 1 device license, 3 Year',
					showPrice: true,
					productionDuration: 3,
					priceRanges: [
						{
							sapId: 'AEMPA0V',
							amount: 300,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'539FF6A8-86DF-4B3B-B9C3-556637F6DB85': {
					publicId: '539FF6A8-86DF-4B3B-B9C3-556637F6DB85',
					name: 'Dispatcher Stratus 1 device license, 1 Year',
					showPrice: true,
					productionDuration: 1,
					priceRanges: [
						{
							sapId: 'STRATUSTERM1MIN1',
							amount: 432,
							min: 1
						},
						{
							sapId: 'STRATUSTERM1MIN10',
							amount: 410.4,
							min: 10
						},
						{
							sapId: 'STRATUSTERM1MIN50',
							amount: 389.88,
							min: 50
						},
						{
							sapId: 'STRATUSTERM1MIN200',
							amount: 370.39,
							min: 200
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'53FDDC16-E37E-4712-BE6D-34E4D08977D2': {
					publicId: '53FDDC16-E37E-4712-BE6D-34E4D08977D2',
					name: 'Dispatcher Stratus 1 device license, 2 Year',
					showPrice: true,
					productionDuration: 2,
					priceRanges: [
						{
							sapId: 'STRATUSTERM2MIN1',
							amount: 820.8,
							min: 1
						},
						{
							sapId: 'STRATUSTERM2MIN10',
							amount: 779.76,
							min: 10
						},
						{
							sapId: 'STRATUSTERM2MIN50',
							amount: 740.77,
							min: 50
						},
						{
							sapId: 'STRATUSTERM2MIN200',
							amount: 703.73,
							min: 200
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'C1512F4C-42F1-4F7E-A477-D635C2B5AFC5': {
					publicId: 'C1512F4C-42F1-4F7E-A477-D635C2B5AFC5',
					name: 'Dispatcher Stratus 1 device license, 3 Year',
					showPrice: true,
					productionDuration: 3,
					priceRanges: [
						{
							sapId: 'STRATUSTERM3MIN1',
							amount: 1166.4,
							min: 1
						},
						{
							sapId: 'STRATUSTERM3MIN10',
							amount: 1108.08,
							min: 10
						},
						{
							sapId: 'STRATUSTERM3MIN50',
							amount: 1052.68,
							min: 50
						},
						{
							sapId: 'STRATUSTERM3MIN200',
							amount: 1000.04,
							min: 200
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'EE08CEBF-2AAC-44E0-A899-7489547BEF8F': {
					publicId: 'EE08CEBF-2AAC-44E0-A899-7489547BEF8F',
					name: 'Dispatcher Stratus 1 device license, 4 Year',
					showPrice: true,
					productionDuration: 4,
					priceRanges: [
						{
							sapId: 'STRATUSTERM4MIN1',
							amount: 1468.8,
							min: 1
						},
						{
							sapId: 'STRATUSTERM4MIN10',
							amount: 1395.36,
							min: 10
						},
						{
							sapId: 'STRATUSTERM4MIN50',
							amount: 1325.59,
							min: 50
						},
						{
							sapId: 'STRATUSTERM4MIN200',
							amount: 1259.31,
							min: 200
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'86EE6148-DBC6-41E7-9D75-2200A349511C': {
					publicId: '86EE6148-DBC6-41E7-9D75-2200A349511C',
					name: 'Dispatcher Stratus 1 device license, 5 Year',
					showPrice: true,
					productionDuration: 5,
					priceRanges: [
						{
							sapId: 'STRATUSTERM5MIN1',
							amount: 1728,
							min: 1
						},
						{
							sapId: 'STRATUSTERM5MIN10',
							amount: 1641.6,
							min: 10
						},
						{
							sapId: 'STRATUSTERM5MIN50',
							amount: 1559.52,
							min: 50
						},
						{
							sapId: 'STRATUSTERM5MIN200',
							amount: 1481.54,
							min: 200
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'6D0F7639-1251-4032-8254-B0DA4872CE93': {
					publicId: '6D0F7639-1251-4032-8254-B0DA4872CE93',
					name: 'Dispatcher Stratus 1 people license, 1 Year',
					showPrice: true,
					productionDuration: 1,
					priceRanges: [
						{
							sapId: 'STRATUSPEOPLETERM1',
							amount: 432,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'5BAEFAE4-9469-449F-9043-28992F137480': {
					publicId: '5BAEFAE4-9469-449F-9043-28992F137480',
					name: 'Dispatcher Stratus 1 people license, 2 Year',
					showPrice: true,
					productionDuration: 2,
					priceRanges: [
						{
							sapId: 'STRATUSPEOPLETERM2',
							amount: 820.8,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'E3FC89C6-E56A-4DE9-B2DF-D13CDF38EB61': {
					publicId: 'E3FC89C6-E56A-4DE9-B2DF-D13CDF38EB61',
					name: 'Dispatcher Stratus 1 people license, 3 Year',
					showPrice: true,
					productionDuration: 3,
					priceRanges: [
						{
							sapId: 'STRATUSPEOPLETERM3',
							amount: 1166.4,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'A974ABCF-2D72-4DC7-A1DB-3D839335BA73': {
					publicId: 'A974ABCF-2D72-4DC7-A1DB-3D839335BA73',
					name: 'Dispatcher Stratus 1 people license, 4 Year',
					showPrice: true,
					productionDuration: 4,
					priceRanges: [
						{
							sapId: 'STRATUSPEOPLETERM4',
							amount: 1468.8,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'309201E3-6482-4BC0-BDBB-37DD3CF4F488': {
					publicId: '309201E3-6482-4BC0-BDBB-37DD3CF4F488',
					name: 'Dispatcher Stratus 1 people license, 5 Year',
					showPrice: true,
					productionDuration: 5,
					priceRanges: [
						{
							sapId: 'STRATUSPEOPLETERM5',
							amount: 1728,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 10000,
						step: 1,
						lock: false
					}
				},
				'52AC9157-8FBF-4A2E-8950-4A1D9CE560C1': {
					publicId: '52AC9157-8FBF-4A2E-8950-4A1D9CE560C1',
					name: 'Dispatcher Stratus Service - Test Plan',
					showPrice: true,
					productionDuration: null,
					priceRanges: [
						{
							sapId: 'DPSAAA02',
							amount: 2,
							min: 1
						}
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 1,
						max: 100,
						step: 1,
						lock: false
					}
				},
				'1E92B38B-EEA4-4B88-9DAB-CBDE4A9D472B': {
					publicId: '1E92B38B-EEA4-4B88-9DAB-CBDE4A9D472B',
					name: 'Dispatcher ScanTrip Cloud 1 device license, Monthy Subscription(1-time)',
					showPrice: true,
					productionDuration: null,
					priceRanges: [
						{
							sapId: 'DPSAAA03',
							amount: 0,
							min: 1
						}
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					}
				}
			}
		});

		const metadataApiUrl = getMetadataUrl(user.awsRegion);
		mock.onPut(`${metadataApiUrl}/api/v1/user/${user.id}`).reply(200);

		// return a custom array on a get to metadata api, like: `GET 	https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/drop/all/b84813c780154de0b3a7070c25458637`
		// mock.onGet(new RegExp(`${metadataApiUrl}/api/v1/drop/all/.*`)).reply(200, {
		// 	files: [
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/06c25fc2-c633-4f88-9be1-adb2673fde40?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=d25bb49bfddf404744a0624ec36068e17d1f26ed814f1db826fa4c7b5f91f451&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/2f84bde8-3248-4e26-8ce1-773ecef06eb8?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=4c7192619982f2006a5a3c6b7330062421ebb28db4bb16315b2d420707c9af4f&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/470bb307-635e-439e-8816-c57dcff89259?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=955f3febcf7e99ef2ae03f3d17867df6abc490eeacc692d7975f31088ded8725&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/509ca932-fc0d-4a0a-9ec8-05027c347539?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=591b72fc86b463e00cee4dfda889f07fde633f1f4f6690a4bda0abe8cbc941fe&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/51dbf494-0049-478f-ab17-71286baee5ba?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=e6c70674d4984d4720231c10f9b5f492eea3e81f1d44a52e4ac8e56a35af4d02&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/b1db055a-4f5c-4b05-abbe-dcbecc8bf1bf?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=ca663a8fcd1c4578c2b1946ae1cf54f1bedb528a4b9b59339057bf4cdf30499e&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/b5643f4a-b392-4a15-ad87-62e6921694eb?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=4eb2f637eeaba2465e0199f193bfa7bec295d414cd7b11be2006f08aa58220e1&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/cdb88d53-c26a-4245-be37-093992f54103?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEY7SQWGP%2F20240202%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240202T190414Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=5a1f105e9a43a4837c041d00cac0999870eef3ff21b34b7709b467c834948616&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDeLMqdSsZj%2FAh523K8N%2BMKqcnNOIEMJK4UUCu9kKUPZAiEA61FyK3Xp%2FTIrDtnrURkwI%2FxAiaSQn68I54vGgkD4uykqiwQIZBAEGgwwODIwMjY1NzYwNjYiDGKR3FmZJmx85OOmcCroAxfQUvkdvuQvAaWDYAmdKFhOyXMaCYAj5DfrroWM1tVqila1fNP7%2BqPJ%2BGNIUoGyPoVyvIE%2BVkhYYNK1lVi%2Fv0dFHcbiBUAEWI%2Bu7HqF5fMoJbsoMaAZ7%2F8tliTCsAtqwXg8TcsILiHbc2aonQvpo8n8PZOxVHGEo38DETFtcrOpQGBkLdxcEoGtFeUsohojIuBslSh5Be345L5xoUqhU%2BnlSqjrOozt34MlXewjkc%2FcbT4NpFnGKaE1EXldrp6V4s19nqqLd0eeuMf0OIBkIl0WUBLD8E3KaR%2FWrBTwpuLY5EqGHPsS5duVyd6v%2BaGbZOcXfcnkgFMEytFcFJH62j5oMPvjvV8tKZvYVzwivvvlbhmtHhq%2FOHZLGa1Cx8blfaumgSpayzaNgQtzqLx7R9%2Fk2X1p%2BxEzXH1tWlrMnoAwwm7OsSveYD5LSo95X6%2BjPl93bh9FdLNsh4wXClzW8%2FZEM94WBQQ8eVGfUNvLor1pIfsKsDpt36Kbav1BFTahOjEZfT65urcGFkF2rtxtKEjo0qoA7ypnpYXkK8stKr%2FSl7WJ4S0R1tjw48VRq5LOuueWOqrtjdsmeMG5B1kYqgzQ%2FCbkNJL7Xcyjgu8oVWfmJ6ILgecgSLHFFDEB%2BO0R0alAA56siATCMMz39K0GOqUBhbpUc71gEmAOpQX1XtVZL%2FLdKZBsxoCtjRAkhHA4IRM84CB3WamptqToi62Mf8ZYInKfBPxVSzYCI3Yzz7s%2Bn%2F2ZYJLU6uV2EDvm5XmEh4bvcsiOUIo8qdTtLOFMZg8t%2FUypusoWeCSvN8xUstMm4MzeIRSrd6F1nvS2UDedjwP1yscwrt7cbDEmJnOBcmW1oHRSU5puTMF2701mCeJMQxzzsKBl',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			}
		// 		}
		// 	]
		// });

		// const mockSelectedJob = {
		// 	id: '526745a9-0f55-4bf7-a9c4-5b0535efbae8',
		// 	active: true,
		// 	name: '470bb307-635e-439e-8816-c57dcff89259|2024-02-01|18:36',
		// 	owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 	queue: '',
		// 	workflow: {
		// 		id: '2f5201ae94614c51b69b4eea1ebb939a',
		// 		name: 'DBI1'
		// 	},
		// 	accepted: true,
		// 	status: 'action',
		// 	token:
		// 		'AQCAAAAAKgAAAAMAAAAAAAAAASuRkJIq7S/DZ+2TxaTXxDv7Dj/GUUO4SYw7ceq6yGpMOCvcvs61n1EV+nSQ/KYcaW2Bqi4eWJTIY+iRTTQwcTszCB544QHrso+WiO5ka9E=V76uuuH2kARAPJqD1VGthqtYW5IEtVWRj9bmIckyoWxTMIJ2uvje1QVa16lFEPZ/TNj5xNOZ8Z7b8+B4ypwdW4I8hiUCUm3Po2Gdim+hEcn/LxQ7IkTxH+Dc36Nqs2/rR0UllOB6rQYrjVTAYaE5NdpHpBWJq8zqipRx9bMT7cFne891Kq8CNqF9sRagynMG0M4P/NHvmH2342OdDKt0ckK/pv+Gt9eeJ/tlE9Z64Qwa2sd7o5xx8U+za7BbeyDXP4n1dJgMlJaCgKBY+Acj6dl/RTk3WgkJVve32lDPpUFI3gIIfoI0g8w07VeifyJzlFBW2FU1Ae804JFFhqZtGWypEjPEAmbkfR4BhiLgaLWBz8VuVoOGTM4ZBFJ2qR97Avr1CoqujPxafoR4o/iYbkvJFXPGZ1QiN4bUbW1tpNsYaF/tk1SOgtzqw5P7q0Sokm3It/oqP81I0l0Gu2zl1txnUDyEf3t85R+sSJdzZ5+ANRyjsrZp8H0c8m3JaGq/bouxV8hq2tuvL+TPGOsy',
		// 	nodeID: 'c1dc0cea85254d18b593bdf75693c37b',
		// 	nodeNamespace: 'PeopleUser',
		// 	returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 	createdAt: 1706812570955,
		// 	updatedAt: 1706812595574,
		// 	nodeConfig: {
		// 		allowReassignment: false,
		// 		sendEmailNotification: false,
		// 		user: {
		// 			id: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			firstName: 'Pat',
		// 			lastName: 'White',
		// 			groupList: [
		// 				'00000000-1111-1111-1111-000000000000',
		// 				'00000000-2222-2222-2222-000000000000',
		// 				'60cb476d-a849-4879-81a6-d65c5a5dbecd',
		// 				'76c6c636-d773-4046-8ed8-d452aa3a9e5e'
		// 			]
		// 		},
		// 		groups: [],
		// 		nextStep: true,
		// 		workflowEngine: {
		// 			nextStepName: 'Pat White'
		// 		}
		// 	},
		// 	history: [
		// 		{
		// 			id: '1b1d345f-9943-401d-89bb-a94440009adc',
		// 			event: {
		// 				type: 'accept'
		// 			},
		// 			source: {
		// 				type: 'user',
		// 				id: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 			},
		// 			createdAt: 1706812595959
		// 		},
		// 		{
		// 			id: 'aaca76a8-cb38-42c1-ba52-d3227b51b39c',
		// 			event: {
		// 				type: 'received'
		// 			},
		// 			source: {
		// 				type: 'user',
		// 				id: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 			},
		// 			createdAt: 1706812571799
		// 		},
		// 		{
		// 			id: 'd5a70cd8-f0ad-4183-a0a4-42d3913ed7a3',
		// 			event: {
		// 				type: 'created'
		// 			},
		// 			source: {
		// 				type: 'system',
		// 				id: 'system'
		// 			},
		// 			createdAt: 1706812571694
		// 		}
		// 	],
		// 	notes: [],
		// 	attachments: [
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/470bb307-635e-439e-8816-c57dcff89259?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183559Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=402626712550e827de601d77b95c741eaedb83ae8927050e31da04f78d3343a5&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.jpg',
		// 				name: 'anastasia',
		// 				fullname: 'anastasia.jpg',
		// 				size: 437034,
		// 				dropboxin: {
		// 					ext: '.jpg',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/anastasia.jpg',
		// 					name: 'anastasia',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAABYg',
		// 					fullname: 'anastasia.jpg',
		// 					filesize: 437034
		// 				},
		// 				uuid: '470bb307-635e-439e-8816-c57dcff89259',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/51dbf494-0049-478f-ab17-71286baee5ba?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183601Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=be284ad7b60c7f4adfe47b0ecf509af7731372a9ec98b72b5f09bed2c5ee840b&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.png',
		// 				name: 'color_fullcolor',
		// 				fullname: 'color_fullcolor.png',
		// 				size: 632,
		// 				dropboxin: {
		// 					ext: '.png',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/color_fullcolor.png',
		// 					name: 'color_fullcolor',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAACag',
		// 					fullname: 'color_fullcolor.png',
		// 					filesize: 632
		// 				},
		// 				uuid: '51dbf494-0049-478f-ab17-71286baee5ba',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/b5643f4a-b392-4a15-ad87-62e6921694eb?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183602Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=f94c5d5baac70ded703512edde43ea35526a7ecb7e221a011d29e4748220c6de&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.png',
		// 				name: 'conn-logo-dropbox',
		// 				fullname: 'conn-logo-dropbox.png',
		// 				size: 3729,
		// 				dropboxin: {
		// 					ext: '.png',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/conn-logo-dropbox.png',
		// 					name: 'conn-logo-dropbox',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAACaQ',
		// 					fullname: 'conn-logo-dropbox.png',
		// 					filesize: 3729
		// 				},
		// 				uuid: 'b5643f4a-b392-4a15-ad87-62e6921694eb',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/06c25fc2-c633-4f88-9be1-adb2673fde40?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183603Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=4f38898e49522956cdbdd14824e15f8fb5736e6d008c651e206237fc7455e7d0&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.png',
		// 				name: 'conn-logo-googledrive',
		// 				fullname: 'conn-logo-googledrive.png',
		// 				size: 4767,
		// 				dropboxin: {
		// 					ext: '.png',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/conn-logo-googledrive.png',
		// 					name: 'conn-logo-googledrive',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAACaw',
		// 					fullname: 'conn-logo-googledrive.png',
		// 					filesize: 4767
		// 				},
		// 				uuid: '06c25fc2-c633-4f88-9be1-adb2673fde40',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/509ca932-fc0d-4a0a-9ec8-05027c347539?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183603Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=27dcd8267531c1204d08cc3d8ede7ca46ea7800fac8aec24906ee2956e9fb035&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.png',
		// 				name: 'download',
		// 				fullname: 'download.png',
		// 				size: 73923,
		// 				dropboxin: {
		// 					ext: '.png',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/download.png',
		// 					name: 'download',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAABKQ',
		// 					fullname: 'download.png',
		// 					filesize: 73923
		// 				},
		// 				uuid: '509ca932-fc0d-4a0a-9ec8-05027c347539',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/2f84bde8-3248-4e26-8ce1-773ecef06eb8?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183605Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=a46d937e320fb817854bd9a6f0e0e891d3e267a2b60003652a3d39bef3ae6c3f&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.jpg',
		// 				name: 'PXL_20231014_154640785',
		// 				fullname: 'PXL_20231014_154640785.jpg',
		// 				size: 1138738,
		// 				dropboxin: {
		// 					ext: '.jpg',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/PXL_20231014_154640785.jpg',
		// 					name: 'PXL_20231014_154640785',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAABYw',
		// 					fullname: 'PXL_20231014_154640785.jpg',
		// 					filesize: 1138738
		// 				},
		// 				uuid: '2f84bde8-3248-4e26-8ce1-773ecef06eb8',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/b1db055a-4f5c-4b05-abbe-dcbecc8bf1bf?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183606Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=d2bfddfa4fa5970c3a1d8f3fccf742ad5afaaaadff47df9c71247ac7ff1b1612&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.jpg',
		// 				name: 'настя',
		// 				fullname: 'настя.jpg',
		// 				size: 1184579,
		// 				dropboxin: {
		// 					ext: '.jpg',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/настя.jpg',
		// 					name: 'настя',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAACXA',
		// 					fullname: 'настя.jpg',
		// 					filesize: 1184579
		// 				},
		// 				uuid: 'b1db055a-4f5c-4b05-abbe-dcbecc8bf1bf',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		},
		// 		{
		// 			url:
		// 				'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/cdb88d53-c26a-4245-be37-093992f54103?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183607Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=38062127a997527a55180ddd054116e59fdb4bb860ad9a6d74786499e4a2d55f&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 			headers: {
		// 				'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 				'x-amz-server-side-encryption-customer-key': 'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 				'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 			},
		// 			metadata: {
		// 				ext: '.jpg',
		// 				name: 'настя1',
		// 				fullname: 'настя1.jpg',
		// 				size: 1116084,
		// 				dropboxin: {
		// 					ext: '.jpg',
		// 					date: '2024-02-01',
		// 					folder: '/2',
		// 					fullpath: '/2/настя1.jpg',
		// 					name: 'настя1',
		// 					count: 1,
		// 					id: 'id:tjzgzDIHGKsAAAAAAAACXQ',
		// 					fullname: 'настя1.jpg',
		// 					filesize: 1116084
		// 				},
		// 				uuid: 'cdb88d53-c26a-4245-be37-093992f54103',
		// 				date: 1706812569499,
		// 				uploadedBy: 'system'
		// 			}
		// 		}
		// 	],
		// 	nextSteps: [
		// 		{
		// 			node: {
		// 				id: '8e042dc9f3094c6c95c32809ee9d20ad',
		// 				name: 'Dropbox Connector',
		// 				namespace: 'Dropbox',
		// 				typename: 'DropboxNode.Models.Dropbox,DropboxNode',
		// 				version: '1',
		// 				enabled: true,
		// 				config: {
		// 					account:
		// 						'dbid:AACG-z9K6jW5hpEYmQb8sbRX1-NMLM93v1w-konicaminoltamarketplace_4b7314fc12378d7e138b3b3126a87b339643b271',
		// 					site: '',
		// 					timeout: 100,
		// 					url: 'id:tjzgzDIHGKsAAAAAAAAAeA',
		// 					customUrl: 'root/3',
		// 					checkInMessage: '',
		// 					overwrite: false,
		// 					keywords: {},
		// 					showFiles: false,
		// 					mode: 'GRID',
		// 					expanded: ['/', 'id:tjzgzDIHGKsAAAAAAAAAeA'],
		// 					breadcrumb: [
		// 						{
		// 							id: '/',
		// 							name: 'root',
		// 							type: 'folder',
		// 							childCount: 1,
		// 							children: []
		// 						},
		// 						{
		// 							id: 'id:tjzgzDIHGKsAAAAAAAAAeA',
		// 							name: '3',
		// 							path: '/3',
		// 							type: 'folder',
		// 							nextToken: '',
		// 							childCount: 1,
		// 							children: []
		// 						}
		// 					]
		// 				},
		// 				status: {
		// 					isValid: true,
		// 					errors: {
		// 						'en-us': []
		// 					}
		// 				},
		// 				options: {}
		// 			},
		// 			name: 'Dropbox Connector',
		// 			transition: 'Normal'
		// 		}
		// 	],
		// 	drop: {
		// 		password: 'sUb9RwBE8xfcHdpQ6ydHit5Ae6Fw4sfl',
		// 		id: 'b84813c780154de0b3a7070c25458637',
		// 		username: '2WL8fCAEL3TZVTIK'
		// 	}
		// };

		// const mockJobs = {
		// 	jobs: [
		// 		{
		// 			id: '02a93c13-f3cf-4b09-917a-6b0fcac96b12',
		// 			active: true,
		// 			name: '20240129T120209080061.pdf|2024-01-29|17:04',
		// 			owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			queue: '',
		// 			workflow: {
		// 				id: '9bbd9aa6fc834ee9912064a28679b72e',
		// 				name: 'Pat user node wf'
		// 			},
		// 			accepted: true,
		// 			status: 'action',
		// 			token:
		// 				'AQCAAAAAKgAAAAMAAAAAAAAAAbuv7X+Cr2MnSAy+0xWRTBYav+MIyj1jAAv/rqFN6KnlfaR4qiGfqoQx0k1UpDf7pTaawPSmXsB9m2EkUWDG8Ac/7kPY6UbbuvqlUumfXTc=eiSFoKBXhSNxWgjx++mDVO/HUv4rKHRpRP1gmAfVs02/w8qhVCFT8bMFWD/Q8WPUSQw/+x5UCdaGHDjI02IEm+OESaqcVHD9XWC5ZsxcHm02bIcLppQ8ua5xthtFy8khEFIeD5EImGVR5Yh9xwshGF4DzSmKHFp3Ep9pzRE7iF4+je3XA26kfmAOSz8d/3U2iqII0JJ3L8NNV/cuCpKq/nnzjSiLKmv4ZWTUN1btYve5NoFRikd2Fcd/WX/GupurT02mgMUwLeddHPQenfvsDR38LDwClkphTpkvqX6ZzD31UvCtivkwNdgvOAJbHljZtR7oisribvrjoOLiYXeANZbW/S8Gag8XmjIsCiRWdS8r3bw4et6lwS+XJGkGkFskjAzfAXN/cw5a9U/+gIsRCe8NnfMX7Q6/wd9eqRY2xKy0CeEmoKGnsEFMPy0l/vvX81/DC88t99a4uv3zfmpvVpS23Dc5QgHjJLlURg3Z89ZvCjtAzj4q5WRBbElcADMRZR1HHgvz2vbX97xfkVOt',
		// 			nodeID: '8fed5478b92948c9855098140ee3d226',
		// 			nodeNamespace: 'PeopleUser',
		// 			returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 			createdAt: 1706547860478,
		// 			updatedAt: 1706548755528,
		// 			history: [],
		// 			notes: [],
		// 			attachments: [
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0be2148a2a2248a6ab271e45abe7862f/20240129T120209080061.pdf?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBNA2JCC7M%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T170413Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=299590947b72ea9364d969ba75200758158500123b1f7f26eb574033974c1ac9&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDkaCXVzLWVhc3QtMSJIMEYCIQCDx0brWCocVX4EBhg3ZuE6yf3K9jMkGX%2BsoQ%2BymbL5NAIhAIMykgty1LfuzwwrR4uWqYCWAvZPOi8xMbFpImKDME5OKpQECPL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQBBoMMDgyMDI2NTc2MDY2IgzjDgPpQ1xcmaPH0I8q6ANhVJmcnnh0UXee5VAyPHsJO3%2BRbt0sjf8nZtlo241dKYdJOjNuBpa3nCjYXWt%2FI7d0sKcp6AgenbGabWso48ZCDCdnKYDV2PkjySthF3oGctGWMb%2BhGfoqi%2F8KXcepCJqNXTlW7CnvQaVpCRLjjcnJBCwiOivfz0ZZLbzMX8O2CycmiPTpix%2BQSh%2BML%2FGGoPNiYpxvnhsNtdx8d0uek0Mfo5uJHK5rIA5b8osn6oLs1mMEEAGlIbOaGwWygfk73YQikkxmFRqswAL%2Bk6OwwbG%2B75dM4C%2Bl%2Fx4SIWybS9Sbr9WWYYCZYmSistecHVryEVrP7xGWh4cvYKJxwZCxUIE0PcXrD1DlBmzv1xiqwxZapAe9pBMqQaNgW5VSGA6BUY%2Bo89d9u9WRJbp%2BaPdBxTb55WMx%2F3GVfRO%2FJHq32mv4XWoM8lvgQZFcvfa0Q%2BdWUfxtH%2FjdqAt1cz5qmY0R%2Fpx%2FeCEeBvt6KNddvMObZOUFF2cTPrgNWRNfbCrHG2jlezX88Twow9DU0H3ye1cbYRcvIj531fzOwiFKDSzp%2BBdYn9Lfxfq0jCpl4dhyMxxQvXrx2JvpPl%2BwVKs5X%2FsaUDNkoP15rfRuYjqeSW5O%2Bu%2B6YMDjiEDA6Yie6Lu7zx%2BS%2BAbcR%2F8JMfm5zTC2st%2BtBjqkAch%2BuZuooeWIo11YLuwAwsMoh6sWRf0Yw%2F7C2J7RYLRuOzbwCDdgFPhxYQTpE10RbhJIb15Z%2F%2BPqD9bBkI19zBnJqlNO8xCwqOtKTGLgVZrdwVKgYYfKGJS0ckU10CNU5edIKT7uPdxL92BYjAA7bKc1vLW5YGWS%2FauPGwoqNb%2FuKw12PYyZxieC%2Bv6SEQ0hb9mF54cC5%2Ff0cRymCNnn%2BRQqv9dh',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'Q0J4djY3QWJma2l6RWdzZFdsRkd2S2w1dlpBdlVSek0=',
		// 						'x-amz-server-side-encryption-customer-key-md5': '9W1mNAo3Yie4WeOX0fRIMg==',
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256'
		// 					},
		// 					metadata: {
		// 						date: 1706547860477,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0be2148a2a2248a6ab271e45abe7862f/toastman.jpg?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBJ274LVXB%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T171914Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=1a5a14d1ebba1e511c9eaf26c6edde9065589f6eb569e86a2e3f25bc36b09759&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDoaCXVzLWVhc3QtMSJHMEUCIQD%2FNDLgLIcy7HXXZ37crlzb8Kg5%2FU%2FKVmCUEhTeEpXHtwIgDXTX7EKq5xEWbWOr4YHIVfjkLecJ92rN2fAvWdyhJYAqlAQI8v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwwODIwMjY1NzYwNjYiDNy%2FU2pneiXWwbVJWCroA0cA%2BJ%2Bm%2BFBJygQGJeVnSinaOHkHOXiVuWYQb3brBn0%2Fz05NDYWrkkp21Ux6ENEPU7q2lHjCnnkpZx3sNFg%2FxnpnzHqtKjO%2BTSqamqwWrqOBBmOGCWa7lWGNk5JGa31x1iXV8UELxTqHsfslu0x5FWgrHpeTfPz2axmQeTVfOE6k4zWhHl5hQm9OXBSSChPBPmUF%2B8op1xeLELr44VCrWHwq6%2FQ7bXn%2FWkEL%2FCaR0gnoUVbimsqH7R5YacW2ONJPcKkkcG9lfYUfyQF6NGPabWyWz7UZwUt3YDROh7wQnhndmpG7%2FE4mzg2wrRqzlS5%2BjGpe6AHzUfXf%2FD3R54SiiCXBFEKY2jObWfWVoGG85SU5ZGkuba7WLFx54mkZA9Fc7W9zRX2TkNKyyveehCSo738WaZYfWXxSYTPzODhBU1fuOUwCULYAxG6K2LKXkQNrxrrvp3TAFSZ3HPyGD522IB%2FQBVXtbdZxpG7nVe%2Fgsec2jxLXQ6elUB%2BGbMQ6Ul%2FdqAQBIU0pi8wh5gzBEkNSv2O4%2BE2qNXSCc2UNABMJlIQvj9JVd0bM%2BO0Q%2BbZS9gSWut3PEV1IIE5pxeDOSyMTrHc%2F6uoPl1L3O5G7SV63AkHoqHC6Z3rxAoyDnv1nq9DTW9gzhN85I1DTMJq5360GOqUBvBaNodrnZVgjiktYEdhYMK800zVpmc%2Fe2ZedIsPc6R4ijn6p6urHoM%2BFm0mkg1fZmol%2BNYsojQR4DnAmfvKB4ceI4fxRx26kxgMkefmwh5wT39onEULkqZbQ9FOPBqGCYgMw9chrNYfOHaqWcELcbFnUP%2FgJKlG%2B0ra2rATCVO21%2BXU0gS%2BPVfVN1RHATvcgYx1WNOT%2Fzvu21AOOw7ny9miUfhSB',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'Q0J4djY3QWJma2l6RWdzZFdsRkd2S2w1dlpBdlVSek0=',
		// 						'x-amz-server-side-encryption-customer-key-md5': '9W1mNAo3Yie4WeOX0fRIMg=='
		// 					},
		// 					metadata: {
		// 						date: 1706548755096,
		// 						uploadedBy: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 					}
		// 				}
		// 			],
		// 			drop: {
		// 				password: 'CBxv67AbfkizEgsdWlFGvKl5vZAvURzM',
		// 				username: 'MVFTNeXSz1k0wPhK',
		// 				id: '0be2148a2a2248a6ab271e45abe7862f'
		// 			}
		// 		},
		// 		{
		// 			id: '073e94a0-9d33-4282-948e-c1fa67222dc3',
		// 			active: true,
		// 			name: '20240129T182733991067.pdf|2024-01-29|23:29',
		// 			owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			queue: '',
		// 			workflow: {
		// 				id: '9bbd9aa6fc834ee9912064a28679b72e',
		// 				name: 'Pat user node wf'
		// 			},
		// 			accepted: true,
		// 			status: 'action',
		// 			token:
		// 				'AQCAAAAAKgAAAAMAAAAAAAAAAdxnmS6v5KKzSt4K6wudlGLZ4Tt1qzOl0pVsTpcSiUpREC9AlRzG5GfMfhD+1XDeDtMVJNeY9KwvF820S3PRkr93ySljnJXwnDNLAkfEHFg=7O7IFVQsbOQSUg6tKQCqAJoddCY4Bk9wn3KYPS8rfZGMDsvN0jYqXLrCOF28/Ks7Cq0mDWA6W3bFYs296dePjDeijaMue/PMaH3P/3QpHvRpnMXXM5dMM/Sedltn66+p5k7JsRvhyzrH0tAKZ2mDW3e0HVQEXV5PvSA2nx8cnomorEdSKHQJN0H2k/oIu3LzEqio2m4BNLkqlIQALNfiO/CkUDnpTd2WqVGrFRPkqRgbGIKdr3Snbpr5Ho8mLSNcJbwcNGepqy+sI7YtyiFKIcrjh1kuvxtsSUyJWO5CPAyIFhzIZEA890XN5Wa58mTD8dSaZCa2FSUhCC05gH/Y4csAKjVuYr5e8IRoMZ1aa3LtSLZXlI0no9O8ppKGvaSfof8ZvnTPH9jtYz2gekZ6tooVw1NxkI0EM8kEMgeIAjRRqc21CEpT6JPU5DSbVmHIC5htFkRKQT60YlY9YR7XGFUztM+X4KugfyHGlKHWDmn1oiU31pFZ7rUbKiZkyzVocSMOvsdDcS3NgYCW6tHi',
		// 			nodeID: '8fed5478b92948c9855098140ee3d226',
		// 			nodeNamespace: 'PeopleUser',
		// 			returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 			createdAt: 1706570992975,
		// 			updatedAt: 1706573373430,
		// 			history: [],
		// 			notes: [],
		// 			attachments: [
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/9d82e869f5e0467bb94fbc06d5e3be7d/20240129T182733991067.pdf?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBNLFGIBPF%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T232938Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=b91a46ccfe2823b6766db9d7d400687945cc649cf58fd966736f0b51a70188d6&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEAaCXVzLWVhc3QtMSJGMEQCIDP0nDAULK6X7rL7AJMqiFpSgF51%2BpPJwS4hpo3DX7JvAiBpLi7frdk2OoLSB%2BBN%2FQL3RIWFfubFqPx9BHmAV%2BALJSqUBAj4%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAQaDDA4MjAyNjU3NjA2NiIMCBtFa8icwtsHrwujKugDyvUAzmvy%2BQkhq8bXvgSg6z3z9BmaWrngT7kqbeIYRkrPKGjfS52g2zVP3jYI42kOU%2F3M9BzjJkGVOVb%2FJAms3hhyxN45cI%2BDwOPvQ3efMWYYXuITWylp4W0xshm2j5wbPAl3GJjgUJxZPuNBTpsZ5V42BBCDyWKEEIrUQu%2FrMQDL209RBcBHu7%2BVfs6liNfrpZot7lMGp59dN2iaAgt4hJSpCD7q8gAfLKP%2BWKh4qU2SBKDB1Ky2NAuKAjbOj5nf6kgs1lUVL1Ad511ssEtucePfbJVWusUNQamUxWmK7vL6H09XR%2BhVWyusxo3y6TZwv7jjFwLT9pss2oZW2rZu%2F44kVkfkkbmzczb6n8SzvRwr8QozOrI7lO7%2F7TxBQCmFhVrRGfXRBs35Wh6ByxvL4NEyoM9Gk7zrF%2F8AKyc6Ftmsc8i6IcplMAknKzB%2B1HqGMbaW81R4nABebtP2ZRGEVFI%2FiDyZlg0Aw4S8RehtSE2U3hP7nBowspocHgE7H%2BqpKfCuRn1%2B5hqj3%2F1NG0QWaHO2wRKZgiFMFoEBy9jHvL%2BL0tmMazCgU62IZq8PWRHNwrznBEURjodMYKznYYkX5gemAz3EyToykmsMOPs7ocIAPA7UA9ndiEYtBhfcHY8WS0CibAXtVWUwruHgrQY6pgHKAMVsBrfNssMU0bogFkmsWfpC9Om6M23gNeXmBy1Co5cIzllzdjQdXs%2FBNNZVo7XERDMuLh8%2FsvpKDpN3fZJ0ryrHw45RSsQNkr7bBwO2F9kJyUjyGUHpoCwHzomeVRWWXwG05xSIcXBqG0TxlWogx3uvC6xgN8fyGO92Okl69tN93WXYcjxbCxgvq1d6yftP7cd%2FqrW6RJV9bTdF1B4ivp1%2FP8%2Fv',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-key-md5': 'DN1CdN4H+czX7yMoD3gaWA==',
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'U3kwdU5MZU1YVDA3UUJXcVpMRGowNHcxTnc0Z0tXQUg='
		// 					},
		// 					metadata: {
		// 						date: 1706570992611,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/9d82e869f5e0467bb94fbc06d5e3be7d/couch.svg?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBFPTK6QPC%2F20240130%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240130T000927Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=d011c90de5ce72e5e46fbc3511e00b2676030d30c40e489d9ebf11a762be7572&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEAaCXVzLWVhc3QtMSJHMEUCIQCi0atkiz2j2etfm0qLA%2BYFjuDu00W6SfDL45%2FDufy4LwIgY6icItIdyKzNtO69BiPQLdniDw82Vsdqu5zYYTZFHgwqlAQI%2Bf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwwODIwMjY1NzYwNjYiDOps5Er3kt8HDgRDDCroA4IluNMeHp0M0QEvhExwcVMR%2FzLzVNv%2BieOgN3gao2WbywPicytIsT0AShyQsN%2FyHFa48%2B2fhJ1YwnnoklUol4KEAwqbVhVPqKyT8AjFYhNS8VaOJO3ZURlSXFAJHtr9%2FijeMttaqtxRB%2B4AGwyjlCRr2iNYK2DH6tw9D0QkotI6os6XP%2BZHeUUWM9IOZChnWn6sCnS0pXV%2F0UokKEoCpfaJFOnX8u5EYL01iAMLBIpNDcKxHiQJfA%2BQAIlXZ9d3g8JsN0o5GkBFjLiaCgYOx7FNv%2FRhGK4rpHCAki8XuLcV5%2BnlH9MYeWmebQUqXCKKc2YRXRf9e%2FKpDjn%2FNCjSv2wiYIjPSkHn%2FPc0GJQTU7UwhKb4WZjc4hhdtYvi3cKxCUodWwlkh5hMUm3qUgLeUwNXaxYmvSeThcz%2Blaa5ZrcHd%2FSjaqZdK3YvajiPvROeN80LH4nwPWHHKl6xKBWTlGCUcngPgZ8e9FQybe81riYOfJo%2BIPJBHZD4UPNTCOuYtUvP6iQjeUFhXqaQVCN9vgYLkPYBOojrC0nEt81qy2GqMJd%2B1UmbsWfGF%2FCUNcCrsZwZA46g72r1O1Gw6lGr4S%2BCpDZiTpdOe927aqypsV34wviFhiT%2FZqhzs%2F7PHhlMMy5F7e%2F9GLAhMMH04K0GOqUBUAHPldHN9GKfBhHE5ErrHoHOzn%2BxvW9xCYZCmIchQHW0XWdKToYBSi9ct5GHo0yBrqCDXGwwEPLLXGSTCDI3kRK4Kdd8yW0YUq2PJwOrmMONcE7cqOtwOoShIj8p9OeMsV16Dyq36x9Qp4Bhpgehcvs3Q%2Fzpu1CPzaYnHzh3pixvhyB0tY9Gxk0hqpyQfbqvXtBAx6m5qPdr4fAya8obVA7q9Z8j',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'U3kwdU5MZU1YVDA3UUJXcVpMRGowNHcxTnc0Z0tXQUg=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'DN1CdN4H+czX7yMoD3gaWA=='
		// 					},
		// 					metadata: {
		// 						date: 1706573369248,
		// 						uploadedBy: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 					}
		// 				}
		// 			],
		// 			drop: {
		// 				password: 'Sy0uNLeMXT07QBWqZLDj04w1Nw4gKWAH',
		// 				username: 'cwTInBjuFydCpeIc',
		// 				id: '9d82e869f5e0467bb94fbc06d5e3be7d'
		// 			}
		// 		},
		// 		{
		// 			id: '09809032-a150-48f6-b519-a57ccd4b8ba8',
		// 			active: true,
		// 			name: '101a6ef9-9040-40d4-8472-f274f77545d5|2024-02-01|22:46',
		// 			owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			queue: '',
		// 			workflow: {
		// 				id: '2f5201ae94614c51b69b4eea1ebb939a',
		// 				name: 'DBI1'
		// 			},
		// 			accepted: true,
		// 			status: 'action',
		// 			token:
		// 				'AQCAAAAAKgAAAAMAAAAAAAAAARIcQ9frBtJ5KhtlHWCk0YmYsM/J5/RYgYtoz0xADuwQaYSG0lSA72ms7YGR+gEAoA46bfARAwfNLIe9/AAKu6UR4anB9CBd4Ulb6AtO/xc=twCpk7FoLuuK5MwwfeQMbdGaCI9ZKs59jpcS+engUvWpi2z1yLPQkC1zJtSFGaZQh+/z8lUG+FaDCcl/4juM+QNbILKG6ZCIj9H9RVeBYW3L2ofSjJeu71iEJq4+6Mc0zn9pKW1EDsI/2MWi65sIoO+pqHTFEV+mKwqWXOEUE1a8w3BSZO0+Jm9ozqcY3rATitQzULIKhpAUnp7VP1zveqOofjlMyzTCs5hVBbXIuOsGZyOSmTGcVZnO1RZjHXhCfmKoCWTuEUcAnov2dCPxzVvfvul515gGzAxR5b+zcPrITQwtI1Yl3pjZx9CsVc/gF7gOhhGSm3VPUtiADRaM/ckyYLce2xlLyUIwucw7clrCnn60o2w3r3XRKlLTjKlQPxjIinR9Rz1166bKdl/llOaVDkwZtPZIIs7BJ2YTPsFiLfQz2xHs2Y2uKe27YDROOBeGlSpyfLbAQB0szccxk55oVAowbDH9ZPSJ8ZZQWX32BBXBNsBlZnCzLVI08E3wMv87OPuaMRuVcupiZg50',
		// 			nodeID: 'c1dc0cea85254d18b593bdf75693c37b',
		// 			nodeNamespace: 'PeopleUser',
		// 			returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 			createdAt: 1706827563796,
		// 			updatedAt: 1706827580919,
		// 			history: [],
		// 			notes: [],
		// 			attachments: [
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/101a6ef9-9040-40d4-8472-f274f77545d5?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224553Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=a22a34cd107fd65d835faf66decb372b41c029a89de4d61b4c1de4bed4a608e1&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/eb1869a8-fbe0-4180-93fc-98a149787d7f?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224554Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=e9a2bea4a58e63faf8fa4c9c25fcafaffe30b49df574f986334f7e4836e3e465&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/47def441-003d-44cf-85ea-8d726b2078c1?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224555Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=692c9ec9f05caef232d509c664e9dc9008d98ec817ec1bdc28746e39771aaeb0&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/1a35962e-05dc-42a3-b00e-6905ccfd080b?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224556Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=e099756249964356f9a0782987bd5b7eee42614211f5fc692445ab32c724d9c1&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/2bbd0b67-313e-4999-aa28-3695fe3ca9f0?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224557Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=1ecce715546cc716e6309928721062ac9bef4a4fa97d46dc66ffdf023cf5168b&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/85fe3891-2eec-45ca-99c9-ce4f6fc3d798?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224558Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=b82aa76e5f4fbe31f4828b2b03a7920d4539fa1d261bf8ce70bea180279b9467&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/5bea4b13-f700-4c43-ba72-ddb2f6bf1e27?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224559Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=f5b0792c3162af0098a03a0fc525aa68d8817ed366e84c89dca01c5284be6a09&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/4da11f6dfcd444668326e59b15b6531f/6a11e0d7-4f77-4a13-a2ed-c2417981e114?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5NV65XO%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T224600Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=ba3a506a3ccb700b28660dbb972c410127a226858dea9194d243cdd22ec330fa&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAdYS3KqkIA1CaEGPqaDHWN5%2FUgQt38sfOWcuQma%2B6NmAiEA9qiFpttjtmdpE3bz5GfCX3s%2BFWN3EVsow8QxqJ3vl7wqiwQIUBAEGgwwODIwMjY1NzYwNjYiDFJMrz2UWA1GN1HwtSroA4tnHTytar0VkdA8K7X6XYax9VtZ%2BXukb5bxGhl7Rw8enuIaokJTEqr2%2FznzoJ9Xyo6om56aH7DQ9sjTaONH18emBlL1BKsuCdxONyU2UymDrXQxeiWK5DNc%2B%2FV59zxhzzSNCRrlK4p11gJaTOq6zi%2BSeTrcmY15E7dArva1%2Bs0mP3iOjwsoBdeTF5SKbeOjgdeq8z606zf7nkcf2da20%2BExGnTE1JS4O5ZH3AkeGdczbejT9vtcNKxvcK9yhVpSeIWvFmQEh50M6XV5HXGy3bbXquALb1E5K1VWjv2tIEIE7zCJx0FJhwm4wSz0aIVbyesJsyVG8weHn8SraU31xRSAi1YURXwRW5L84BBrVfyN8S0ZsbQr%2Fql8cKy143dShL2DlRYTdzkF%2F2kbENzDU%2BWGLSSrrb9BLz2SAuCx3mUTvlIiDzNKbmYYHrDdR4fFD0iJrqmp0%2FZyjSZ3qPW8%2FmhHCQnYJ5r93f19l%2F8aRIWAh2kezqUi3Jc8S6%2BOD8ibT2cALC4zzKVmxTMWA8JYZqIav6hrwEGmUp8J8ZhfegJ7SeKYGh6GAl6JwgtBygEH4rR8fXVYtyUbiRGI%2BYcV0pUG90AAWWYWT%2B3Yixk4gcRsf8a194jgjYWz3zVgReLL%2BGVIVxBDGwHLMNq78K0GOqUBCtInHZHRcDV1%2Fw2nA%2BeGedy6uR8EhiHcI4OxkJtx2vfymmc4aPQkazVpUagMnp5UsKMULbwDvSNFHxFgSDwfuLQ2uV8FGSdIn2m2KF%2FqDljkwkPvvydxcitil1A4vmJjAlofu1l%2FLe6F1QHYBIACIOmq9jBrzKzCC9KM%2BBo6Lv0OJ0Tp4fBHPtSstlKedD8smI2fXbEZxYWCnZDeETNXGNDS9h7W',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'RHgxYVJ4aWw2U1FlbUY3Tkk4OXpQQW01UlgxTnhud1Q=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'CjEvdEOHOoXNpR9Vt2E+7g=='
		// 					},
		// 					metadata: {
		// 						date: 1706827562798,
		// 						uploadedBy: 'system'
		// 					}
		// 				}
		// 			],
		// 			drop: {
		// 				password: 'Dx1aRxil6SQemF7NI89zPAm5RX1NxnwT',
		// 				id: '4da11f6dfcd444668326e59b15b6531f',
		// 				username: 'wohGHNcGrhT9uG88'
		// 			}
		// 		},
		// 		{
		// 			id: '13322948-8a55-40c9-9dbe-29487e997dc4',
		// 			active: true,
		// 			name: 'fac0cecd-5143-4ef3-8858-ecd66db81ed1|2024-01-29|20:28',
		// 			owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			queue: '',
		// 			workflow: {
		// 				id: 'cdc495a5a0c84b4bbb6b5905408adc10',
		// 				name: "Josh's User Workflow"
		// 			},
		// 			accepted: true,
		// 			status: 'action',
		// 			token:
		// 				'AQCAAAAAKgAAAAMAAAAAAAAAAQ7Nn7cxrsKRymJfZJi6A0E6zbVF1OZulf3aDi4lIaQukqci3H+24rjIN/6DdkiSkktJZVCzbTjcDQdBkKbC1SptNLTk27JlJ6a+nLJwITs=d2mtDb3I8ClRKV8S8RoNZKzfPKYVaGnZLuO/EZlNphAyD7KIM1/RZQB9y8pb3PCi1xh9bcPN4I1dkrFOQdF6iQID6ES568eKJgTaoGOh2GKw/ldbUMQEftqOsPORu+KcedB85D71vlZH/wmt0zfEZVQAhnTfjVbrdP9b+757zZlJdIdx9U49xuTqywer26b956lWyHcLY22uRA/cyhb+FlLNVhAmZzgXXSKlXWYzv+5FClzGNQQKkX+HuAaRa7LhXsx74LBMXotSufnrnTeuUsUcg8ol8x6fD7Db3r/9cEjw2Iai1+eOxfyoPO0Zf+L/RUuU0XgOWhK+E1B7uR/diFLFaqKHmnJonV0JnABY6R+YMWxYTOoyZ8kyXTeBtZexpiV79LM+FrQQnyYv01C3gI7CnY87VZrfp4BJ1EIJv4mvrDBZ/8rV7G0sijGh8Hm5MiMwOyYIRdmqjfvStPMn0BkEhcolPNOTOa25dk9Wlxvh1KE1zZFly8K7QFRoCo4Yjv6R7vOUotXUSItzGiRo',
		// 			nodeID: 'a6b31abf2d684a4cb3ca4099b89bfa00',
		// 			nodeNamespace: 'PeopleUser',
		// 			returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 			createdAt: 1706560080575,
		// 			updatedAt: 1706570711997,
		// 			history: [],
		// 			notes: [],
		// 			attachments: [
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/a07d39c039d84717860aef3da917d8ee/fac0cecd-5143-4ef3-8858-ecd66db81ed1?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBOP6BTE6B%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T202746Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=2e1cf18165f9c0374b7095867274cf6639f229e63ea586397e4aa585ebd5cb1c&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED0aCXVzLWVhc3QtMSJGMEQCIHs9Ji35GScaTNyXRoBjVhhQQIMn98LiGc5J6aWk0FWDAiB%2Bv5lDHGY4%2FWKb0cPAltW4MTLSTzyJezerIztJ%2Bsnz6yqUBAj1%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAQaDDA4MjAyNjU3NjA2NiIMqFmYUUMOWGbUM6L%2BKugD7r8olVD3ZiCSEyOzS1YKGzCTmdEKAyuFd%2BmSTFqmkoiOCvmz1HDE8jK9iIiPpTes%2Fu2dS4b2DvZU%2BKXj%2BJfSeAG1l0E5XQqLrla7ZtFW%2F3cm6545e7Z%2BFQ6DqQm5kOk7xbbA4djcp1bd1sJbDAqi83Zw87kZfpzhJ6evPxiDs4hi2n0KEFrsdgwJw%2F1raBNlcSFlEMVgx9iKA2164vEtAf64b923xOJLAgnjywCg9SYIZpcDWpP5pXNoiRjv%2B7jeM57RuaSh4ngVXSdaYRAhcdaxkgerkUWUu2kRMLD09Tam5yEPq5MkpJ2ZXtlmMGt6dCYedClh4vHt2DUh4pgVYdz22iQDPGtGb0j9ceWJCTZMTGC7WgsvAyFzVSt3InPFYUJSfLKIQTjAzFOhly238ubsUvESwnVAnNAzbhSNbNmbs%2BHMJAKFfwryKtAiVQlcz0M7Qc792yBWMylYMHv1kWPuDEKu7%2FfDakf%2BsAYXq6SUAv3IpGYlx7eIh413rThAxnczAuUdIw1tAStBS9bQ3XYP00k04x%2Bg1C4JRA2W63tUdZ9ASFcRrMznCMbh7dTx%2FzzYvelSmevgQfzEYt%2FXoJc%2BtkM3qwo88uq97mTCrm4XgX5kSFeY3B7Nk4UXSsSA%2BodFhvuDwrYwqYvgrQY6pgFOIlnnKgWvIRD%2Fb%2BLeFGg1oExLRtVuqSYzAbbkKDEyExeC%2BsXgqqbVUME%2BifEa80BmIBPQI0Cez0QLzMEtfyEQp%2Bj3762IjWqmThUwP4Lzs%2F5iuCTwEdZYmU4llM8dneFnENDRnD4pFsJI2YgIlQh1bAHoqTdJJc2SmjX6cZHpolrLnobhBbH2NoFCND0O5WnEz2%2FXXgx5ZvNt2FuxdUVpK6d9hB%2B5',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'aURDSE9BUnRpMUVsTG81UGRoVzJIMzNvaGNMNlB5aXc=',
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'gsslJm5OKKxyFr6Chgwcow=='
		// 					},
		// 					metadata: {
		// 						date: 1706560080558,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/a07d39c039d84717860aef3da917d8ee/toastman.jpg?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBOHCWDAOD%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T211951Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=22e608a923033e818e461cb1e97003002dbf66f8ae42f504410b4a35ad782fb4&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED0aCXVzLWVhc3QtMSJHMEUCIQC3UzEUCG9barAtgaZ%2F%2B2Gb2EOV5lwUE6iVzeWfphJI5gIgAPFvPFQSnmuvjQpdp4g3ndgswAjLTPLsqx2HUmBCS74qlAQI9v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwwODIwMjY1NzYwNjYiDEwcxD6HHszcQigvqSroA5WyA3vdHvt2lGgz4%2FW7CfEWgeMgFVM1zpKBZtNlDKQaeQW9iAaqRDgLB57sp3z3hExNKHx7NCWUlxIKxSG1apMGOjAGtnT%2FYJzeZfE%2Bca8HrAWfhixH9%2FraG4C3SKd9WY8oirOEKORxlPB0O1eg8va37jE4jiQ61EHLu3skbNENmE476iP7gCqqFPMkRoRVPUHw2A6YV3PCs1wg%2BJWSnsv4KrQcXdY48yBRIkBae2oZKyJ4YLlFufgwK%2BlvjkDCtpgE%2BJKKau3s%2FlJAh2TvrWif32wIfS8bjYB9XaVQLJEFIXAnrIiMGjPLn6WEVL9KQwpI%2FOM1GTYNO%2F2s1q7qhKD7g3%2BvD6qq6zsPLYyh4YzTQ9j8zrdjOWXyFp%2Fb9r%2FKL4uv0qXMQJA0HlwCIR9kvm%2B526IOFI0GAR%2FLZv%2FVKP2SrCO%2BCOfn8kLDl60uyEUK0Ky5kEzjOyweiSiBI4s%2BLXLikViLBTRryVrTtwrWd%2Bj1XhAFFOIkFDEHXMvLNiO5ungw1ZVUqO8ZH5oXR2xj5ykttiUBGXz6PIwvAgw638oTYOU1okoNua8b%2BaW4BBwVoU1zk91veOj1J0xPuVn8j175oyi7APE1tzj6bqu48GIqCmKnXZ9boteew8IHeOR0i%2FgRi5hE6XTPMMWk4K0GOqUB3rJ9uo%2Bm4wViigQwxMjDinrLwzx3HCbEqpbeIm99BMd7cEZtMXmf56wrYZjp2e1hoYExaGDrFUoA%2BikY1TBko70OyFU52imU3PZbKpbzWNl1n1wZOpk2y2%2BdyLGPdaAERTIZwcdvjHwoEjg8wJfaHp%2BzRwwHZHlKlA79k6KLktMQtW9OqRyy4PH%2BL2EpJ2YZ4Er8zGlF%2FQ%2BQ%2FkhnPs%2BWL8VaaWx6',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'aURDSE9BUnRpMUVsTG81UGRoVzJIMzNvaGNMNlB5aXc=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'gsslJm5OKKxyFr6Chgwcow=='
		// 					},
		// 					metadata: {
		// 						date: 1706563192049,
		// 						uploadedBy: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/a07d39c039d84717860aef3da917d8ee/toastman.jpg?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBAI4PZJXW%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T212939Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=0f0352007355d7420756893837d24dd2c070961d2260611a002b4f7756ecf21a&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED4aCXVzLWVhc3QtMSJIMEYCIQD7JDborm151eWFiEHFFknaTFs3K5Weo9Y27N9H6diTFgIhANS2kGHMSvnRhY8AFxac2wR9RbFseqSueUiBBqkJcdp%2BKpQECPf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQBBoMMDgyMDI2NTc2MDY2Igwx8UHsaHLRnUKF86Mq6AOVXfXeg3cAIl58BqANObkpAVo2Rp%2FX74PxgIO36zalGXL8EYnf%2BiEvShl081eriYxYDkghaBGged5rBaXGx6bnRBjxM6fIt0KQ8qVMVlIgyzFkQATzTFBMC3%2BXdmKzhQjvhSfTOVB9WRE7LFQtQf0lDEuSOaDM4xzhmLfNZaej7zHdJxWy9vXBYohMTAASHmmVDU5%2BipraGT5CRNm62%2Bb7tHmJEvs%2FdiEUxrcSzu8aa8PCygNrF%2FstyGa1Yj427X54rJQYvAx7i2lkz92IzJrGkTnvrPWhM8vFcPFiFZOwjkuC28StSjSIRuCdMmfeOBVxxnU%2BF5r%2BoWNlx26oCkWZn767k6LDaryHg2algdtvtJmHxQFrGX4m9lF5q5J8FTgBscrY4xIWE7LeuC3B5aSKHq4JKesxQ5SLk17jJFYwViwZugLMWIOLrtZISwdake4n1bFgVw7Z83tVpcCJJemnu%2F8sTRmDnvo2%2BOT07hdbYzicDCxgBfkHA9gC8Ix09BWPRLJ64cef1IOCkbp%2Bk1ypSZXRGB%2BSe2AMezBR86ihPogO4cPNtTXfTtWtdnjtwkEAgDDJJUIrYgHj4AtO4mXtiiEXUf86tbHdz5ISHlRsPAoUrIMasttY%2FNtFfx0mR7OEi1Gdckud1TCHsOCtBjqkAZl%2FNEn3i6S4pFgGG4BkdqyBcixi0X14hwevrdMjOk4Fg1azF38DM98tfY46B8Wx5Dq9oK%2FZG%2Bw7ipipCcTozME9lOJiqQzwovd%2FV5m95ectNnkYyZay3p3o%2FU3y37%2BuHMugqoNK811%2FqBs%2FkoPDKbkeFCmoG%2BKuAdA9OzfDKiyQg7tF%2FHAQcl01SSvqqac6fQjeYaemPKkvTCHIAHmg24%2FDjThU',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'aURDSE9BUnRpMUVsTG81UGRoVzJIMzNvaGNMNlB5aXc=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'gsslJm5OKKxyFr6Chgwcow=='
		// 					},
		// 					metadata: {
		// 						date: 1706563780414,
		// 						uploadedBy: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/a07d39c039d84717860aef3da917d8ee/toastman.jpg?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBAI4PZJXW%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T213104Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=12605aa85302cc6e514e39d8f601f5e889dc7bdbde77581706c6544e154491f3&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED4aCXVzLWVhc3QtMSJIMEYCIQD7JDborm151eWFiEHFFknaTFs3K5Weo9Y27N9H6diTFgIhANS2kGHMSvnRhY8AFxac2wR9RbFseqSueUiBBqkJcdp%2BKpQECPf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQBBoMMDgyMDI2NTc2MDY2Igwx8UHsaHLRnUKF86Mq6AOVXfXeg3cAIl58BqANObkpAVo2Rp%2FX74PxgIO36zalGXL8EYnf%2BiEvShl081eriYxYDkghaBGged5rBaXGx6bnRBjxM6fIt0KQ8qVMVlIgyzFkQATzTFBMC3%2BXdmKzhQjvhSfTOVB9WRE7LFQtQf0lDEuSOaDM4xzhmLfNZaej7zHdJxWy9vXBYohMTAASHmmVDU5%2BipraGT5CRNm62%2Bb7tHmJEvs%2FdiEUxrcSzu8aa8PCygNrF%2FstyGa1Yj427X54rJQYvAx7i2lkz92IzJrGkTnvrPWhM8vFcPFiFZOwjkuC28StSjSIRuCdMmfeOBVxxnU%2BF5r%2BoWNlx26oCkWZn767k6LDaryHg2algdtvtJmHxQFrGX4m9lF5q5J8FTgBscrY4xIWE7LeuC3B5aSKHq4JKesxQ5SLk17jJFYwViwZugLMWIOLrtZISwdake4n1bFgVw7Z83tVpcCJJemnu%2F8sTRmDnvo2%2BOT07hdbYzicDCxgBfkHA9gC8Ix09BWPRLJ64cef1IOCkbp%2Bk1ypSZXRGB%2BSe2AMezBR86ihPogO4cPNtTXfTtWtdnjtwkEAgDDJJUIrYgHj4AtO4mXtiiEXUf86tbHdz5ISHlRsPAoUrIMasttY%2FNtFfx0mR7OEi1Gdckud1TCHsOCtBjqkAZl%2FNEn3i6S4pFgGG4BkdqyBcixi0X14hwevrdMjOk4Fg1azF38DM98tfY46B8Wx5Dq9oK%2FZG%2Bw7ipipCcTozME9lOJiqQzwovd%2FV5m95ectNnkYyZay3p3o%2FU3y37%2BuHMugqoNK811%2FqBs%2FkoPDKbkeFCmoG%2BKuAdA9OzfDKiyQg7tF%2FHAQcl01SSvqqac6fQjeYaemPKkvTCHIAHmg24%2FDjThU',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'aURDSE9BUnRpMUVsTG81UGRoVzJIMzNvaGNMNlB5aXc=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'gsslJm5OKKxyFr6Chgwcow=='
		// 					},
		// 					metadata: {
		// 						date: 1706563865584,
		// 						uploadedBy: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/a07d39c039d84717860aef3da917d8ee/dstrat5.png?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBNLFGIBPF%2F20240129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240129T232507Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=15c6087c156d1e81773631aab5e2c7bb01a008dd2b3d372092b92e7e12aeafda&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEAaCXVzLWVhc3QtMSJGMEQCIDP0nDAULK6X7rL7AJMqiFpSgF51%2BpPJwS4hpo3DX7JvAiBpLi7frdk2OoLSB%2BBN%2FQL3RIWFfubFqPx9BHmAV%2BALJSqUBAj4%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAQaDDA4MjAyNjU3NjA2NiIMCBtFa8icwtsHrwujKugDyvUAzmvy%2BQkhq8bXvgSg6z3z9BmaWrngT7kqbeIYRkrPKGjfS52g2zVP3jYI42kOU%2F3M9BzjJkGVOVb%2FJAms3hhyxN45cI%2BDwOPvQ3efMWYYXuITWylp4W0xshm2j5wbPAl3GJjgUJxZPuNBTpsZ5V42BBCDyWKEEIrUQu%2FrMQDL209RBcBHu7%2BVfs6liNfrpZot7lMGp59dN2iaAgt4hJSpCD7q8gAfLKP%2BWKh4qU2SBKDB1Ky2NAuKAjbOj5nf6kgs1lUVL1Ad511ssEtucePfbJVWusUNQamUxWmK7vL6H09XR%2BhVWyusxo3y6TZwv7jjFwLT9pss2oZW2rZu%2F44kVkfkkbmzczb6n8SzvRwr8QozOrI7lO7%2F7TxBQCmFhVrRGfXRBs35Wh6ByxvL4NEyoM9Gk7zrF%2F8AKyc6Ftmsc8i6IcplMAknKzB%2B1HqGMbaW81R4nABebtP2ZRGEVFI%2FiDyZlg0Aw4S8RehtSE2U3hP7nBowspocHgE7H%2BqpKfCuRn1%2B5hqj3%2F1NG0QWaHO2wRKZgiFMFoEBy9jHvL%2BL0tmMazCgU62IZq8PWRHNwrznBEURjodMYKznYYkX5gemAz3EyToykmsMOPs7ocIAPA7UA9ndiEYtBhfcHY8WS0CibAXtVWUwruHgrQY6pgHKAMVsBrfNssMU0bogFkmsWfpC9Om6M23gNeXmBy1Co5cIzllzdjQdXs%2FBNNZVo7XERDMuLh8%2FsvpKDpN3fZJ0ryrHw45RSsQNkr7bBwO2F9kJyUjyGUHpoCwHzomeVRWWXwG05xSIcXBqG0TxlWogx3uvC6xgN8fyGO92Okl69tN93WXYcjxbCxgvq1d6yftP7cd%2FqrW6RJV9bTdF1B4ivp1%2FP8%2Fv',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'aURDSE9BUnRpMUVsTG81UGRoVzJIMzNvaGNMNlB5aXc=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'gsslJm5OKKxyFr6Chgwcow=='
		// 					},
		// 					metadata: {
		// 						date: 1706570708913,
		// 						uploadedBy: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd'
		// 					}
		// 				}
		// 			],
		// 			drop: {
		// 				password: 'iDCHOARti1ElLo5PdhW2H33ohcL6Pyiw',
		// 				username: 'gdIkjnokJwThVI22',
		// 				id: 'a07d39c039d84717860aef3da917d8ee'
		// 			}
		// 		},
		// 		{
		// 			id: '2bd8f684-59e7-4582-9794-8b90c548ec36',
		// 			active: true,
		// 			name: '20240130T112512692366.pdf|2024-01-30|16:27',
		// 			owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			queue: '',
		// 			workflow: {
		// 				id: '9bbd9aa6fc834ee9912064a28679b72e',
		// 				name: 'Pat user node wf'
		// 			},
		// 			accepted: true,
		// 			status: 'action',
		// 			token:
		// 				'AQCAAAAAKgAAAAMAAAAAAAAAAZRXaJBB7gFHq1KzVigScio1nda9LIgso/OENmYe4LCrzbX/aMXrjCXg+hedSD2IRQd5UFdxLXynKczbzPONmtY3RVZz3AKIeHJhAD6pMpE=NGu+me/WJrPIYDyduK5pvN+NCpvmgMf52oQimrTw4uW23B0eMCw9e6CDJ3VGnQU2qWxD6SI+LctjgJs9cljiAe9X0m/J/sJZG84VktLnHVvv28VZGcG3HFnImf0wOmCIssj1V8onPiIR/2Sh1dLz8ZiyH5RI9sd3R/EqrnzWZcyR5HNXvb/WSyE3886SCIa5YJpxXsyN5ywt/sZP5rdkbNmQy8knQRG+C8CmObZ/LsUj5j3LRg6j61+tFiVAyjvQGu7UJ7ovu9D5E5ClNds22oZZlQFfNAD4ZSiW/cEmJwfZYahIyQnF4GzAwa9BhyUlAFg2pfXU/eaakMZzQWvLugxBThJwUmcA7LJeyTFaMO+thixSqkN2QBTLH8oOmJfILc8c/hbce8nhIhfsh/OLawfGrsCj0hjf+lphKEQwhFEbZoTxgMoXol4o3ekQh/2/WQP0Dqdvs8AF5ef5iZtMb/JdWMS1IqEW93dpstN1gHGhaBD0wsckmifRXkvq4s6YERYIZxZdtG30Am7zH9ak',
		// 			nodeID: '8fed5478b92948c9855098140ee3d226',
		// 			nodeNamespace: 'PeopleUser',
		// 			returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 			createdAt: 1706632051847,
		// 			updatedAt: 1706632717781,
		// 			history: [],
		// 			notes: [],
		// 			attachments: [
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/18e01f0c741447ec93209175d3a3d6ea/20240130T112512692366.pdf?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBNO22HO3G%2F20240130%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240130T162723Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=03910a4937460b2b21e4766b2b9dee00c18c7a82f726d310b120e8171f20c7ab&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEFEaCXVzLWVhc3QtMSJHMEUCIDrdZeaBT1kveVvUrhnSbbc7URS2bYNHhY8iZP9HPZ3XAiEAlxxG7%2FpBWDF83XsawKhh8fbVJexQyvXEPQtqjOHtcxMqiwQIGhAEGgwwODIwMjY1NzYwNjYiDDa10m7MpmZbnqpU0yroAw3A3Kk9QO0z5ZH9pek49XijXSWYnMMBCpCaT%2Fn%2FXxMxbn%2F5oSN%2BtDSXZVzMI%2BrJ5ZkzzObd3csyGJO68XlcDG2bS9vn9oP7z%2FA7DR3qUhOoU%2BOoK8GVULU95ud86XwjUH1L5c8R5FLHddlGIZCNO0oRY%2B0KB1ttxFC337mzmNOLVvNqNLffHcmmmYRHum6ojB5bRv6leDVSD5S7XnEzLxmwQGNR5k1At450Vw5T3B5sHqWvFl%2Bsdl%2B%2BEtjxMes2Dm4KS8ZrsNLyzK%2F7dqd0ddcgvxGTqXf0aVxAM8QrIRa1iLFwDKK7hf%2B4BibRnRZDjGuIME2lufqDsf55gAyaSShSxDTn8%2FxCBOXR0kxcnRTFI23fcoDJfYaYUfqmGai06n%2FSJ6BJ5jEDuAVOaYXIcp%2B%2F7esod0r8VWkbvWYyGmwo2kk6Wb1PlJ2%2B6vP1gayPvE0%2Fm9%2Fm54jNUKvwi2ckG7BdRcVCHNY9y7ABgIm1PSoAmyr%2FEBJeNNdAqlDTvEozCSbFcHoXVJMIZ1JKNH0rT%2FCSYX2lh8fCyRGXnt%2Fib9suppm6uoTFN9wflOz9%2Bw2Kimf9buC5z%2Fp6bOyE75IODoXIkufOkejuTj8K1jvtZf9fEqksd2v7fEhH2YKGvZL%2BMA%2BnrKUcsIseMPHE5K0GOqUBQxC%2BgyTaJ2QZG%2FRkZwSVra52C5r6cGwPq51tKCu85avOCJlUy4Dky4kGahqszccRPRMbr1ytFx85h2XkzfB8vgNnDz0djoPS%2BgFRwaJ6o242PV24mHDqXNxwENyk5%2B%2B0%2Fk%2BN80C7hGG5uXpZGmIgAjJAkU%2BGtxL6hLu1rbvt9HKTp%2FGMwRI0CZL3wUcgQZi9WmS7KskyAUTQmVJf6EBpeL05GNlm',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'rTtx4/YdECAVVTe0Tets3g==',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'S0Y4UGlUYmk2dE9MNkV0R25qd2tXenl1R0JJM1pZWmw='
		// 					},
		// 					metadata: {
		// 						date: 1706632051528,
		// 						uploadedBy: 'system'
		// 					}
		// 				}
		// 			],
		// 			drop: {
		// 				password: 'KF8PiTbi6tOL6EtGnjwkWzyuGBI3ZYZl',
		// 				id: '18e01f0c741447ec93209175d3a3d6ea',
		// 				username: 'Z7l3OdIkNSQ2TVXx'
		// 			}
		// 		},
		// 		{
		// 			id: '33105ae7-1471-4a8d-9d79-4f560d76470b',
		// 			active: true,
		// 			name: 'f9e49a20-4ba0-49f4-b545-71547b60f771|2024-02-01|17:51',
		// 			owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			queue: '',
		// 			workflow: {
		// 				id: '2f5201ae94614c51b69b4eea1ebb939a',
		// 				name: 'DBI1'
		// 			},
		// 			accepted: true,
		// 			status: 'action',
		// 			token:
		// 				'AQCAAAAAKgAAAAMAAAAAAAAAAe+SHa+jY8VtAPEcZSBUJYBYL0Ttkh6k46CSaU/z3QG0L09hF7Jr6tSs/4Uy3JKPvn1DxGa0SySPYH1WESsvhTFfDSxc0pElCHDdOPcMJG4=4qx5ldbBFYo41fLwAM4XyTUebfIHnFyr0Y5nyEUmq/5rCqiAovbfiNbVjZ2UOIEjBu/tW1nxDOZCL9FERsSQn7q00ZhcdMxPGy1df2vbjQ6AQIEpMuJkUyppAlPLgpGd6If6NQHxKCznGOE0UoQ954k35kMIvNHRCIxsO/xHXUDXCcpgGK4dRVleAsF4c3P5rFJ6XbOON+2M/h6u58LpK9S6VtmUNo+/ryoDzDuvleUr9Y7CVSNjCOqlaj8LoJe/A9gs5efhtHqVrWlmBUx3os0kthLwPh3lbn8nFz5ycOAODwSBnAK3GMsDF7TvhBmUH0QSlItLpN9zJONTFsINey9fdJUdBiady/dJFRfB+bhf80ArM0UZPPmexxcmkhX990dRcWoKvaTCObVZNS1kEdHg1MEce0exdVdIKGJIoBCVyWgU7B7BHG6mF0Ejua5my0khJWh+FzciUS58oijcQrS36AL9NTNzSQnEOXoSVMbWbQq/zF0YQapVdOMS/vTTJAWAYdHThY7GgAtRc7g7',
		// 			nodeID: 'c1dc0cea85254d18b593bdf75693c37b',
		// 			nodeNamespace: 'PeopleUser',
		// 			returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 			createdAt: 1706809897386,
		// 			updatedAt: 1706810163244,
		// 			history: [],
		// 			notes: [],
		// 			attachments: [
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0c312e75a9cc45a3bca91ee9f398b63c/f66bd49e-81a2-4279-99e2-69650c51300c?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEGHSHIE3%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T175125Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=e47b3389df68a961062229df99af333fec41df9eee63b2b305aeccccb97c987b&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDycCqOyWZetHrGTUdF2%2FBBh2i13d6AQMU%2FL4cv62uPrgIhAIHEuUaKKEiY%2Bjv7Gy5rzzkmkcHswJEcaWDLyyr01Z0dKosECEsQBBoMMDgyMDI2NTc2MDY2IgykEjTJRrPnrTTJyDoq6AMr80yg6hW8iBVv8FeoV5fihhV03QUtsspqQgKMT1thDKWCqXArtTvGUjkjPprENB0D7A%2FgOKAXnyTxRE8dnUmnboafYnzfhaem2lddlNPAhSeEgJT9AyH1WQNABOosUwlpeyAVO3qBLuzAlfi0w2DUZKFtHc%2B4QRg%2Fe69q%2Fd85SMixebST1sIicPP7tWcRFtguS%2FfQH%2FFilyUL2fiadEmW2pwSQ2RA2ZpkRf0EQd3OoztK8IBA1cti%2BIX84bwf0p8QJlmAENVyrRBY9DLqOKAep27IpHu1Fzpq7LenbHYLfAlIHqPXxjL82XEoNaOQm%2Bw2OSegTPI%2BIp%2B6TQM%2FR3SB4tURLRk8ad4GHVaqI0x1TWCeoVlDEwqfWO%2BME%2FOjscqqX%2BqismoT59vvpacVIyFk%2F%2FTD9tXCNorAMRE%2FJXQ4e5qtuCcq8O%2FxZCQk2pdrHtBu5UCc6%2BrqE%2Bm8u7WPfES5eBkrXBXQiCN3zH9HXsbnG%2BTbcB7I4%2B4N%2F%2BCOdrMZ4zeQ89SycbbIZdwu1oDke2aJ96olm9jbIGCBDgK6UDFrMNxtw3GjiuQODH6OhI6L27Il58bU%2BvpLWz%2FbIHFKKEs0n2iwXfdOrTQ0Rq%2B31N9Ux2N3YUbFBNpbGqPjUeBw8nYVr3Cr4CwzJzChsu%2BtBjqkAc5x1GupznX4dumRywcsbEbR2k5Mg0C3Ogs71VRSzF%2F1MxQQ8CHwR%2F7wNu48mRPm5F8oi5mZCL9Rjs%2FVoypIZwAoadcZcFttDV3YKrUng08I%2BEk0A0nCSfxxHlexdMopbsEihQ9b5NQOesFzCgwuU1i4NxPvTg8ilVc64jRZ76ZsWf4BTwf%2FW%2FYYHwb4Y%2F2excjDB8N2Ikf9sMZkfzbJldRyNaKT',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'N2NSbU51bjVtUjE2OVoyanpBd3hZSUZxM3NIZTh6dzY=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'D1r+5Dnm5RDLg6IXUuBdsA=='
		// 					},
		// 					metadata: {
		// 						date: 1706809896410,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0c312e75a9cc45a3bca91ee9f398b63c/c0cab34b-c15c-4472-ae63-7f9650c01e75?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEGHSHIE3%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T175126Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=d1a236b02c240228978d2f388442809c35eec9b3d6a042a071fb5d2413906289&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDycCqOyWZetHrGTUdF2%2FBBh2i13d6AQMU%2FL4cv62uPrgIhAIHEuUaKKEiY%2Bjv7Gy5rzzkmkcHswJEcaWDLyyr01Z0dKosECEsQBBoMMDgyMDI2NTc2MDY2IgykEjTJRrPnrTTJyDoq6AMr80yg6hW8iBVv8FeoV5fihhV03QUtsspqQgKMT1thDKWCqXArtTvGUjkjPprENB0D7A%2FgOKAXnyTxRE8dnUmnboafYnzfhaem2lddlNPAhSeEgJT9AyH1WQNABOosUwlpeyAVO3qBLuzAlfi0w2DUZKFtHc%2B4QRg%2Fe69q%2Fd85SMixebST1sIicPP7tWcRFtguS%2FfQH%2FFilyUL2fiadEmW2pwSQ2RA2ZpkRf0EQd3OoztK8IBA1cti%2BIX84bwf0p8QJlmAENVyrRBY9DLqOKAep27IpHu1Fzpq7LenbHYLfAlIHqPXxjL82XEoNaOQm%2Bw2OSegTPI%2BIp%2B6TQM%2FR3SB4tURLRk8ad4GHVaqI0x1TWCeoVlDEwqfWO%2BME%2FOjscqqX%2BqismoT59vvpacVIyFk%2F%2FTD9tXCNorAMRE%2FJXQ4e5qtuCcq8O%2FxZCQk2pdrHtBu5UCc6%2BrqE%2Bm8u7WPfES5eBkrXBXQiCN3zH9HXsbnG%2BTbcB7I4%2B4N%2F%2BCOdrMZ4zeQ89SycbbIZdwu1oDke2aJ96olm9jbIGCBDgK6UDFrMNxtw3GjiuQODH6OhI6L27Il58bU%2BvpLWz%2FbIHFKKEs0n2iwXfdOrTQ0Rq%2B31N9Ux2N3YUbFBNpbGqPjUeBw8nYVr3Cr4CwzJzChsu%2BtBjqkAc5x1GupznX4dumRywcsbEbR2k5Mg0C3Ogs71VRSzF%2F1MxQQ8CHwR%2F7wNu48mRPm5F8oi5mZCL9Rjs%2FVoypIZwAoadcZcFttDV3YKrUng08I%2BEk0A0nCSfxxHlexdMopbsEihQ9b5NQOesFzCgwuU1i4NxPvTg8ilVc64jRZ76ZsWf4BTwf%2FW%2FYYHwb4Y%2F2excjDB8N2Ikf9sMZkfzbJldRyNaKT',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'N2NSbU51bjVtUjE2OVoyanpBd3hZSUZxM3NIZTh6dzY=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'D1r+5Dnm5RDLg6IXUuBdsA=='
		// 					},
		// 					metadata: {
		// 						date: 1706809896410,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0c312e75a9cc45a3bca91ee9f398b63c/82214d9a-b6d5-4bc1-8369-f23a5b8010e1?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEGHSHIE3%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T175127Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=11188fb620420dfc19486e706026d0bf1735f7fa312b0cde5097742eeec46a00&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDycCqOyWZetHrGTUdF2%2FBBh2i13d6AQMU%2FL4cv62uPrgIhAIHEuUaKKEiY%2Bjv7Gy5rzzkmkcHswJEcaWDLyyr01Z0dKosECEsQBBoMMDgyMDI2NTc2MDY2IgykEjTJRrPnrTTJyDoq6AMr80yg6hW8iBVv8FeoV5fihhV03QUtsspqQgKMT1thDKWCqXArtTvGUjkjPprENB0D7A%2FgOKAXnyTxRE8dnUmnboafYnzfhaem2lddlNPAhSeEgJT9AyH1WQNABOosUwlpeyAVO3qBLuzAlfi0w2DUZKFtHc%2B4QRg%2Fe69q%2Fd85SMixebST1sIicPP7tWcRFtguS%2FfQH%2FFilyUL2fiadEmW2pwSQ2RA2ZpkRf0EQd3OoztK8IBA1cti%2BIX84bwf0p8QJlmAENVyrRBY9DLqOKAep27IpHu1Fzpq7LenbHYLfAlIHqPXxjL82XEoNaOQm%2Bw2OSegTPI%2BIp%2B6TQM%2FR3SB4tURLRk8ad4GHVaqI0x1TWCeoVlDEwqfWO%2BME%2FOjscqqX%2BqismoT59vvpacVIyFk%2F%2FTD9tXCNorAMRE%2FJXQ4e5qtuCcq8O%2FxZCQk2pdrHtBu5UCc6%2BrqE%2Bm8u7WPfES5eBkrXBXQiCN3zH9HXsbnG%2BTbcB7I4%2B4N%2F%2BCOdrMZ4zeQ89SycbbIZdwu1oDke2aJ96olm9jbIGCBDgK6UDFrMNxtw3GjiuQODH6OhI6L27Il58bU%2BvpLWz%2FbIHFKKEs0n2iwXfdOrTQ0Rq%2B31N9Ux2N3YUbFBNpbGqPjUeBw8nYVr3Cr4CwzJzChsu%2BtBjqkAc5x1GupznX4dumRywcsbEbR2k5Mg0C3Ogs71VRSzF%2F1MxQQ8CHwR%2F7wNu48mRPm5F8oi5mZCL9Rjs%2FVoypIZwAoadcZcFttDV3YKrUng08I%2BEk0A0nCSfxxHlexdMopbsEihQ9b5NQOesFzCgwuU1i4NxPvTg8ilVc64jRZ76ZsWf4BTwf%2FW%2FYYHwb4Y%2F2excjDB8N2Ikf9sMZkfzbJldRyNaKT',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'N2NSbU51bjVtUjE2OVoyanpBd3hZSUZxM3NIZTh6dzY=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'D1r+5Dnm5RDLg6IXUuBdsA=='
		// 					},
		// 					metadata: {
		// 						date: 1706809896410,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0c312e75a9cc45a3bca91ee9f398b63c/19cae1a7-2446-450b-a9e6-8baab8ccf595?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEGHSHIE3%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T175128Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=cdafdf3116d91c24f6aeb759e2dea7b26b2c968e06fb96c32c66a4af865b3016&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDycCqOyWZetHrGTUdF2%2FBBh2i13d6AQMU%2FL4cv62uPrgIhAIHEuUaKKEiY%2Bjv7Gy5rzzkmkcHswJEcaWDLyyr01Z0dKosECEsQBBoMMDgyMDI2NTc2MDY2IgykEjTJRrPnrTTJyDoq6AMr80yg6hW8iBVv8FeoV5fihhV03QUtsspqQgKMT1thDKWCqXArtTvGUjkjPprENB0D7A%2FgOKAXnyTxRE8dnUmnboafYnzfhaem2lddlNPAhSeEgJT9AyH1WQNABOosUwlpeyAVO3qBLuzAlfi0w2DUZKFtHc%2B4QRg%2Fe69q%2Fd85SMixebST1sIicPP7tWcRFtguS%2FfQH%2FFilyUL2fiadEmW2pwSQ2RA2ZpkRf0EQd3OoztK8IBA1cti%2BIX84bwf0p8QJlmAENVyrRBY9DLqOKAep27IpHu1Fzpq7LenbHYLfAlIHqPXxjL82XEoNaOQm%2Bw2OSegTPI%2BIp%2B6TQM%2FR3SB4tURLRk8ad4GHVaqI0x1TWCeoVlDEwqfWO%2BME%2FOjscqqX%2BqismoT59vvpacVIyFk%2F%2FTD9tXCNorAMRE%2FJXQ4e5qtuCcq8O%2FxZCQk2pdrHtBu5UCc6%2BrqE%2Bm8u7WPfES5eBkrXBXQiCN3zH9HXsbnG%2BTbcB7I4%2B4N%2F%2BCOdrMZ4zeQ89SycbbIZdwu1oDke2aJ96olm9jbIGCBDgK6UDFrMNxtw3GjiuQODH6OhI6L27Il58bU%2BvpLWz%2FbIHFKKEs0n2iwXfdOrTQ0Rq%2B31N9Ux2N3YUbFBNpbGqPjUeBw8nYVr3Cr4CwzJzChsu%2BtBjqkAc5x1GupznX4dumRywcsbEbR2k5Mg0C3Ogs71VRSzF%2F1MxQQ8CHwR%2F7wNu48mRPm5F8oi5mZCL9Rjs%2FVoypIZwAoadcZcFttDV3YKrUng08I%2BEk0A0nCSfxxHlexdMopbsEihQ9b5NQOesFzCgwuU1i4NxPvTg8ilVc64jRZ76ZsWf4BTwf%2FW%2FYYHwb4Y%2F2excjDB8N2Ikf9sMZkfzbJldRyNaKT',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'N2NSbU51bjVtUjE2OVoyanpBd3hZSUZxM3NIZTh6dzY=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'D1r+5Dnm5RDLg6IXUuBdsA=='
		// 					},
		// 					metadata: {
		// 						date: 1706809896410,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0c312e75a9cc45a3bca91ee9f398b63c/2938cc41-06e5-4aa8-96a3-fbbd20a19050?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEGHSHIE3%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T175129Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=39c448ddfc6da6d0a943dbf1127098208b9478be02578a329cc8f8e112418701&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDycCqOyWZetHrGTUdF2%2FBBh2i13d6AQMU%2FL4cv62uPrgIhAIHEuUaKKEiY%2Bjv7Gy5rzzkmkcHswJEcaWDLyyr01Z0dKosECEsQBBoMMDgyMDI2NTc2MDY2IgykEjTJRrPnrTTJyDoq6AMr80yg6hW8iBVv8FeoV5fihhV03QUtsspqQgKMT1thDKWCqXArtTvGUjkjPprENB0D7A%2FgOKAXnyTxRE8dnUmnboafYnzfhaem2lddlNPAhSeEgJT9AyH1WQNABOosUwlpeyAVO3qBLuzAlfi0w2DUZKFtHc%2B4QRg%2Fe69q%2Fd85SMixebST1sIicPP7tWcRFtguS%2FfQH%2FFilyUL2fiadEmW2pwSQ2RA2ZpkRf0EQd3OoztK8IBA1cti%2BIX84bwf0p8QJlmAENVyrRBY9DLqOKAep27IpHu1Fzpq7LenbHYLfAlIHqPXxjL82XEoNaOQm%2Bw2OSegTPI%2BIp%2B6TQM%2FR3SB4tURLRk8ad4GHVaqI0x1TWCeoVlDEwqfWO%2BME%2FOjscqqX%2BqismoT59vvpacVIyFk%2F%2FTD9tXCNorAMRE%2FJXQ4e5qtuCcq8O%2FxZCQk2pdrHtBu5UCc6%2BrqE%2Bm8u7WPfES5eBkrXBXQiCN3zH9HXsbnG%2BTbcB7I4%2B4N%2F%2BCOdrMZ4zeQ89SycbbIZdwu1oDke2aJ96olm9jbIGCBDgK6UDFrMNxtw3GjiuQODH6OhI6L27Il58bU%2BvpLWz%2FbIHFKKEs0n2iwXfdOrTQ0Rq%2B31N9Ux2N3YUbFBNpbGqPjUeBw8nYVr3Cr4CwzJzChsu%2BtBjqkAc5x1GupznX4dumRywcsbEbR2k5Mg0C3Ogs71VRSzF%2F1MxQQ8CHwR%2F7wNu48mRPm5F8oi5mZCL9Rjs%2FVoypIZwAoadcZcFttDV3YKrUng08I%2BEk0A0nCSfxxHlexdMopbsEihQ9b5NQOesFzCgwuU1i4NxPvTg8ilVc64jRZ76ZsWf4BTwf%2FW%2FYYHwb4Y%2F2excjDB8N2Ikf9sMZkfzbJldRyNaKT',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'N2NSbU51bjVtUjE2OVoyanpBd3hZSUZxM3NIZTh6dzY=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'D1r+5Dnm5RDLg6IXUuBdsA=='
		// 					},
		// 					metadata: {
		// 						date: 1706809896410,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0c312e75a9cc45a3bca91ee9f398b63c/4a2071ac-7d3c-4dcb-a442-7f6c00158a48?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEGHSHIE3%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T175131Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=6324d3a009386527f5b0611864b5f721d94ecb36c60891362e0e108e9885951b&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDycCqOyWZetHrGTUdF2%2FBBh2i13d6AQMU%2FL4cv62uPrgIhAIHEuUaKKEiY%2Bjv7Gy5rzzkmkcHswJEcaWDLyyr01Z0dKosECEsQBBoMMDgyMDI2NTc2MDY2IgykEjTJRrPnrTTJyDoq6AMr80yg6hW8iBVv8FeoV5fihhV03QUtsspqQgKMT1thDKWCqXArtTvGUjkjPprENB0D7A%2FgOKAXnyTxRE8dnUmnboafYnzfhaem2lddlNPAhSeEgJT9AyH1WQNABOosUwlpeyAVO3qBLuzAlfi0w2DUZKFtHc%2B4QRg%2Fe69q%2Fd85SMixebST1sIicPP7tWcRFtguS%2FfQH%2FFilyUL2fiadEmW2pwSQ2RA2ZpkRf0EQd3OoztK8IBA1cti%2BIX84bwf0p8QJlmAENVyrRBY9DLqOKAep27IpHu1Fzpq7LenbHYLfAlIHqPXxjL82XEoNaOQm%2Bw2OSegTPI%2BIp%2B6TQM%2FR3SB4tURLRk8ad4GHVaqI0x1TWCeoVlDEwqfWO%2BME%2FOjscqqX%2BqismoT59vvpacVIyFk%2F%2FTD9tXCNorAMRE%2FJXQ4e5qtuCcq8O%2FxZCQk2pdrHtBu5UCc6%2BrqE%2Bm8u7WPfES5eBkrXBXQiCN3zH9HXsbnG%2BTbcB7I4%2B4N%2F%2BCOdrMZ4zeQ89SycbbIZdwu1oDke2aJ96olm9jbIGCBDgK6UDFrMNxtw3GjiuQODH6OhI6L27Il58bU%2BvpLWz%2FbIHFKKEs0n2iwXfdOrTQ0Rq%2B31N9Ux2N3YUbFBNpbGqPjUeBw8nYVr3Cr4CwzJzChsu%2BtBjqkAc5x1GupznX4dumRywcsbEbR2k5Mg0C3Ogs71VRSzF%2F1MxQQ8CHwR%2F7wNu48mRPm5F8oi5mZCL9Rjs%2FVoypIZwAoadcZcFttDV3YKrUng08I%2BEk0A0nCSfxxHlexdMopbsEihQ9b5NQOesFzCgwuU1i4NxPvTg8ilVc64jRZ76ZsWf4BTwf%2FW%2FYYHwb4Y%2F2excjDB8N2Ikf9sMZkfzbJldRyNaKT',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'N2NSbU51bjVtUjE2OVoyanpBd3hZSUZxM3NIZTh6dzY=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'D1r+5Dnm5RDLg6IXUuBdsA=='
		// 					},
		// 					metadata: {
		// 						date: 1706809896410,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/0c312e75a9cc45a3bca91ee9f398b63c/2b3c83af-0864-42c1-8e89-c526128bc515?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEGHSHIE3%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T175132Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=6bd37b5dc56edef3ccba9a6fab542942acaaf1f12442504602c4350e6cd7ba8c&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDycCqOyWZetHrGTUdF2%2FBBh2i13d6AQMU%2FL4cv62uPrgIhAIHEuUaKKEiY%2Bjv7Gy5rzzkmkcHswJEcaWDLyyr01Z0dKosECEsQBBoMMDgyMDI2NTc2MDY2IgykEjTJRrPnrTTJyDoq6AMr80yg6hW8iBVv8FeoV5fihhV03QUtsspqQgKMT1thDKWCqXArtTvGUjkjPprENB0D7A%2FgOKAXnyTxRE8dnUmnboafYnzfhaem2lddlNPAhSeEgJT9AyH1WQNABOosUwlpeyAVO3qBLuzAlfi0w2DUZKFtHc%2B4QRg%2Fe69q%2Fd85SMixebST1sIicPP7tWcRFtguS%2FfQH%2FFilyUL2fiadEmW2pwSQ2RA2ZpkRf0EQd3OoztK8IBA1cti%2BIX84bwf0p8QJlmAENVyrRBY9DLqOKAep27IpHu1Fzpq7LenbHYLfAlIHqPXxjL82XEoNaOQm%2Bw2OSegTPI%2BIp%2B6TQM%2FR3SB4tURLRk8ad4GHVaqI0x1TWCeoVlDEwqfWO%2BME%2FOjscqqX%2BqismoT59vvpacVIyFk%2F%2FTD9tXCNorAMRE%2FJXQ4e5qtuCcq8O%2FxZCQk2pdrHtBu5UCc6%2BrqE%2Bm8u7WPfES5eBkrXBXQiCN3zH9HXsbnG%2BTbcB7I4%2B4N%2F%2BCOdrMZ4zeQ89SycbbIZdwu1oDke2aJ96olm9jbIGCBDgK6UDFrMNxtw3GjiuQODH6OhI6L27Il58bU%2BvpLWz%2FbIHFKKEs0n2iwXfdOrTQ0Rq%2B31N9Ux2N3YUbFBNpbGqPjUeBw8nYVr3Cr4CwzJzChsu%2BtBjqkAc5x1GupznX4dumRywcsbEbR2k5Mg0C3Ogs71VRSzF%2F1MxQQ8CHwR%2F7wNu48mRPm5F8oi5mZCL9Rjs%2FVoypIZwAoadcZcFttDV3YKrUng08I%2BEk0A0nCSfxxHlexdMopbsEihQ9b5NQOesFzCgwuU1i4NxPvTg8ilVc64jRZ76ZsWf4BTwf%2FW%2FYYHwb4Y%2F2excjDB8N2Ikf9sMZkfzbJldRyNaKT',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'N2NSbU51bjVtUjE2OVoyanpBd3hZSUZxM3NIZTh6dzY=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'D1r+5Dnm5RDLg6IXUuBdsA=='
		// 					},
		// 					metadata: {
		// 						date: 1706809896410,
		// 						uploadedBy: 'system'
		// 					}
		// 				}
		// 			],
		// 			drop: {
		// 				password: '7cRmNun5mR169Z2jzAwxYIFq3sHe8zw6',
		// 				id: '0c312e75a9cc45a3bca91ee9f398b63c',
		// 				username: 'UTKMzQR7m5PVe2yy'
		// 			}
		// 		},
		// 		{
		// 			id: '526745a9-0f55-4bf7-a9c4-5b0535efbae8',
		// 			active: true,
		// 			name: '470bb307-635e-439e-8816-c57dcff89259|2024-02-01|18:36',
		// 			owner: 'be9bdc3d-4d67-4c23-970d-4c7a432649dd',
		// 			queue: '',
		// 			workflow: {
		// 				id: '2f5201ae94614c51b69b4eea1ebb939a',
		// 				name: 'DBI1'
		// 			},
		// 			accepted: true,
		// 			status: 'action',
		// 			token:
		// 				'AQCAAAAAKgAAAAMAAAAAAAAAASuRkJIq7S/DZ+2TxaTXxDv7Dj/GUUO4SYw7ceq6yGpMOCvcvs61n1EV+nSQ/KYcaW2Bqi4eWJTIY+iRTTQwcTszCB544QHrso+WiO5ka9E=V76uuuH2kARAPJqD1VGthqtYW5IEtVWRj9bmIckyoWxTMIJ2uvje1QVa16lFEPZ/TNj5xNOZ8Z7b8+B4ypwdW4I8hiUCUm3Po2Gdim+hEcn/LxQ7IkTxH+Dc36Nqs2/rR0UllOB6rQYrjVTAYaE5NdpHpBWJq8zqipRx9bMT7cFne891Kq8CNqF9sRagynMG0M4P/NHvmH2342OdDKt0ckK/pv+Gt9eeJ/tlE9Z64Qwa2sd7o5xx8U+za7BbeyDXP4n1dJgMlJaCgKBY+Acj6dl/RTk3WgkJVve32lDPpUFI3gIIfoI0g8w07VeifyJzlFBW2FU1Ae804JFFhqZtGWypEjPEAmbkfR4BhiLgaLWBz8VuVoOGTM4ZBFJ2qR97Avr1CoqujPxafoR4o/iYbkvJFXPGZ1QiN4bUbW1tpNsYaF/tk1SOgtzqw5P7q0Sokm3It/oqP81I0l0Gu2zl1txnUDyEf3t85R+sSJdzZ5+ANRyjsrZp8H0c8m3JaGq/bouxV8hq2tuvL+TPGOsy',
		// 			nodeID: 'c1dc0cea85254d18b593bdf75693c37b',
		// 			nodeNamespace: 'PeopleUser',
		// 			returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
		// 			createdAt: 1706812570955,
		// 			updatedAt: 1706812595574,
		// 			history: [],
		// 			notes: [],
		// 			attachments: [
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/470bb307-635e-439e-8816-c57dcff89259?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183559Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=402626712550e827de601d77b95c741eaedb83ae8927050e31da04f78d3343a5&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/51dbf494-0049-478f-ab17-71286baee5ba?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183601Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=be284ad7b60c7f4adfe47b0ecf509af7731372a9ec98b72b5f09bed2c5ee840b&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/b5643f4a-b392-4a15-ad87-62e6921694eb?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183602Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=f94c5d5baac70ded703512edde43ea35526a7ecb7e221a011d29e4748220c6de&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/06c25fc2-c633-4f88-9be1-adb2673fde40?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183603Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=4f38898e49522956cdbdd14824e15f8fb5736e6d008c651e206237fc7455e7d0&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/509ca932-fc0d-4a0a-9ec8-05027c347539?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183603Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=27dcd8267531c1204d08cc3d8ede7ca46ea7800fac8aec24906ee2956e9fb035&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/2f84bde8-3248-4e26-8ce1-773ecef06eb8?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183605Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=a46d937e320fb817854bd9a6f0e0e891d3e267a2b60003652a3d39bef3ae6c3f&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/b1db055a-4f5c-4b05-abbe-dcbecc8bf1bf?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183606Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=d2bfddfa4fa5970c3a1d8f3fccf742ad5afaaaadff47df9c71247ac7ff1b1612&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				},
		// 				{
		// 					url:
		// 						'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b84813c780154de0b3a7070c25458637/cdb88d53-c26a-4245-be37-093992f54103?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBB5I2ZUM6%2F20240201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240201T183607Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=38062127a997527a55180ddd054116e59fdb4bb860ad9a6d74786499e4a2d55f&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDISFEaoAXeWyRhRNk6PVqbfq1DAZTSuPhZbDzghJ7bLAIhAKBXM5nY8%2BPVAEksF%2F1EGulCOFCOvF3n8J2tXZ4RQGNqKosECEwQBBoMMDgyMDI2NTc2MDY2IgxT3irOHTSKLRgNpQMq6ANmVPRkjMqH6xy0ngkTbcoUSDDxT18zzXUurBLcalOuWCx5vyZO6jTevB9coLEDzx16SALL3lrupXJsLjc5bnhv0TA%2FlQch9TcEpLz8pr3DBRH6IhvwjQNP9Kfr2Hj6BVegYIVnKvaWBCFmOtjKdTOlnYTkCLb9Bduh2rsqheA3O45QLy9O%2F8G5eqUSGxBpjPW4eWrCTqQ%2BAigy9icBtyBcPZtG2H7OKuMnaJGXpJkUIZpSF%2B%2B1C%2FZfsKxtHUmH9B7BjPeGCV3U3HcStgNuIcp%2BcVNBNOO94XeGT2N%2BT4a0MkTq5dEKPmc3vraUYV0jCGY2Z0W4m9Vd6BNb%2Bm7sUVJ%2F07k9kElxtoh862Kid57DYkadq9V9rXmFZArnbLOGLeOkmiZwXjUvceEbYjfY0LP%2BSphbEEIhVnoporkrolS4nqwyKHKLpiNvcFFj%2F13Y0cJ6X3WpAbopeEBqH0Ra4iDXykHb3yTQQ1nuOVnYtuiKflqjUzsbrWovbQjFbUm9YDg4hiAJuwk6OCZXPjONE5YWfUM9NJq4f4%2FLtCPPXsgnuEFojVC50MaQaLmzDNtVyDTMv%2FMhODSFZalh650L7i8jkuu4Y1X7kAuBEqaJr9rnWCLCu6qJDMD4G8%2BVFPlEIJO0IAcX30L%2B5jDxx%2B%2BtBjqkAY7bQWkupB%2FhQVQ7OaLOGgytbhh%2FXREREits7euZL4gdFdeKawsYTtTiy9NBoVPGTfn2CxPm9%2FIQ47ASCoFJr5zJ1vPMmEjhvH495YeWePz2ipB%2FOHx54mECrmnGh555f7p7%2BqS5mSP4BsBWQzBOglrev4N8kq3%2BiGGs%2F%2FWQYIKrbDpNDe11ODof9E7lb6xV5lcRvz3E%2BdGLlBpc9I%2Bx5VBOF0su',
		// 					headers: {
		// 						'x-amz-server-side-encryption-customer-algorithm': 'AES256',
		// 						'x-amz-server-side-encryption-customer-key':
		// 							'c1ViOVJ3QkU4eGZjSGRwUTZ5ZEhpdDVBZTZGdzRzZmw=',
		// 						'x-amz-server-side-encryption-customer-key-md5': 'ilEHUFs1Gvvxx3BIn+BPRw=='
		// 					},
		// 					metadata: {
		// 						date: 1706812569499,
		// 						uploadedBy: 'system'
		// 					}
		// 				}
		// 			],
		// 			drop: {
		// 				password: 'sUb9RwBE8xfcHdpQ6ydHit5Ae6Fw4sfl',
		// 				id: 'b84813c780154de0b3a7070c25458637',
		// 				username: '2WL8fCAEL3TZVTIK'
		// 			}
		// 		}
		// 	],
		// 	count: 7
		// };

		// mock.onGet(/\/people\/job/).reply(config => {
		// 	// Check if jobID is provided
		// 	if (config.params && config.params.jobID) {
		// 		// Find the job with the matching jobID
		// 		const job = mockJobs.jobs.find(j => j.id === config.params.jobID);

		// 		if (job) {
		// 			return [200, { job }];
		// 		}
		// 		return [404, { message: 'Job not found' }];
		// 	}

		// 	return [400, { message: 'jobID is required' }];
		// });

		// mock.onGet(/\/people\/jobs\/user/).reply((config: AxiosRequestConfig<any>) => {
		// 	return [200, mockJobs];
		// });

		const formsApiUrl = getFormsUrl(user.awsRegion);
		mock.onGet(`${formsApiUrl}/api/groups`).reply(200, [
			{
				id: '4976d6b2fccd40ce9b825c1eb12a5434',
				title: 'form-group-2',
				sortOrder: 2
			},
			{
				id: 'eabdc55208d842619bccb6397680930a',
				title: 'group-2',
				sortOrder: 3
			}
		]);

		mock.onGet(`${formsApiUrl}/api/forms`).reply(200, [
			{
				id: '1a2b3c4d5e6f7g8h',
				title: 'Project X',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696000000000,
				updated: 1696100000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '2b3c4d5e6f7g8h9i',
				title: 'Task Management 2',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696100000000,
				updated: 1696200000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c4d5e6f7g8h9i0j',
				title: 'Product Launch 3',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696200000000,
				updated: 1696300000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '4d5e6f7g8h9i0j1k',
				title: 'Meeting Notes 4',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696300000000,
				updated: 1696400000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '5e6f7g8h9i0j1k2l',
				title: 'Expense Report 5',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696400000000,
				updated: 1696500000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '6f7g8h9i0j1k2l3m',
				title: 'Project Y',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696500000000,
				updated: 1696600000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '7g8h9i0j1k2l3m4n',
				title: 'Survey Results 7',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696600000000,
				updated: 1696700000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '8h9i0j1k2l3m4n5o',
				title: 'Marketing Campaign 8',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696700000000,
				updated: 1696800000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c58f69b470f42178b77d9249e2f3989',
				title: 'nmassey',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1695312048179,
				updated: 1695312065423,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: 'b555c9e7f8fa41dfbc5a79683e22dd43',
				title: 'Test 1',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1694832174280,
				updated: 1694832778817,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			}
		]);
	}

	if (localStorage.getItem('mock') || localStorage.getItem('mockEntitlements')) {
		mock.onGet(`/api/user/entitlements`).reply(200, {
			permissions,
			features: selectedLicenseGroup.plan === 'stc' ? {} : features
		});
	}

	if (localStorage.getItem('mock') || localStorage.getItem('mockApps')) {
		mock.onGet('/api/app').reply(200, apps);
	}

	const mockRegion = localStorage.getItem('mockRegion') ?? (localStorage.getItem('mock') ? 'US' : undefined);
	if (mockRegion) {
		mock.onGet('https://static.getpersonalize.com/geolocation').reply(200, mockRegion);
	}

	mock.onPatch(`/api/v1/tenant/${selectedLicenseGroup.id}/settings`).reply(200);
	mock.onPatch(`/api/v1/tenant/${selectedLicenseGroup.id}/copy`).reply(200);
	mock.onPatch(`/api/v1/tenant/${selectedLicenseGroup.id}/upgrade-demo-license`).reply(config => {
		const { orderNumber } = JSON.parse(config.data);
		// Odd/even check to simulate success/failure, until we have a real API
		if (orderNumber % 2 === 0) {
			return [200, { message: 'Success: (orderNumber is even)' }];
		}
		return [400, { message: 'Error: orderNumber is not even' }];
	});

	mock.onPost('/api/v1/invitation').reply(200, {
		invitationId: 'mock-invitation-id'
	});

	mock.onPost('/api/v1/invitation/mock-invitation-id/link').reply(200, {
		transactionId: 'mock-transaction-id'
	});

	mock.onPatch(/\/api\/v1\/user\/[^/]+/).reply(200, []);

	mock.onPost(/\/api\/v1\/user/).reply(200, []);

	mock.onPatch(/\/api\/v1\/devicegroup\/[^/]+\/[^/]+/).reply(200, []);

	mock.onPatch(/\/api\/v1\/device\/[^/]+\/[^/]+/).reply(200, []);

	mock.onPatch('/api/license-groups/mock-admin-invite/devices').reply(200);

	mock.onPost(`/api/v1/tenant/${selectedLicenseGroup.id}/registration`).reply(200, []);

	mock.onGet('/api/v1/user/changeMeId').reply(200, users);

	mock.onGet(/\/api\/v1\/workflow\/[^/]+\/[^/]+\/logs/).reply(200, [
		{
			id: 'log-w-1',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110800000,
			event: 'Workflow submitted',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			id: 'log-w-2',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110600000,
			event: 'Workflow stopped',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			id: 'log-w-3',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110400000,
			event: 'Workflow edited',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			id: 'log-w-4',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110200000,
			event: 'Workflow started',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		}
	]);

	if (localStorage.getItem('mockUser')) {
		mock.onGet('/api/sso/session').reply(200, {
			user
		});
		// mock.onGet('/api/sso/session').reply(401, null);
	}

	// mock.onGet('/api/v1/user/changeMeId/mock-user-id/devices').reply(200, [
	// mock.onGet(/\/api\/v1\/user\/changeMeId\/[^/]+\/devices/).reply(200, _.keyBy(mpDevices, 'id'));

	// mock.onGet('/api/v1/user/changeMeId/mock-user-id/devices').reply(200, [
	mock.onGet(/\/api\/v1\/user\/changeMeId\/[^/]+\/forms/).reply(200, [
		{
			id: 'mock-mp-form-guid-1',
			name: 'C558',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-1',
			friendlyName: 'Second Floor - Dev'
		},
		{
			id: 'mock-mp-form-guid-2',
			name: 'C556',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-2',
			friendlyName: 'Second Floor - QA'
		},
		{
			id: 'mock-mp-form-guid-3',
			name: 'C554',
			family: 'VenusMLK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-3',
			friendlyName: 'Second Floor - Lab'
		},
		{
			id: 'mock-mp-form-guid-4',
			name: 'C358',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-4'
		},
		{
			id: 'mock-mp-form-guid-5',
			name: 'C356',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-5'
		},
		{
			id: 'mock-mp-form-guid-6',
			name: 'C354',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-6'
		},
		{
			id: 'mock-mp-form-guid-7',
			name: 'C666',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-7'
		}
	]);

	const alertSubscriptions = [
		{
			id: 'mock-report-device-status-alert-subscription-1',
			logsubId: 'mock-report-device-status-alert-subscription-1',
			userId: user.id,
			type: 'report-device-status',
			severity: '',
			alertEmail: [user.email],
			parameters: {
				reportTime: '03:35',
				assessedNotSecure: true,
				assessedOffline: true,
				notAssessed: true
			},
			alertCell: [],
			createdAt: 1592659304896,
			updatedAt: 1592659304896
		},
		{
			id: 'mock-alert-subscription-1',
			logsubId: 'mock-alert-subscription-1',
			userId: user.id,
			type: 'device',
			severity: 'high',
			alertEmail: [user.email],
			alertCell: [],
			createdAt: 1592659304896,
			updatedAt: 1592659304896
		}
	];

	// api/v1/alert
	mock.onGet(/\/api\/v1\/alert\/changeMeId\/user\/[^/]+\/subscriptions/).reply(
		200,
		_.keyBy(alertSubscriptions, 'id')
	);

	// mock.onPatch('/api/license-groups/changeMeId/users').reply(200, {
	// 	errors: {
	// 		add: [
	// 			{ type: 'already-associated', data: { email: 'fake1@email.com' } },
	// 			{ type: 'already-associated', data: { email: 'fake2@email.com' } }
	// 		],
	// 		delete: []
	// 	}
	// });
	mock.onPatch('/api/license-groups/changeMeId/users').reply(200, {
		errors: { add: [], delete: [] }
	});
	mock.onPatch('/api/license-groups/changeMeId/devices').reply(200, { success: 'true' });
	mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(401, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, {
	// 	errors: [
	// 		{
	// 			output: {
	// 				statusCode: 400
	// 			}
	// 		}
	// 	]
	// });

	if (localStorage.getItem('mock')) {
		mock.onGet(`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.EnterpriseMonthly}`).reply(
			200,
			{
				total: 5 * 50,
				currencyCode: 'USD'
			}
		);

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.EnterpriseMonthly}&quantity=50`
		).reply(200, {
			total: 5 * 50,
			currencyCode: 'USD'
		});

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.EnterpriseMonthly}&quantity=60`
		).reply(200, {
			total: 5 * 60,
			currencyCode: 'USD'
		});

		mock.onGet(`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.BusinessMonthly}`).reply(
			200,
			{
				total: 4 * 50,
				currencyCode: 'USD'
			}
		);

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.BusinessMonthly}&quantity=50`
		).reply(200, {
			total: 4 * 50,
			currencyCode: 'USD'
		});

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.BusinessMonthly}&quantity=60`
		).reply(200, {
			total: 4 * 60,
			currencyCode: 'USD'
		});

		mock.onGet(`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.StarterMonthly}`).reply(
			200,
			{
				total: 3 * 50,
				currencyCode: 'USD'
			}
		);

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.StarterMonthly}&quantity=50`
		).reply(200, {
			total: 3 * 50,
			currencyCode: 'USD'
		});

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.StarterMonthly}&quantity=60`
		).reply(200, {
			total: 3 * 60,
			currencyCode: 'USD'
		});

		mock.onPatch(/\/api\/tenants\/[^/]+\/configure/).reply(200);

		// People Api Mocks
		const peopleApiUrl = `${getNodeTestingAPI('us-east-1')}/people`;
		mock.onGet(`${peopleApiUrl}/jobs/user`).reply(200, {
			jobs: [
				{
					id: 'j1j1j1j1',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'workflowName:2023-10-2:2f2f3',
					owner: user.id,
					status: 'action',
					workflow: 'workflow-9'
				},
				{
					id: 'j2j2j2j2',
					active: true,
					createdAt: 1699999179,
					updatedAt: 1699999179,
					name: 'workflow2:2023-10-2:abcd',
					owner: user.id,
					status: 'action',
					workflow: 'workflow-11'
				},
				{
					id: 'j4j4j4j4',
					active: true,
					createdAt: 1699999179,
					updatedAt: 1699999179,
					name: 'MyWorkflow2:2023-11-19:ss2',
					owner: user.id,
					status: 'action',
					workflow: 'workflow-11'
				},
				{
					id: '753a60ee-1f4d-45e1-81c8-a34c71c05426',
					active: true,
					name: "Sara's People Workflow:2024-0-2:05426",
					owner: user.id,
					queue: '',
					workflow: '6abb4f3ccc174cfc86b352c9fc6c30e8',
					accepted: false,
					status: 'action',
					token:
						'AQCAAAAAKgAAAAMAAAAAAAAAAaTl3uRTPt5w3WSbOpexIqhf/JlUsPZ+Tb7fs/bOGFfQTb61OSUm8tFRlJi8CVyshZNIZrhc9eLsHbDfU/AAD3fq2p/VEQduSiJP/ltri+M=yFrnWabwo2p3OZ+VS0eykyHueXDzmLf9tS6x3fPTq2faH89aW7X6lUjS/nP8if9N9ngzYZi7CaxYVUCkcNfyglTbGWccgGZ/O1zmWXs7I+zPxuc5eHOaTSWVJopP1nFfT25XTSMCDyaVxEcGP4VLfDokaGjFCxJ3MuM3CnnEqjiUyOB3ky6QMvWKzeQDEVUBt5hN5tFYdIAW+bl3nv/Sp5vqO9QJTCPlMKB7sdzJHOmOMz0RN8cwba5nOqAr1VconmGlZNrS6hD2UxUBqvbPYYfc2pEACMF9UR/CsIbohrZxydGH4fhhC0Mknv/CLc17c5AWpIPrQnem8EqOyQWzZjQdr8VtA1cgACbWviRDL/Snpz1Mw+sMUSTJkJE4JrV9FQleV0GP8eDNG/oAqGx+vDNR0IOS1s6OJf2tnGH4VCIUolj8QHpaYCPGnYmXHK+tAEVwb4nOkai4d2kymJXc6cjrWc9kw9xyjJhYGHAHGA+fdYmsok+gsFm2dJAIes5K7vNEFtA8RvWTrTghjEwv',
					nodeID: 'c398f1c0f9214a73ada32facc0d240b3',
					nodeNamespace: 'PeopleGroup',
					returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
					groupID: '60cb476d-a849-4879-81a6-d65c5a5dbecd',
					createdAt: 1704836165836,
					updatedAt: 1704836165836
				}
			],
			count: 3
		});

		mock.onGet(`${peopleApiUrl}/queues/user`).reply(200, {
			queues: [
				{
					id: 'q1q1q1q1',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'Queue 1',
					members: [user.id, users[2].id]
				},
				{
					id: 'q2q2q2q2',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'Queue 2',
					members: [user.id, users[1].id]
				},
				{
					id: 'q3q3q3q3',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'Queue 3',
					members: [user.id]
				}
			],
			count: 3
		});
		mock.onGet(`${peopleApiUrl}/queues`).reply(200, {
			queues: [
				{
					id: 'q1q1q1q1',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'queue 1',
					groupID: 'userGroup-1',
					members: [user.id, users[2].id]
				},
				{
					id: 'q2q2q2q2',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'Queue 2',
					members: [user.id, users[1].id]
				},
				{
					id: 'q3q3q3q3',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'queue 3',
					members: [user.id]
				},
				{
					id: 'q4q4q4q4',
					active: false,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'Queue 4',
					members: [users[1].id, users[2].id]
				},
				{
					id: 'q5q5q5q5',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'Queue 5',
					members: [users[2].id, users[3].id]
				},
				{
					id: 'q6q6q6q6',
					active: false,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'Queue 6',
					members: [users[4].id, users[5].id, users[6].id]
				}
			],
			count: 6
		});

		mock.onGet(`${peopleApiUrl}/jobs/queue`).reply(config => {
			switch (config.params.queueID) {
				case 'q1q1q1q1':
					return [
						200,
						{
							jobs: [
								{
									id: '9a654bc1-6226-49fb-b7fe-da693a639166',
									active: true,
									name: 'cristian queue test:2024-0-2:39166',
									owner: '02f8a272-6701-4298-a327-6b6afaa52b97',
									queue: 'q1q1q1q1',
									workflow: 'ae18bc2d74974cf1bede66f0726076be',
									accepted: true,
									status: 'action',
									token:
										'AQCAAAAAKgAAAAMAAAAAAAAAAWErCSzM7XnKyRivOuRJFyXibLJFWVW3mRU5HCUHZTOG5PsvtEsPneH5YMzNjIqvk8za1t+4ZQfCxUY+zGrGCr9j6WPHhPeiPkcFu7XbdaE=cSKvC36jZaq18fKmH4Mc8bpp+zCBof7VPv68wSRyGdEbjI7A7OrIqpPQKlL9QJRC1+7eygm2EcB+9JiNicjRBQ0CBD4d8Jlok2Nx+4WfxGMamxcMuLMD2vr+WkGrlam6zCFatrvlrEn62QyDkmo49uG/dS0tci1A+VYGHUybcuk8B7m5suamKskg8ysWeHt0hoPVO3LWWC9olbQJ9MjYBgTs2xhNVZ+/6u9jneQtihM7rpYQ/P9BK2g66jR7GBWyBewuVAY53W0FkI+lGuiJh3KXxMKUBS8IOW7ZeSlmpXitTxyozTF4bGDKbXHIWB0hHa0/CXwJMVz0Ou8usD5pK8Y+yR629H/wjt5OowHje/Kd2hpDL8/O86WBq4zQSs06ZEduNlK8hubdSxOfOuTalQGTN8bTuavZukuUtYl8lsript6bFYFwZaYo4sHlxX30GWjVn1owlgh1aj7uOTrokq7QIbRCnQwXLrUS/R486vDZw5LjKExyIWw1jKLeiu48tejzzxjD5hzV+Dn86h4b',
									nodeID: '8051a394b73a4a2d8f61910fc11e6095',
									nodeNamespace: 'PeopleQueue',
									returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
									createdAt: 1704829398770,
									updatedAt: 1704832022011
								},
								{
									id: 'j1j1j1j1',
									active: true,
									createdAt: 1699989179,
									updatedAt: 1699989179,
									name: 'workflowName:2023-10-2:2f2f3',
									owner: user.id,
									queue: 'q1q1q1q1',
									status: 'action',
									workflow: 'workflow-9'
								},
								{
									id: 'j2j2j2j2',
									active: true,
									createdAt: 1699999179,
									updatedAt: 1699999179,
									name: 'workflow2:2023-10-2:abcd',
									owner: '',
									queue: 'q1q1q1q1',
									status: 'action',
									workflow: 'workflow-11'
								},
								{
									id: 'j3j3j3j3',
									active: true,
									createdAt: 1699999179,
									updatedAt: 1699999179,
									name: 'MyWorkflow:2023-12-19:ss1',
									owner: users[2].id,
									queue: 'q1q1q1q1',
									status: 'action',
									workflow: 'workflow-13'
								}
							],
							count: 2
						}
					];
				case 'q2q2q2q2':
					return [
						200,
						{
							jobs: [
								{
									id: 'q2j1j1j1j1',
									active: true,
									createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
									updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
									name: 'Queue 2 Job 1',
									queue: 'q2q2q2q2',
									status: 'action',
									workflow: 'workflow-11'
								},
								{
									id: 'q2j2j2j2j2',
									active: true,
									createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
									updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
									name: 'Queue 2 Job 2',
									queue: 'q2q2q2q2',
									status: 'verification',
									workflow: 'workflow-11'
								}
							],
							count: 2
						}
					];
				default:
					return [200, { jobs: [], count: 0 }];
			}
		});

		mock.onGet(`${peopleApiUrl}/job`).reply(config => {
			switch (config.params.jobID) {
				case 'j1j1j1j1':
					return [
						200,
						{
							job: {
								id: 'j1j1j1j1',
								active: true,
								createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
								updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
								accepted: false,
								name: 'workflowName:2023-10-2:2f2f3',
								owner: user.id,
								status: 'action',
								workflow: 'workflow-9',
								nextSteps: [
									{
										node: {},
										name: 'Cristian Test',
										transition: 'Named_2b4099b408b648efbe632662006e691e'
									},
									{
										node: {},
										name: 'test queue',
										transition: 'Named_5e93117311f943ce9362e5d50bc39cdc'
									}
								],
								nodeConfig: {
									queue: 'd1a44ab3-5bbd-40bd-a627-abff19aff834',
									group: '60cb476d-a849-4879-81a6-d65c5a5dbecd',
									workflowEngine: {
										nextStepName: "cristian's queue"
									}
								},
								history: [
									{
										id: 'e1e1e1e1',
										createdAt: new Date('10/12/2023 10:34:21 AM').getTime(),
										event: { type: 'completed' },
										source: { type: 'system', id: 'System' }
									},
									{
										id: 'e2e2e2e2',
										createdAt: new Date('10/12/2023 10:34:03 AM').getTime(),
										event: { type: 'accepted' },
										source: { type: 'user', id: users[1].id }
									},
									{
										id: 'e3e3e3e3',
										createdAt: new Date('10/10/2023 5:14:33 PM').getTime(),
										event: { type: 'reassigned', to: { type: 'user', id: users[1].id } },
										source: { type: 'system', id: 'System' }
									},
									{
										id: 'e4e4e4e4',
										createdAt: new Date('10/10/2023 2:56:14 PM').getTime(),
										event: { type: 'fileAttached' },
										source: { type: 'user', id: user.id }
									},
									{
										id: 'e5e5e5e5',
										createdAt: new Date('10/09/2023 8:38:06 AM').getTime(),
										event: { type: 'accepted' },
										source: { type: 'system', id: 'System' }
									}
								],
								notes: [
									{
										id: 'n1n1n1n1',
										createdAt: new Date('10/06/2023 12:34:15 pm').getTime(),
										note: 'some note',
										author: user.id,
										private: false
									},
									{
										id: 'n2n2n2n2',
										createdAt: new Date('10/03/2023 4:22:56 pm').getTime(),
										note:
											'Pellentesque aliquam est vitae lorem gravida lacinia. Nullam at ex eget lorem mattis tincidunt. Aenean vitae risus eget augue faucibus ullamcorper at ac magna. Donec tempus augue a justo scelerisque scelerisque. Vestibulum suscipit maximus vestibulum. Integer sed placerat felis. Phasellus turpis ipsum, dapibus aliquam aliquet vel, tristique ac risus. Ut lectus sem, rutrum et mattis vel, eleifend sit amet odio. Sed vel malesuada lacus. Quisque rutrum justo elit, sit amet cursus justo facilisis non. Quisque vitae tortor vel nibh bibendum feugiat.',
										author: user.id,
										private: true
									},
									{
										id: 'n3n3n3n3',
										createdAt: new Date('10/02/2023 11:54:08 am').getTime(),
										note:
											'Pellentesque eget augue neque. Sed erat urna, cursus et imperdiet id, condimentum et sem. Phasellus efficitur malesuada tempus. Ut id magna pharetra erat convallis finibus. Nam quis enim quis dui aliquet sodales vel et ex. Vivamus sem orci, maximus eu commodo eu, fermentum ut metus. In molestie magna sed lacus imperdiet pretium. Nam ut iaculis tellus. Cras id risus dictum, venenatis est quis, elementum lorem. Etiam id leo scelerisque, efficitur leo et, scelerisque diam. Proin a tellus velit. Nam a arcu cursus justo varius pulvinar. Quisque sagittis, nibh ut volutpat sodales, erat nisi efficitur massa, in pellentesque enim enim rutrum eros. Vivamus sit amet nisl non tellus imperdiet faucibus et vel dui.',
										author: user.id,
										private: false
									}
								],
								attachments: [
									{
										url: 'lo-and-behold.PNG',
										metadata: {
											ext: '.png',
											fullname: 'lo-and-behold.PNG',
											name: 'lo-and-behold',
											size: '10 KB',
											date: new Date('10/02/2023 11:54:08 am').getTime(),
											uploadedBy: user.id
										}
									},
									{
										url: 'Bla-bla-bla.PDF',
										metadata: {
											ext: '.pdf',
											fullname: 'Bla-bla-bla.PDF',
											name: 'Bla-bla-bla',
											size: '15 KB',
											date: new Date('10/01/2023 10:32:54 am').getTime(),
											uploadedBy: user.id
										}
									}
								]
							}
						}
					];
				case 'j2j2j2j2':
					return [
						200,
						{
							job: {
								id: 'j2j2j2j2',
								active: true,
								createdAt: 1699989179,
								updatedAt: 1699989179,
								accepted: false,
								name: 'workflow2:2023-10-2:abcd',
								owner: user.id,
								status: 'action',
								workflow: 'workflow-11',
								nextSteps: [
									{
										node: {},
										name: 'Cristian Test',
										transition: 'Named_2b4099b408b648efbe632662006e691e'
									},
									{
										node: {},
										name: 'test queue',
										transition: 'Named_5e93117311f943ce9362e5d50bc39cdc'
									}
								],
								nodeConfig: {
									queue: 'd1a44ab3-5bbd-40bd-a627-abff19aff834',
									group: '60cb476d-a849-4879-81a6-d65c5a5dbecd',
									workflowEngine: {
										nextStepName: "cristian's queue"
									}
								},
								history: [
									{
										id: 'e1e1e1e1',
										createdAt: new Date('10/12/2023 10:34:21 AM').getTime(),
										event: { type: 'completed' },
										source: { type: 'system', id: 'System' }
									},
									{
										id: 'e2e2e2e2',
										createdAt: new Date('10/12/2023 10:34:03 AM').getTime(),
										event: { type: 'accepted' },
										source: { type: 'user', id: users[1].id }
									},
									{
										id: 'e3e3e3e3',
										createdAt: new Date('10/10/2023 5:14:33 PM').getTime(),
										event: { type: 'reassigned', to: users[1].id },
										source: { type: 'system', id: 'System' }
									},
									{
										id: 'e4e4e4e4',
										createdAt: new Date('10/10/2023 2:56:14 PM').getTime(),
										event: { type: 'fileAttached' },
										source: { type: 'user', id: user.id }
									},
									{
										id: 'e5e5e5e5',
										createdAt: new Date('10/09/2023 8:38:06 AM').getTime(),
										event: { type: 'accepted' },
										source: { type: 'system', id: 'System' }
									}
								],
								notes: [
									{
										id: 'n1n1n1n1',
										createdAt: new Date('10/06/2023 12:34:15 pm').getTime(),
										note: 'some note',
										author: user.id,
										private: false
									},
									{
										id: 'n2n2n2n2',
										createdAt: new Date('10/03/2023 4:22:56 pm').getTime(),
										note:
											'Pellentesque aliquam est vitae lorem gravida lacinia. Nullam at ex eget lorem mattis tincidunt. Aenean vitae risus eget augue faucibus ullamcorper at ac magna. Donec tempus augue a justo scelerisque scelerisque. Vestibulum suscipit maximus vestibulum. Integer sed placerat felis. Phasellus turpis ipsum, dapibus aliquam aliquet vel, tristique ac risus. Ut lectus sem, rutrum et mattis vel, eleifend sit amet odio. Sed vel malesuada lacus. Quisque rutrum justo elit, sit amet cursus justo facilisis non. Quisque vitae tortor vel nibh bibendum feugiat.',
										author: user.id,
										private: true
									},
									{
										id: 'n3n3n3n3',
										createdAt: new Date('10/02/2023 11:54:08 am').getTime(),
										note:
											'Pellentesque eget augue neque. Sed erat urna, cursus et imperdiet id, condimentum et sem. Phasellus efficitur malesuada tempus. Ut id magna pharetra erat convallis finibus. Nam quis enim quis dui aliquet sodales vel et ex. Vivamus sem orci, maximus eu commodo eu, fermentum ut metus. In molestie magna sed lacus imperdiet pretium. Nam ut iaculis tellus. Cras id risus dictum, venenatis est quis, elementum lorem. Etiam id leo scelerisque, efficitur leo et, scelerisque diam. Proin a tellus velit. Nam a arcu cursus justo varius pulvinar. Quisque sagittis, nibh ut volutpat sodales, erat nisi efficitur massa, in pellentesque enim enim rutrum eros. Vivamus sit amet nisl non tellus imperdiet faucibus et vel dui.',
										author: user.id,
										private: false
									}
								],
								attachments: [
									{
										url: 'lo-and-behold.PNG',
										metadata: {
											ext: '.png',
											fullname: 'lo-and-behold.PNG',
											name: 'lo-and-behold',
											size: '10 KB',
											date: new Date('10/02/2023 11:54:08 am').getTime(),
											uploadedBy: user.id
										}
									},
									{
										url: 'Bla-bla-bla.PDF',
										metadata: {
											ext: '.pdf',
											fullname: 'Bla-bla-bla.PDF',
											name: 'Bla-bla-bla',
											size: '15 KB',
											date: new Date('10/01/2023 10:32:54 am').getTime(),
											uploadedBy: user.id
										}
									}
								]
							}
						}
					];
				case 'j3j3j3j3':
					return [
						200,
						{
							job: {
								id: 'j3j3j3j3',
								active: true,
								createdAt: 1699989179,
								updatedAt: 1699989179,
								accepted: false,
								name: 'MyWorkflow:2023-12-19:ss1',
								owner: users[2].id,
								status: 'action',
								workflow: 'workflow-13',
								history: [],
								notes: [],
								attachments: [],
								nextSteps: [
									{
										node: {},
										name: 'Cristian Test',
										transition: 'Named_2b4099b408b648efbe632662006e691e'
									},
									{
										node: {},
										name: 'test queue',
										transition: 'Named_5e93117311f943ce9362e5d50bc39cdc'
									}
								],
								nodeConfig: {
									queue: 'd1a44ab3-5bbd-40bd-a627-abff19aff834',
									group: '60cb476d-a849-4879-81a6-d65c5a5dbecd',
									workflowEngine: {
										nextStepName: "cristian's queue"
									}
								}
							}
						}
					];
				case 'j4j4j4j4':
					return [
						200,
						{
							job: {
								id: 'j4j4j4j4',
								active: true,
								createdAt: 1699989179,
								updatedAt: 1699989179,
								accepted: false,
								name: 'MyWorkflow2:2023-11-19:ss2',
								owner: user.id,
								status: 'action',
								workflow: 'workflow-13',
								nextSteps: [
									{
										node: {},
										name: 'Cristian Test',
										transition: 'Named_2b4099b408b648efbe632662006e691e'
									},
									{
										node: {},
										name: 'test queue',
										transition: 'Named_5e93117311f943ce9362e5d50bc39cdc'
									}
								],
								nodeConfig: {
									queue: 'd1a44ab3-5bbd-40bd-a627-abff19aff834',
									group: '60cb476d-a849-4879-81a6-d65c5a5dbecd',
									workflowEngine: {
										nextStepName: "cristian's queue"
									}
								},
								history: [],
								notes: [],
								attachments: []
							}
						}
					];
				case '753a60ee-1f4d-45e1-81c8-a34c71c05426':
					return [
						200,
						{
							job: {
								id: '753a60ee-1f4d-45e1-81c8-a34c71c05426',
								active: true,
								name: "Sara's People Workflow:2024-0-2:05426",
								owner: user.id,
								queue: '',
								workflow: '6abb4f3ccc174cfc86b352c9fc6c30e8',
								accepted: false,
								status: 'action',
								token:
									'AQCAAAAAKgAAAAMAAAAAAAAAAaTl3uRTPt5w3WSbOpexIqhf/JlUsPZ+Tb7fs/bOGFfQTb61OSUm8tFRlJi8CVyshZNIZrhc9eLsHbDfU/AAD3fq2p/VEQduSiJP/ltri+M=yFrnWabwo2p3OZ+VS0eykyHueXDzmLf9tS6x3fPTq2faH89aW7X6lUjS/nP8if9N9ngzYZi7CaxYVUCkcNfyglTbGWccgGZ/O1zmWXs7I+zPxuc5eHOaTSWVJopP1nFfT25XTSMCDyaVxEcGP4VLfDokaGjFCxJ3MuM3CnnEqjiUyOB3ky6QMvWKzeQDEVUBt5hN5tFYdIAW+bl3nv/Sp5vqO9QJTCPlMKB7sdzJHOmOMz0RN8cwba5nOqAr1VconmGlZNrS6hD2UxUBqvbPYYfc2pEACMF9UR/CsIbohrZxydGH4fhhC0Mknv/CLc17c5AWpIPrQnem8EqOyQWzZjQdr8VtA1cgACbWviRDL/Snpz1Mw+sMUSTJkJE4JrV9FQleV0GP8eDNG/oAqGx+vDNR0IOS1s6OJf2tnGH4VCIUolj8QHpaYCPGnYmXHK+tAEVwb4nOkai4d2kymJXc6cjrWc9kw9xyjJhYGHAHGA+fdYmsok+gsFm2dJAIes5K7vNEFtA8RvWTrTghjEwv',
								nodeID: 'c398f1c0f9214a73ada32facc0d240b3',
								nodeNamespace: 'PeopleGroup',
								returnURL: 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev/report-end',
								groupID: 'userGroup-1',
								createdAt: 1704836165836,
								updatedAt: 1704836165836,
								nextSteps: [
									{
										node: {},
										name: 'Email Out',
										transition: 'Normal'
									},
									{
										node: {},
										name: 'End',
										transition: 'Normal'
									},
									{
										node: {},
										name: 'End',
										transition: 'Negative'
									}
								],
								nodeConfig: {
									allowReassignment: true,
									group: '60cb476d-a849-4879-81a6-d65c5a5dbecd',
									sendEmailNotification: false,
									nextStep: true,
									workflowEngine: {
										nextStepName: 'people testing'
									}
								},
								history: [
									{
										id: '91b8e04f-155c-4a16-868c-169199fe2cc2',
										event: {
											type: 'created'
										},
										source: {
											type: 'system',
											id: 'system'
										},
										createdAt: 1704836165905
									},
									{
										id: 'e7755772-0a32-49d4-b51b-e23c309cadba',
										event: {
											type: 'received'
										},
										source: {
											type: 'user',
											id: '02f8a272-6701-4298-a327-6b6afaa52b97'
										},
										createdAt: 1704836166001
									}
								],
								notes: [],
								attachments: [
									{
										url:
											'https://s3.us-east-1.amazonaws.com/lol.stratus.us-east-1.s3-adapter/b06fb58120614c2495638f2aec2ea022/20240109T171734293076.pdf?x-id=GetObject&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARGGJKHDBEZNONBVR%2F20240109%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240109T213558Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host%3Bx-amz-server-side-encryption-customer-algorithm%3Bx-amz-server-side-encryption-customer-key%3Bx-amz-server-side-encryption-customer-key-md5&X-Amz-Signature=c1a698b4a907a08dc3396c36e7de329a20734eb53278026e2d2a89eae5899f68&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEF4aCXVzLWVhc3QtMSJGMEQCIQCgFZnrq3VoK90OwtaTPE2aUGPkDAhWsTi%2FOlFSN7ZYDAIfU8cnLlVXaqGjLEnt53WFaYE3KdMoCJHZ1XmR7LMWoyqUBAj2%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAQaDDA4MjAyNjU3NjA2NiIMS8tEqka7eQn1VwM5KugDqavI5%2FRN%2BgeDMCTMn8OcE%2Bml6im5QFNK8ygROV8Efo1f95Uv%2FCiOAGmJbL%2BGX1wVuDUYh50XMr9a73o3zZOdx%2FjJCv4QzJFVmDr%2B%2Bxa14Py0Z4gx9ulIJAa9AzL5Yy73FBVjeIqKIbetSZt0dYjeTNRAV6Q%2FzQoA4OY68aMX%2F3tYg1ylNRvPQi5XXwuWHNGB1r0IesCxjbrZLaIWG952LH8YvgIsX1WEwWQSxzawAGaCxji%2F%2FxiIT7Zfu4GI37FkolLSiwSkII2R5rjSB%2B3DJRoFOYQRGaekUAU7vhoIGLhnaC2IwB%2FbXfEgF4KksSIBJBjnz%2FkV9un3w6HnGq6e1Bf9Al1zUUA%2Bq6HkBWYtFW1%2Frw2EcTP7qKn60Ovos1m34hn51nprGCmvXCIWPhjUT9JZIO3ham99CXDI6bEohU4AzNw0MvvwmnXv0AWJqWmk3XDovBkbK2ItDUeIGfLQ7q1Y6N34L%2BdXE86JhkzrhID%2FLARnJzHePgjyDUK8PZQ3A94AwY9R4RWgGFz%2BaTGuLdM6uHh8veziIeSPNDzvF2RMwF9Dl9dWCWMoFR5SQzguLqTaGibLD8w5ugw2vdEiTQOnZOYyTtI1BPEN%2BxSMKonyspNVMVziPf616Wk%2BDvxJfjlN%2FvMGMrowve32rAY6pgEJfr%2FMm5K2Bpxo9rU2IJNsmUNkB2FqAsjPw2ZgdssxFiROnCbKoa3kkN%2FyzmO0GU%2B3uX3OhUVrggycXrPTs%2FvBeSMHnHQKIt0mwlmIH%2BFSG7i0JeWnNCMBjcXEHw8k8hBASfKMHVMg0c6%2BGLsjfxlZGW491NXX6BQk6thok8TKrN5B9BMkJSmP3TEFR9pSNHWGU6IttcE5i3Fzy55ZkJF4J6Pcl75J',
										headers: {
											'x-amz-server-side-encryption-customer-key-md5': '/4NdloddRs2b0Ktze2qolw==',
											'x-amz-server-side-encryption-customer-key':
												'SURIMGl2em96ZDZxWTd6dFJPblZ2UTdVaGRhdU5lNUM=',
											'x-amz-server-side-encryption-customer-algorithm': 'AES256'
										},
										metadata: {
											ext: '.pdf',
											name: '20240109T171734293076',
											fullname: '20240109T171734293076.pdf',
											size: 957388,
											date: 1704836165835,
											uploadedBy: 'system'
										}
									}
								]
							}
						}
					];
				default:
					return [404, { job: {} }];
			}
		});

		mock.onGet(`${peopleApiUrl}/inbox`).reply(200, {
			inbox: [
				{
					id: 'nx0nx0nx0nx0',
					message:
						'There was an external error while processing this job. Please contact support with the following reference number should you need assistance: ADJ33D4',
					type: 'error',
					origin: {
						type: 'workflow',
						id: 'workflow-9',
						name: workflows.find(({ id }) => id === 'workflow-9')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/01/2023 11:53:08 am').getTime()
				},
				{
					id: 'nx1nx1nx1nx1',
					message: 'New Job Assigned',
					type: 'job',
					status: 'action',
					job: 'j1j1j1j1',
					origin: {
						type: 'workflow',
						id: 'workflow-9',
						name: workflows.find(({ id }) => id === 'workflow-9')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/02/2023 11:53:08 am').getTime()
				},
				{
					id: 'nx2nx2nx2nx2',
					message: 'Get this done ASAP',
					type: 'job',
					status: 'action',
					job: 'j2j2j2j2',
					origin: {
						type: 'workflow',
						id: 'workflow-11',
						name: workflows.find(({ id }) => id === 'workflow-11')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/03/2023 4:22:56 pm').getTime()
				},
				{
					id: 'nx3nx3nx3nx3',
					message: 'This job does not exist',
					type: 'job',
					status: 'action',
					job: 'j5j5j5j5',
					origin: {
						type: 'workflow',
						id: 'workflow-9',
						name: workflows.find(({ id }) => id === 'workflow-9')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/02/2023 11:54:08 am').getTime()
				},
				{
					id: 'nx4nx4nx4nx4',
					message: 'Workflows error message 1',
					type: 'error',
					// status: 'error',
					origin: {
						type: 'workflow',
						id: 'workflow-9',
						name: workflows.find(({ id }) => id === 'workflow-9')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/12/2023 11:54:08 am').getTime()
				},
				{
					id: 'nx5nx5nx5nx5',
					message: 'Workflows error message 2',
					type: 'error',
					// status: 'error',
					origin: {
						type: 'workflow',
						id: 'workflow-11',
						name: workflows.find(({ id }) => id === 'workflow-11')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/13/2023 4:22:56 pm').getTime()
				},
				{
					id: 'nx6nx6nx6nx6',
					message: 'Workflows error message 3',
					type: 'error',
					// status: 'error',
					origin: {
						type: 'workflow',
						id: 'workflow-9',
						name: workflows.find(({ id }) => id === 'workflow-9')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/14/2023 11:54:08 am').getTime()
				},
				{
					id: 'nx7nx7nx7nx7',
					message: 'Workflows error message 4',
					type: 'error',
					// status: 'error',
					origin: {
						type: 'workflow',
						id: 'workflow-9',
						name: workflows.find(({ id }) => id === 'workflow-9')?.name ?? 'Unknown Workflow'
					},
					createdAt: new Date('10/15/2023 11:54:08 am').getTime()
				},
				{
					id: 'nx8nx8nx8nx8',
					message: "Workflow status set to 'Running' by 'John Smith'",
					type: 'info',
					origin: {
						type: 'system'
					},
					createdAt: new Date('10/15/2023 11:58:20 am').getTime()
				},
				{
					id: 'nx9nx9nx9nx9',
					message: 'System info message 2',
					type: 'info',
					origin: {
						type: 'system'
					},
					createdAt: new Date('10/15/2023 11:59:20 am').getTime()
				}
			],
			count: 2
		});

		mock.onPost(`${peopleApiUrl}/job/notes`).reply(200);
		mock.onPost(`${peopleApiUrl}/job/attachments`).reply(200);
		mock.onPut(`${peopleApiUrl}/job/action`).reply(200);
		mock.onPost(`${peopleApiUrl}/tracker`).reply(config => {
			const data = JSON.parse(config.data);
			return [200, { jobIDs: [...data.jobs] }];
		});
		mock.onGet(`${peopleApiUrl}/tracker`).reply(200, {
			jobs: [
				{
					id: 'j1j1j1j1',
					active: true,
					createdAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					updatedAt: new Date('10/09/2023 7:38:00 AM').getTime(),
					name: 'workflowName:2023-10-2:2f2f3',
					owner: user.id,
					status: 'action',
					workflow: 'workflow-9'
				},
				{
					id: 'j2j2j2j2',
					active: true,
					createdAt: 1699999179,
					updatedAt: 1699999179,
					name: 'workflow2:2023-10-2:abcd',
					owner: user.id,
					status: 'action',
					workflow: 'workflow-11'
				}
			]
		});

		const wfxApiUrl = getWfxUrl('us-east-1');
		mock.onGet(`${wfxApiUrl}/api/wfx/mock-workflow-id-1.json`).reply(200, {
			nodes: [
				{
					id: 'mock-node-id-1',
					name: 'Mock Internal Form Node 1',
					namespace: 'InternalForm',
					enabled: true
				},
				{
					id: 'mock-node-id-2',
					name: 'Mock Internal Form Node 2',
					namespace: 'InternalForm',
					enabled: true
				},
				{
					id: 'mock-node-id-3',
					name: 'Mock Rename Node 3',
					namespace: 'Rename',
					enabled: true
				},
				{
					id: 'mock-node-id-4',
					name: 'Mock External Form Node 4',
					namespace: 'ExternalForm',
					enabled: true
				}
			]
		});

		mock.onGet(`${wfxApiUrl}/api/wfx/mock-workflow-id-2.json`).reply(200, {
			nodes: [
				{
					id: 'mock-node-id-201',
					name: 'Mock Internal Form Node 201',
					namespace: 'InternalForm',
					enabled: true
				},
				{
					id: 'mock-node-id-202',
					name: 'Mock Internal Form Node 202',
					namespace: 'InternalForm',
					enabled: true
				},
				{
					id: 'mock-node-id-203',
					name: 'Mock Rename Node 203',
					namespace: 'Rename',
					enabled: true
				}
			]
		});
	}

	mock.onGet('/api/user/functions').reply(200, {
		static: {
			w00000001: { name: 'Copy', class: 'icn-copy' },
			w00000002: { name: 'Scan To Email', class: 'icn-email' },
			w00000003: { name: 'Scan To Box', class: 'icn-box' },
			w00000004: { name: 'Scan To PC', class: 'icn-scanpc' },
			w00000005: { name: 'Internet Fax', class: 'icn-inetfax' },
			w00000006: { name: 'IP Address Fax', class: 'icn-ipfax' },
			w00000007: { name: 'Fax', class: 'icn-fax' },
			w00000016: { name: 'IP Fax (SIP)', class: 'icn-ipaddrfax' },
			w00000017: { name: 'Quick Copy', class: 'icn-quick-copy' },
			w00000101: { name: 'Web Browser', class: 'icn-web' },
			w00000102: {
				name: 'MarketPlace',
				class: 'icn-mpinstall'
			}, // pre-install.
			w00000201: { name: 'Address Book', class: 'icn-address' },
			w00000202: { name: 'USB Mem. Print', class: 'icn-usb' },
			w00000203: { name: 'Classic Style', class: 'icn-classic' },
			w00000204: { name: 'Classic Copy', class: 'icn-classic-copy' },
			w00000205: { name: 'Classic Scan', class: 'icn-classic-scan' },
			w00000206: { name: 'Classic Fax', class: 'icn-classic-fax' },
			w00000207: { name: 'Box', class: 'icn-classic-box' },
			w00000208: { name: 'Serverless Pull Printing', skip: true },
			w00000301: { name: 'ID & Print', class: 'icn-idprint' },
			w00000302: { name: 'Paper', class: 'icn-paper' },
			w00000303: { name: 'Card Shot', class: 'icn-idcard' },
			w00000304: { name: 'Scan to Me', class: 'icn-scanme' },
			w00000401: { name: 'APP (OpenAPI)', class: 'icn-app' }
		}
	});

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 100', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 10', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '005',
			auth_count: '1604'
		},
		{
			user_name: 'ben',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '123125',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '655',
			auth_count: '1545'
		},
		{
			user_name: 'derek',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '45',
			auth_count: '1516'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '335',
			auth_count: '1639'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '1578'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '1635'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice1',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '05',
			auth_count: '164'
		},
		{
			user_name: 'ben2',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '155'
		},
		{
			user_name: 'derek1',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '16'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '5'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '78'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '35'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&by=week&id=changeMeId').reply(
		200,
		[
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '19',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '131',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '16',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '11',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '18',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '46',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '25',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '186',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '9',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '17',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '1',
				by_week: '2020-10-26T00:00:00.000Z'
			}
		]
	);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2976',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '227',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2899',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '239',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_week: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&by=day&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '283',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '29',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '1',
			users_using_ct: '1',
			auth_count: '28',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '23',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_day: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=13fhhi70ehf&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=241eih11499&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '14',
			users_using_ct: '11',
			auth_count: '83',
			by_week: '2020-10-01T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '13',
			auth_count: '250',
			by_week: '2020-10-0500:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '33',
			auth_count: '20',
			by_week: '2020-10-12T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '23',
			auth_count: '10',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '3',
			auth_count: '52',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet('/api/license-policy').reply(200, {
		[PublicId.StarterMonthly]: ['admin-dashboard', 'add-users', 'add-devices'],
		[PublicId.BusinessMonthly]: ['admin-dashboard', 'add-users', 'add-devices', 'panel-editor'],
		[PublicId.EnterpriseMonthly]: [
			'admin-dashboard',
			'add-users',
			'add-devices',
			'panel-editor',
			'assign-devices-by-user',
			'assign-users-by-device',
			'assign-users-by-domain',
			'admin-dashboard-ext',
			'reports'
		]
	});

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/user`).reply(200, [
		'userGroup-1',
		'userGroup-3',
		'',
		'userGroup-2'
	]);

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/device`).reply(200, [
		'deviceGroup-8',
		'deviceGroup-1',
		'deviceGroup-3',
		'deviceGroup-4',
		'not-a-real-one',
		'fake-ronald',
		'deviceGroup-9',
		'deviceGroup-6',
		'deviceGroup-7',
		'deviceGroup-8',
		'00000000-4444-4444-4444-000000000000',
		'deviceGroup-5'
	]);

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/form`).reply(200, [
		'deviceGroup-1',
		'deviceGroup-3',
		'',
		'deviceGroup-2'
	]);

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/workflow`).reply(200, [
		'userGroup-1',
		'workflowGroup-2',
		'userGroup-3',
		'workflowGroup-1',
		'userGroup-2',
		'workflowGroup-3'
	]);

	// APIs for the customer feedback
	// mock.onPost(`/api/feedback/file`).reply(200, {
	// 	status: 'success',
	// 	key: '9a61ebfec1117533c9243f7ced95aeb3/2023-03-21_15-05.png'
	// });

	// mock.onPost(`/api/feedback/email`).reply(200, {
	// 	status: 'success'
	// });

	// APIs for the contact us form
	// mock.onPost('/api/contact').reply(200, {
	// 	status: 'success'
	// });
	//
	// APIs for the upgrade license
	// mock.onPatch('/api/tenants/${selectedLicenseGroup.id}/upgrade').reply(200, {
	// 	success: true,
	// 	isMoreThanNewCapacity: true,
	// 	unassigned: true
	// });

	// Mock Tenant config onboarding page
	// mock.onGet(
	// 	`/api/confirmation/d79ad530-da0e-5a12-b60f-7876cb28a84c/tenant/d8bdff9e-5ff1-4306-9a72-747a8c05a98e`
	// ).reply(200, { valid: true, status: 'INVITED', orderNumber: 'JAMES100037', plan: 'stratus' });
	// mock.onGet(
	// 	`/api/tenants/d79ad530-da0e-5a12-b60f-7876cb28a84c/tenant/d8bdff9e-5ff1-4306-9a72-747a8c05a98e/config-status`
	// ).reply(200, {
	// 	status: 'INVITED'
	// });
};

export default mocking;
